import axios from "@/axios";

export async function getGraphData(payload, case_id) {
    try {
        const url = `/analysis-tools/keylines_graph_data/${case_id}`;
        return await axios.post(url, payload, {
            headers: {"x-case-id": case_id, "x-status": "RUNNING"},
        });
    } catch (err) {
        console.log(err);
    }
}

export async function saveToolGraphData(payload) {
    try {
        const url = `analysis-tools/save-tool-graph-data`;
        return await axios.post(url, payload, {
            headers: {"x-case-id": payload.case_id},
        });
    } catch (err) {
        console.error("error", err);
    }
}
