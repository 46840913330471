import Comparify from "../analysisTools/comparify";
import titleMixin from "@/mixins/titleMixin";

import VueMultiselect from "@/components/vue-multiselect";
// import "vue-multiselect/dist/vue-multiselect.min.css";
import "@/assets/css/multiselect.scss";
import axios from "@/axios";
import {filterDropdownData, getJurisdictionName, setUsernamesDropdownData, getMappingValue} from "@/utils/functions";

// import OsintGraph from "@/components/osint-graph";
// import monitoringFilter from "@/components/monitoring-filter"
import {mapGetters, mapMutations, mapActions, mapState} from "vuex";
import OsintAxios from "@/axios/osint.js";
import {v4 as uuidv4} from "uuid";
import {capitalize, clone, cloneDeep, conforms, get, startCase, uniq, uniqBy} from "lodash";
import {FEATURES, ADVERSE_TOOL_MAPPING} from "@/utils/constants";
import {TippyComponent} from "vue-tippy";
import {DateTime} from "luxon";
import whatsappServices from "./whatsapp/services.js";
import {splitCountryCode} from "@/utils/functions";
import AccordionCard from "@/components/accordion";
import SubjectPreview from "@/containers/subject-preview";

// Dynamic imports for components that are not used on first load
const KlGraph = () => import("@/components/osint-graph/KlGraph");
const KlGraphNew = () => import("@/components/osint-graph/KlGraphNew");
const ResultExpanded = () => import("@/components/result-expanded");
const NeoSearchQueries = () => import("./searchQueries.vue");
const analysisToolsFilters = () => import("./ToolsTreeView.vue");
const twitterTool = () => import("../tools/twitter");
const instagramTool = () => import("../tools/instagram");
const skypeTool = () => import("../tools/skype");
const googleTool = () => import("../tools/google");
const newsTool = () => import("../tools/news");
const darkWebTool = () => import("../tools/darkWeb");
const usernameTool = () => import("../tools/username");
const githubTool = () => import("../tools/github");
const gitlabTool = () => import("../tools/gitlab");
const whatsappTool = () => import("../tools/whatsapp");
const emailSearchTool = () => import("../tools/email-search");
const emailReputationTool = () => import("../tools/emailReputation");
const linkedinTool = () => import("../tools/linkedin");
const SearchEngineTool = () => import("../tools/search-engine");
const Personidentifier = () => import("../tools/person-identifier");
const gdc = () => import("../tools/gdc");
const Companyidentifier = () => import("../tools/company-identifier");
const DomainSearchTool = () => import("../tools/domain-search");
const NameSearchTool = () => import("../tools/name-search");
const domainTool = () => import("../tools/domain");
//const CompanyChecker = () => import("../tools/company-checker");
const otherTool = () => import("../tools/other");
const flickrTool = () => import("../tools/flickr");
const PartialDomainSearch = () => import("../tools/partial-domain-search");
const adverseTool = () => import("../tools/adverse-media");
const offshoreLeaksTool = () => import("../tools/offshore-leaks");
const lexisBridgerTool = () => import("../tools/lexis-bridger");
const courtRecordsTool = () => import("../tools/court-records");
const facebookTool = () => import("../tools/facebook");
const RiskTool = () => import("../tools/adverse-media");
const tiktokTool = () => import("../tools/tiktok");
const ManualMonitoringModel = () => import("@/containers/manual-monitoring");
const Button = () => import("@/components/button");
const Input = () => import("@/components/input");
const Table = () => import("@/components/table");
const Tab = () => import("@/components/tab");
const NeoTabsSwitch = () => import("@/components/tabs-switch");
const Loader = () => import("@/components/loader");
const Dropdown = () => import("@/components/dropdown");
const Pagination = () => import("@/components/pagination");
const MonitoringFilter = () => import("@/components/monitoring-filter");
const ToolsFilter = () => import("./tools-filter");
const globalNerAnalyser = () => import("../tools/ner-analyser");
import {EventBus} from "@/main.js";

import {getGraphData} from "./services";

// Vue.use(Toast);

export default {
    title() {
        return this.$route.name === "tools" ? `Tools` : `Monitoring`;
    },
    name: "tools",
    components: {
        "neo-subject-preview": SubjectPreview,
        accordion: AccordionCard,
        tippy: TippyComponent,
        "neo-input": Input,
        "neo-button": Button,
        "neo-table": Table,
        "neo-tab": Tab,
        "neo-tabs-switch": NeoTabsSwitch,
        "neo-pagination": Pagination,
        VueMultiselect,
        "neo-loader": Loader,
        "neo-dropdown": Dropdown,
        "analysis-tools-filters": analysisToolsFilters,
        "twitter-tool": twitterTool,
        "instagram-tool": instagramTool,
        "skype-tool": skypeTool,
        "google-tool": googleTool,
        "news-tool": newsTool,
        "dark-web-tool": darkWebTool,
        "username-tool": usernameTool,
        "email-reputation-tool": emailReputationTool,
        "github-tool": githubTool,
        "gitlab-tool": gitlabTool,
        "whatsapp-tool": whatsappTool,
        "linkedin-tool": linkedinTool,
        "search-engine-tool": SearchEngineTool,
        "person-identifier-tool": Personidentifier,
        "gdc-tool": gdc,
        "company-name": Companyidentifier,
        "company-domain": domainTool,
        //"company-checker": CompanyChecker,
        "other-tool": otherTool,
        "facebook-tool": facebookTool,
        "flickr-tool": flickrTool,
        "adverse-media-tool": adverseTool,
        "offshoreleaks-tool": offshoreLeaksTool,
        "lexis-bridger-tool": lexisBridgerTool,
        "court-records-tool": courtRecordsTool,
        "risk-tool": RiskTool,
        "tiktok-tool": tiktokTool,
        "email-search-tool": emailSearchTool,
        "domain-search-tool": DomainSearchTool,
        "name-search-tool": NameSearchTool,
        "manual-monitoring": ManualMonitoringModel,
        "neo-comparify": Comparify,
        "partial-domain-search-tool": PartialDomainSearch,
        // "social-search-tool": socialSearchTool,
        // OsintGraph,
        NeoSearchQueries,
        KlGraph,
        KlGraphNew,
        ResultExpanded,
        MonitoringFilter,
        ToolsFilter,
        globalNerAnalyser,
    },
    props: {
        expandedGraph: {
            type: Boolean,
        },
        comparatorInputValue: {default: 100},
    },
    mixins: [titleMixin],
    data() {
        return {
            sideGraphOpen: false,
            queryExpanded: false,
            isCaseDataFiltered: false,
            isCaseDataApiCall: false,
            isLoadingDetails: false,
            currenToolTypeMapper: {
                "twitter-tool": "twitter",
                "skype-tool": "skype",
                "google-tool": "google",
                "github-tool": "github",
                "whatsapp-tool": "whatsapp",
                "linkedin-tool": "linkedin",
                "flickr-tool": "flickr",
                "adverse-media-tool": "adverse-media",
                "offshoreleaks-tool": "offshoreleaks",
                "lexis-bridger-tool": "lexis-bridger",
                "court-records-tool": "court-records",
                "risk-tool": "adverse-media",
                "gitlab-tool": "gitlab",
                "tiktok-tool": "tiktok",
                "facebook-tool": "facebook",
                "person-identifier-tool": "personIdentifier",
                "gdc-tool": "gdc",
                "search-engine-tool": "search-engine",
                "username-tool": "username-search",
                "name-search-tool": "name_search",
                "company-domain": "domain",
                "email-search-tool": "email-search",
                "company-name": "company",
                "company-checker": "companyChecker",
                "instagram-tool": "instagram",
                "domain-search-tool": "domain-search",
                "partial-domain-search-tool": "partial-domain-search",
            },
            nodeKeyTypeMap: {
                company: "Company Name",
                current_employer: "Current Employer",

                person: "Name",
                officerName: "Name",
                name: "Name",
                full_name: "Name",
                nickname: "name",
                registrant_name: "name",
                registrant: "name",
                user: "name",

                bio: "github bio",
                gid: "Google id",
                id: "Github id",

                number: "phone",
                registrant_phone: "phone",

                registrant_email: "email",

                company_name: "Company Name",
                companyName: "Company Name",
                birthday: "Date of birth",
                education_institute: "education",
                link: "url",
                current_title: "Employment title",

                registrant_address: "location",
                registrant_country: "location",
                registrant_city: "location",
                registrant_state: "location",
                city: "location",
                country: "location",
                address: "location",
                province: "location",
                full_name: "Name",
                name: "Name",
                query: "Search engine query",
                twitter_username: "username",
            },
            queryData: {
                nodes: [],
                edges: [],
            },
            graphDataQueue: [],
            currentQueryNodeId: null,
            collapsed: false,
            collapseIconVisible: false,
            input_type: [],
            input_value: [],
            input_type_list: [],
            input_value_list: [],
            inputTypeOptions: [
                {
                    group: "Input Type",
                    categories: [
                        {
                            text: "Email",
                            input_type: "email",
                            value: "emailList",
                        },
                        {
                            text: "Username",
                            input_type: "username",
                            value: "usernameList",
                        },
                        {
                            text: "Phone",
                            input_type: "phone",
                            value: "phoneList",
                        },
                        {
                            text: "Location",
                            input_type: "location",
                            value: "locationList",
                        },
                        {
                            text: "Domain",
                            input_type: "domain",
                            value: "domainList",
                        },
                        {
                            text: "Company Name",
                            input_type: "company_name",
                            value: "companyUsers",
                        },
                        {
                            text: "Registrant",
                            input_type: "registrant",
                            value: "registrantUsers",
                        },
                        {
                            text: "Name",
                            input_type: "name",
                            value: "nameList",
                        },
                        {
                            text: "URL",
                            input_type: "url",
                            value: "urlList",
                        },
                        {
                            text: "Education",
                            input_type: "education",
                            value: "educationList",
                        },
                        {
                            text: "Employment",
                            input_type: "employment",
                            value: "employmentList",
                        },
                        {
                            text: "Hobby",
                            input_type: "hobby",
                            value: "hobbyList",
                        },
                    ],
                },
            ],
            inputValueOptions: [],
            toolFieldsMapping: {
                "company-name": ["companyUsers", "nameList"],
                "company-domain": ["domainList", "nameList", "companyUsers"],
                "company-checker": ["companyUsers", "locationList"],
                "name-search-tool": ["nameList"],
                "domain-search-tool": ["registrantUsers", "domainList", "emailList", "nameList"],
                "dark-web-tool": ["usernameList", "phoneList", "emailList", "domainList", "nameList"],
                "github-tool": ["emailList", "usernameList"],
                "tiktok-tool": ["usernameList"],
                "gitlab-tool": ["emailList"],
                "google-tool": ["emailList", "phoneList"],
                "whatsapp-tool": ["phoneList"],
                "linkedin-tool": ["emailList", "domainList", "nameList", "urlList"],
                "skype-tool": ["emailList", "usernameList", "nameList", "phoneList"],
                "facebook-tool": ["emailList", "phoneList", "urlList", "nameList"],
                "twitter-tool": ["usernameList"],
                "instagram-tool": ["usernameList"],
                "username-tool": ["usernameList"],
                "flickr-tool": ["emailList"],
                "adverse-media-tool": ["nameList", "companyUsers"],
                "offshoreleaks-tool": ["nameList", "companyUsers"],
                "lexis-bridger-tool": ["nameList", "companyUsers"],
                "court-records-tool": ["nameList", "companyUsers"],
                "risk-tool": ["nameList"],
                "email-search-tool": ["emailList"],
                "person-identifier-tool": ["emailList", "usernameList", "phoneList", "nameList", "employmentList", "educationList", "locationList"],
                "gdc-tool": ["emailList", "usernameList", "phoneList", "nameList"],
                "email-reputation-tool": ["emailList"],
                "search-engine-tool": ["emailList", "usernameList", "phoneList", "locationList", "educationList", "employmentList", "hobbyList", "companyUsers", "domainList", "nameList"],
                "social-search-tool": ["nameList"],
                "news-tool": ["nameList", "companyUsers"],
                "partial-domain-search-tool": ["domainList"],
            },
            toolPlatforms: {
                "company-name": "company-identifier",
                "company-domain": "domain",
                "company-checker": ["companyUsers", "locationList"],
                "domain-search-tool": "domain-search",
                "name-search-tool": "name-search",
                "dark-web-tool": "dark-web",
                "github-tool": "github",
                "gitlab-tool": "gitlab",
                "google-tool": "google",
                "whatsapp-tool": "whatsapp",
                "linkedin-tool": "linkedin",
                "skype-tool": "skype",
                "twitter-tool": "twitter",
                "instagram-tool": "instagram",
                "username-tool": "username",
                "flickr-tool": "flickr",
                "adverse-media-tool": "adverse-media",
                "lexis-bridger-tool": "lexis-bridger",
                "facebook-tool": "facebook",
                "offshoreleaks-tool": "offshoreleaks",
                "court-records-tool": "court-records",
                "risk-tool": "adverse-media",
                "tiktok-tool": "tiktok",
                "email-search-tool": "email-search",
                "person-identifier-tool": "person-identifier",
                "gdc-tool": "gdc",
                "email-reputation-tool": "email_reputation",
                "search-engine-tool": "search-engine",
                "news-tool": "news",
                "social-search-tool": "social-search",
                "partial-domain-search-tool": "partial-domain-search",
            },
            graphFilters: [],
            searchedKeywords: [],
            osintGraphDrawerOpen: false,
            filterValueApiMap: {
                personIdentifier: [
                    {
                        source: "personIdentifier",
                        keys: ["display"],
                    },
                ],
                gdc: [
                    {
                        source: "gdc",
                        keys: ["display"],
                    },
                ],
                query: [
                    {
                        source: "search-engine",
                        keys: ["link"],
                    },
                    {
                        source: "instagram",
                        keys: ["full_name", "username"],
                    },
                ],
                name: [
                    {
                        source: "company",
                        keys: ["name"],
                    },
                    {
                        source: "name_search",
                        keys: ["publicIdentifier", "username", "login", "screen_name"],
                    },
                    {
                        source: "adverse-media",
                        keys: ["name"],
                    },
                ],
                phone: [
                    {
                        source: "google",
                        keys: ["gid"],
                    },
                ],
                email: [
                    {source: "github", keys: ["username", "id", "email", "location", "name", "bio", "twitter_username"], excludeKeys: ["plan"]},
                    {source: "gitlab", keys: ["username", "name"]},
                    {
                        source: "linkedin",
                        api: "/linkedin/search/email",
                        keys: ["location", "current_title", "current_employer", "number", "email"],
                    },
                    {
                        source: "linkedin-discover",
                        keys: ["email"],
                    },
                    {source: "domain", api: "/api/domain/email", keys: []},
                    {source: "domain-search", keys: ["domain"]},
                    {source: "flickr", keys: ["name", "location", "username"]},
                    {
                        source: "google",
                        keys: ["gid"],
                    },
                    // {source:'skype', keys:["displayname","city","phoneHome","phoneMobile","phoneOffice","province"]}
                ],
                // 'tool':[
                //     {source:'google', keys:["extra_email"]},

                // ],
                skype_query: [
                    {
                        source: "skype",
                        keys: ["displayname", "city", "phoneHome", "phoneMobile", "phoneOffice", "province"],
                    },
                ],
                friends: [
                    {source: "twitter", keys: ["name"], excludeKeys: ["user_mentions"]},
                    {source: "instagram", keys: ["full_name"]},
                ],

                domain: [
                    {source: "linkedin", keys: ["location", "current_title", "current_employer", "number", "email", "name"]},
                    {
                        source: "domain-search",
                        keys: ["domain", "registrant_address", "registrant_city", "registrant_country", "registrant_email", "registrant_name", "registrant_org", "registrant_phone", "registrant_state"],
                    },
                ],
                employees: [{source: "domain", keys: ["name"]}],
                company: [
                    {source: "domain", keys: ["description"]},
                    {source: "company", keys: ["company_number", "company_name", "status", "address", "company_type", "jurisdiction", "incorporation_date"]},
                ],

                url: [
                    {
                        source: "linkedin",
                        keys: ["name", "location", "current_title", "current_employer", "email", "number"],
                    },
                ],
                followers: [
                    {source: "twitter", keys: ["name"], excludeKeys: ["user_mentions"]},
                    {source: "instagram", keys: ["full_name"]},
                ],
                username: [
                    // {source:'skype', keys:["displayname","city","phoneHome","phoneMobile","phoneOffice","province"]},
                    {
                        source: "username-search",
                        keys: ["generated_uri"],
                    },

                    {
                        source: "twitter",
                        api: "/twitter/user",
                        keys: ["name"],
                        excludeKeys: ["user_mentions"],
                    },
                    {
                        source: "linkedin",
                        api: "/linkedin/search/name/company",
                        keys: ["name", "number", "location", "current_title", "current_employer", "email"],
                    },
                    {source: "github", keys: ["email"]},
                    {
                        source: "instagram",
                        api: "/instagram/user",
                        keys: ["full_name", "website"],
                    },
                    {
                        source: "tiktok",
                        api: "/tiktok/user",
                        keys: ["nickname"],
                    },
                ],
            },
            isGraphReady: false,
            graphNodes: [],
            graphLinks: [],
            nodeToLink: null,
            searchResults: {},
            nodeCount: 0,
            case_id: "",
            isTool: false,
            toolOptions: {},
            toolsData: [],
            treeData: null,
            activeProcess: "",
            test: "",
            usernameList: [],
            phoneList: [],
            emailList: [],
            locationList: [],
            employmentList: [],
            educationList: [],
            hobbyList: [],
            registrantUsers: [],
            domainList: [],
            organisationList: [],
            companyUsers: [],
            nameList: [],
            // searchedQueries: [],
            urlList: [],
            counterInterval: null,
            splitGraphView: false,
            caseData: {
                nodes: [],
                edges: [],
            },
            currentQueryNodeId: null,
            analysisToolData: [],
            toggleAnalyzer: false,
            toggleNer: false,
            selectedItem: {
                email: "",
                username: "",
                phone: "",
                domain: "",
                company: "",
                company_name: "",
                // registrant_name: "",
                location: [],
                education: [],
                employment: [],
                hobby: [],
            },
            fNameInput: "",
            mNameInput: "",
            lNameInput: "",
            uniqBy,
            selectedToolsList: [],
            queriesRoute: null,
            shouldMountGraph: false,
            locationInput: [],
            educationInput: [],
            employmentInput: [],
            tabName: "",
            FEATURES,
            selectVal: [],
            activeMonitoring: null,
            monitoringQueries: [],
            query_ids: [],
            products: [],
            toolsCategories: [],
            dateFilters: {
                minDate: 0,
                maxDate: 0,
                data: [],
            },
            selectedToolQuery: null,
            queriesResultsCount: [],
            toolsUsers: [],
            // toolFiltersCount: 0,
            isSearchQueryClicked: false,
            toolActiveMessage: "",

            // newGraph
            graph: {
                graphData: null,
                caseId: "",
                loaderProgress: 0,
                graphLoading: false,
            },
            showNewGraph: true,

            // Expanded Result Card
            // TODO: Fix naming to make in less verbose
            expandedResultCard: {
                isResultExpanded: false,
                isOldVersionSelected: false,
                content: null,
                isSavedLoading: {
                    loading: false,
                    loadingCardIndex: null,
                },
            },
        };
    },
    watch: {
        expandedGraph: function(val) {
            if (val) {
                document.getElementById("components").style.paddingBottom = 0;
                this.splitGraphView = false;

                if (this.showNewGraph) {
                    this.getNewGraph();
                }
            } else {
                document.getElementById("components").removeAttribute("style");
                this.splitGraphView = true;
                this.shouldMountGraph = false;
            }
        },
        "$route.params.toolName"(to, from) {
            if (to !== from) this.redirectTool();
        },
        async "$route.query.query_id"(to, from) {
            if (to !== from) {
                if (this.$route.params.toolName && this.$route.query.query_id && !this.isSearchQueryClicked) {
                    await this.handleRefreshQueries();
                    let {query_id} = this.$route.query;
                    let selected_query;
                    if (this.tabName === "monitoring" || this.tabName === "monitoring-ro") selected_query = this.fetchSearchedQueries?.find((el) => el._id === query_id && el.is_monitoring);
                    else selected_query = this.fetchSearchedQueries?.find((el) => el._id === query_id);
                    const {search_queries} = this.$refs;
                    if (selected_query) search_queries.handleClick(selected_query, true);
                } else if (this.$route.params.toolName && !this.$route.query.query_id && !this.isSearchQueryClicked) {
                    const {search_queries} = this.$refs;
                    search_queries.handleClick();
                }
            }
        },
        getProduct() {
            this.getProductMonitoring(); // Function to fetch product monitoring data
        },
        async getAllUsers() {
            await this.fetchToolsUsers();
        },
    },
    computed: {
        // ...mapState(["overallToolsStatus"]),
        ...mapGetters([
            "getReadOnlyMode",
            "toolsMonitoringEvents",
            "queryMonitoringEvents",
            "monitoringTools",
            "productMonitoring",
            "getToolsData",
            "getResearchData",
            "getAnalysisToolData",
            "getCaseUsernames",
            "getCaseEmails",
            "getCaseLocations",
            "getCasePhones",
            "getCaseEmployments",
            "getCaseEducations",
            "getCaseHobbies",
            "getCaseRegistrants",
            "getCaseOrganisations",
            "getCaseCompanies",
            "getCaseDomains",
            "getCaseNames",
            "getAllToolNames",
            "searchedQueries",
            "getMonitoringData",
            "getAppConfig",
            "getPermissions",
            "checkPermissionsFetched",
            "getCompanyProducts",
            "getCasePersonalData",
            "getProduct",
            "getSelectedToolQuery",
            "getMonitoringDateFilter",
            "getCaseCreationDate",
            "queriesLoading",
            "selectedToolsUsers",
            "getAllUsers",
            "getToolsFilterData",
            "nerFilters",
            "getAnalyzerOptions",
            "caseValid",
            "searchedQueries",
            "getCaseId",
        ]),

        product_data() {
            return this.getCompanyProducts.find((prod) => prod.name === this.getCasePersonalData?.data?.["Due Diligence Level"]?.value);
        },

        frequency() {
            let inherit_settings = this.monitoringTools[this.currentToolSelected] && this.monitoringTools[this.currentToolSelected].status === "enabled" && !this.monitoringTools[this.currentToolSelected].has_inner_level_monitoring ? this.monitoringTools[this.currentToolSelected] : this.productMonitoring.find((product) => product.tool === this.currentToolSelected);
            return inherit_settings?.frequency;
        },
        allCaseUsers() {
            return [
                {
                    group: "Users",
                    values: this.toolsUsers,
                },
            ];
        },
        isToolActive() {
            let toolActive = this.getAllToolNames?.children?.find((el) => {
                if (el.activeProcess === this.currentToolSelected) {
                    return el;
                }
            });
            if (toolActive) {
                this.toolActiveMessage = toolActive.errorMessage;
                return toolActive.active;
            } else return true;
        },
        // shouldMountGraph(){
        //     return this.shouldMountGraph
        // },
        product() {
            return this.getProduct;
        },
        tools() {
            return Object.keys(this.monitoringTools) || [];
        },
        isToolMonitoring() {
            return this.tools.includes(this.currentToolSelected) && !this.monitoringTools[this.currentToolSelected].has_inner_level_monitoring;
        },
        isProductToolMonitoring() {
            return this.productMonitoring.findIndex((product) => product.tool === this.currentToolSelected) > -1 && this.productMonitoring.find((product) => product.tool === this.currentToolSelected).status === "enabled" ? true : false;
        },
        isGraphDataQueueEmpty() {
            if (this.graphDataQueue.length === 0) return true;
            else return false;
        },
        getGraphNodes() {
            if (this.isGraphReady && this.expandedGraph !== null) {
                if (!this.shouldMountGraph) {
                    setTimeout(() => {
                        this.shouldMountGraph = true;
                    }, 1000);
                }
                if (this.shouldMountGraph) {
                    return this.graphNodes;
                }
            }
            return [];
        },
        getGraphLinks() {
            if (this.isGraphReady && this.expandedGraph !== null) {
                if (!this.shouldMountGraph) {
                    setTimeout(() => {
                        this.shouldMountGraph = true;
                    }, 1000);
                }
                if (this.shouldMountGraph) {
                    return this.graphLinks;
                }
            }
            return [];
        },
        getActiveTool() {
            return this.currenToolTypeMapper[this.activeProcess];
            // return this.activeProcess
        },
        isMonitoring() {
            let nonMonitoringTools = ["name-search-tool", "email-search-tool"];
            let isPermitted = this.getPermissions.some((ele1) => FEATURES["monitoring"][ele1] === this.treeData.activeProcess);
            return isPermitted && this.tabName === "tools" ? this.isProductToolMonitoring && !nonMonitoringTools.includes(this.treeData.activeProcess) : false;
        },
        isCardMonitoring() {
            let nonMonitoringTools = ["name-search-tool", "email-search-tool"];
            let isPermitted = this.getPermissions.some((ele1) => FEATURES["monitoring"][ele1] === this.treeData.activeProcess);
            return isPermitted ? this.isProductToolMonitoring && !nonMonitoringTools.includes(this.treeData.activeProcess) : false;
        },
        isMonitoringEnabled() {
            return process.env.VUE_APP_TAG_NAME === "local" || process.env.VUE_APP_TAG_NAME === "development";
        },
        nodeValues() {
            const vals = this.graphNodes;
            return vals;
        },

        fetchAllData() {
            if (this.getResearchData.personalDetails?.length || this.getResearchData.socialProfiles?.length) {
                this.getAllToolsData();
            }
        },
        currentToolSelected() {
            return this.treeData?.manualMonitoring ? "manual-monitoring" : this.$route.params.toolName;
        },
        toolsList() {
            let data = {...this.getAllToolNames};
            if (this.tabName === "monitoring") {
                data = {children: data?.children?.filter((ele) => ele.monitoring)};
            }
            if (data?.children && this.input_type?.length) {
                data.children = data?.children.filter((tool) => {
                    let filter = false;
                    this.input_type.forEach((type) => {
                        if (!filter) filter = tool?.fields?.find((field) => type.value === field) ? true : false;
                    });
                    return filter;
                });
            }
            if (this.tabName === "tools") {
                data = data?.children?.map((n) => ({...n, globalAnalyzer: this.toggleAnalyzer}));
            } else {
                // map tools with monitoring tools data
                data = data?.children?.map((ele) => {
                    return {
                        ...ele,
                        ...this.monitoringTools[ele.activeProcess],
                        ...{
                            tool: ele.activeProcess,
                            events: this.toolsMonitoringEvents ? this.toolsMonitoringEvents[ele.activeProcess] : null,
                        },
                    };
                });
            }
            if (this.toolsCategories.length > 0) {
                data = data.filter((dt) => this.toolsCategories.some((tc) => tc.name === dt.heading));
            }

            return {children: data};
        },
        fetchSearchedQueries() {
            let queries = cloneDeep(this.searchedQueries).map((query) => ({
                ...query,
                ...{
                    frequency: this.monitoringQueries.find((mq) => mq?.query.query_id === query._id)?.frequency,
                    monitoring_status: this.monitoringQueries.find((mq) => mq?.query.query_id === query._id)?.status,
                    monitoring_id: this.monitoringQueries.find((mq) => mq?.query.query_id === query._id)?._id,
                    events: this.queryMonitoringEvents ? this.queryMonitoringEvents[query._id] : null,
                    total_results: this.queriesResultsCount.find((mq) => mq?.query_id === query._id)?.result_count || 0,
                    acknowledged_results: this.queriesResultsCount.find((mq) => mq?.query_id === query._id)?.acknowledge_count || 0,
                    unacknowledged_results: this.queriesResultsCount.find((mq) => mq?.query_id === query._id)?.unacknowledge_count || 0,
                    saved_results: this.queriesResultsCount.find((mq) => mq?.query_id === query._id)?.saved_count || 0,
                },
            })); // merge searchedQueries and monitoring queries with same id
            queries = queries.map((query) => {
                if (query.tool === "lexis-bridger-tool" && query.query.entity_type === "Individual") {
                    let list = [];
                    list.push(query.query.person_entity.first_name);
                    list.push(query.query.person_entity.middle_name);
                    list.push(query.query.person_entity.last_name);
                    query.query.person_entity.full_name = list.filter((el) => el).join(" ");
                    return query;
                } else return query;
            });
            return queries?.filter((n) => n.tool == this.currentToolSelected);
        },
        isGraphDataQueueEmpty() {
            if (this.graphDataQueue.length === 0) return true;
            else return false;
        },
        // tabName() {
        //     return this.$route.name;
        // },
        permissionsFetched() {
            return this.checkPermissionsFetched;
        },
        isAuth() {
            const tabName = this.tabName.replace("-ro", ""); // CHANGE TABNAME FOR READ-ONLY MODE ACCORDING TO TOOLS/MONITORING MAPPING
            const toolName = this.currentToolSelected === "manual-monitoring" ? this.$route.params.toolName : this.currentToolSelected;
            return this.getPermissions.some((ele) => FEATURES[tabName][ele] == toolName);
        },
        isGraphAuth() {
            return this.getPermissions.includes("association_graph__case_data");
        },
        isToolsAuth() {
            // outputs__case_data
            if (this.tabName === "tools") {
                return this.getPermissions.includes("analysis_tools__tools");
            } else {
                return this.getPermissions.includes("monitoring__monitoring");
            }
        },

        getCaseStatus() {
            return this.getCasePersonalData && Object.keys(this.getCasePersonalData).length ? this.getCasePersonalData.case_state || "To Do" : "";
        },
        getToolsClass() {
            return ["company-name", "adverse-media-tool", "news-tool", "person-identifier-tool", "gdc-tool"].includes(this.currentToolSelected) ? "tw-overflow-hidden" : "tw-overflow-y-auto";
        },
        toolFiltersCount() {
            let count = 0;
            if (this.getToolsFilterData.monitoring !== "all") count += 1;
            if (this.getToolsFilterData.saved !== "all") count += 1;
            return count;
        },
        globalFiltersCount() {
            let count = 0;
            if (this.nerFilters) {
                count = Object.keys(this.nerFilters)?.length;
                if (count && this.nerFilters["neo_from_date"] && this.nerFilters["neo_from_date"]) count -= 1;
            }
            return count;
        },

        // TODO: Change to classes or style object
        expandedResultCardStyles() {
            return "position: fixed; top: 80px; left: 70px; padding: 0; width: calc(100% - 70px); bottom: 0;";
        },
    },
    async mounted() {
        this.tabName = this.$route.name;
        this.isLoadingDetails = true;
        if (this.tabName === "monitoring") {
            let startDt = new Date();
            let endDt;
            if (this.getCaseCreationDate !== "") endDt = Date.parse(this.getCaseCreationDate);
            // Set last 30 days in case of error
            else endDt = new Date(startDt.getTime() - 30 * 24 * 60 * 60 * 1000);
            Object.assign(this.dateFilters, {
                minDate: startDt,
                maxDate: endDt,
            });
        }
        // document.getElementById("view").style.overflow = "hidden";
        await this.getDataMonitoring(); // Function to fetch monitoring data
        //await this.getNewMonitoringEvents(); // Function to get new monitoring events on product and case level

        await this.getProductMonitoring(); // Function to fetch product monitoring data
        await this.getQueriesResultsCount();
        try {
            this.GraphDataConsumer();
            this.queriesRoute = this.$route.query;
            this.case_id = this.$route.params.id;
            if (this.getAllUsers.length > 0) await this.fetchToolsUsers();
            const preSetData = this.tabName == "tools" ? this.getToolsData : this.getMonitoringData;
            // this.expandedGraph = null;
            if (!preSetData && this.caseValid) {
                EventBus.$emit("topprogress", "start");
                try {
                    await this.getAnalysisDropdownData({
                        url: `research-methodology/${this.case_id}/tools/profiles`,
                    });
                    this.analysisToolData = this.getAnalysisToolData;
                    this.isLoadingDetails = false;
                    await this.loadOptions();
                    await this.getAllToolsData();
                    await this.getQueriesMonitoring();
                    await this.getToolsStatus();
                    //To retain global filters
                    const {value, source} = this.queriesRoute;
                    if (value && source) {
                        let selectedItem = this.inputTypeOptions[0].categories.find((ele) => ele.input_type === source);
                        this.input_type_list.push(selectedItem);
                        this.selectInputType(selectedItem);
                        // let selectedValueSource = this.inputValueOptions[0].values.find((ele) => ele.type.toLowerCase() === (source == "company_name" ? "companyname" : source));
                        let selectedValue = this.inputValueOptions[0].values.find((ele) => ele.value.trim() === value.trim());
                        if (!selectedValue && source === "phone") {
                            selectedValue = this.inputValueOptions[0].values.find((ele) => ele.value.substring(1).trim() == value.trim());
                        }
                        // this.filterInputValues();
                        // if (selectedValue) {
                        this.input_value_list.push(selectedValue);
                        // this.input_value.push(selectedValue);
                        this.selectInputValue(selectedValue);
                        // }
                    }
                    this.redirectTool();
                    if (this.currentToolSelected) {
                        await this.getQueryMonitoringEvents(this.currentToolSelected); // Function to get new monitoring events on query
                        let {query_id} = this.queriesRoute;
                        let selected_query;
                        if (this.tabName === "monitoring" || this.tabName === "monitoring-ro") selected_query = this.fetchSearchedQueries?.find((el) => el._id === query_id && el.is_monitoring);
                        else selected_query = this.fetchSearchedQueries?.find((el) => el._id === query_id);
                        const {search_queries} = this.$refs;
                        if (selected_query) search_queries.handleClick(selected_query);
                    }
                    const graphData = await this.fetchGraphData();
                    if (graphData) {
                        graphData.nodes.forEach((n) => {
                            delete n.group;
                        });
                        // graphData.nodes = graphData.nodes.filter(el => {
                        //     if(el.source.length === 1 && el.source.includes("caseData")) return false;
                        //     else return true
                        // });
                        this.initGraph(graphData);
                    }
                } catch (error) {
                    console.error(error);
                }

                // if (!this.selectedToolQuery) this.selectedToolQuery = this.getSelectedToolQuery && this.getSelectedToolQuery.query;
                EventBus.$emit("topprogress", "done");
                // loader.hide();
            } else {
                this.graphNodes = preSetData.graphNodes;
                this.graphLinks = preSetData.graphLinks;
                this.nodeToLink = preSetData.nodeToLink;
                this.searchResults = preSetData.searchResults;
                this.nodeCount = preSetData.nodeCount;
                this.case_id = preSetData.case_id;
                this.isTool = preSetData.isTool;
                this.toolOptions = preSetData.toolOptions;
                this.toolsData = preSetData.toolsData;
                this.treeData = preSetData.treeData;
                this.activeProcess = preSetData.activeProcess;
                this.analysisToolData = preSetData.analysisToolData;
                this.input_type = preSetData.input_type;
                this.input_type_list = preSetData.input_type_list;
                this.input_value = preSetData.input_value;
                this.input_value_list = preSetData.input_value_list;
                this.toggleAnalyzer = preSetData.toggleAnalyzer;
                this.selectedToolsList = preSetData.selectedToolsList;
                this.selectedItem = preSetData.selectedItem;
                this.fNameInput = preSetData.fNameInput;
                this.mNameInput = preSetData.mNameInput;
                this.lNameInput = preSetData.lNameInput;
                // this.selectedToolQuery = preSetData.selectedToolQuery;
                const query = preSetData.selectedToolQuery;
                let selected_query;
                if (this.tabName === "monitoring" || this.tabName === "monitoring-ro") selected_query = this.fetchSearchedQueries?.find((el) => el._id === query?._id && el.is_monitoring);
                else selected_query = this.fetchSearchedQueries?.find((el) => el._id === query?._id);

                this.selectedToolQuery = selected_query;

                if (this.treeData) {
                    this.clickEvent(this.treeData);
                }
                if (!this.selectedToolQuery) this.SET_SELECTED_TOOL_QUERY(null);
                this.isLoadingDetails = false;
                await this.loadOptions();
                this.getAllToolsData();
                await this.getQueriesMonitoring();
                await this.getToolsStatus();
                this.filterInputValues();
                this.handleChecked();
                // ** Duplicate call of handleClick as we
                //  are already calling this on to,from route.//
                /*if (!this.selectedToolQuery) {
                    const { search_queries } = this.$refs;
                    search_queries.handleClick();
                }*/
            }
            // this.analyseTool();
        } catch (error) {
            console.error(error);
        }
    },
    async created() {
        EventBus.$on("updateMonitoringCascadingTimeline", (data) => this.updateMonitoringCascadingTimeline(data));

        EventBus.$on("setExpandedCardData", async (data) => {
            this.$toast.warning("The node data is being loaded...");

            const result = await axios.post(`/tools-status`, {case_id: this.getCaseId});
            const filteredQueries = result?.data?.data?.filter((res) => res?.tool === "adverse-media-tool");

            let item = null;

            for (const query of filteredQueries) {
                const search = await OsintAxios.post(
                    "/adverse-media/search",
                    {
                        request_id: query?._id,
                        name: query?.query?.name,
                    },
                    {
                        headers: {"x-tool-name": "adverse-media-tool"},
                    }
                );

                const mappedSearchResult = getMappingValue(search?.data?.data, "complyAdvantage", ADVERSE_TOOL_MAPPING);
                item = mappedSearchResult.find((res) => res?.api_all_data?.doc?.id === data?.id);

                if (item) {
                    EventBus.$emit("showExpandedCard", item);
                    this.$toast.success("Loading is completed!");
                    break;
                }
            }

            if (!item) {
                this.$toast.error("Sorry, no data for the node was found.");
            }
        });

        EventBus.$on("showExpandedCard", (data) => {
            this.expandedResultCard.isResultExpanded = true;
            this.expandedResultCard.content = data;
        });
    },
    updated() {
        if (this.treeData)
            this.$refs?.filters?.$children.forEach((ele) => {
                if (ele?.setSelected && {}.toString.call(ele.setSelected) === "[object Function]") ele.setSelected(this.treeData.id ? this.treeData.id : this.treeData.text);
            });
    },
    beforeDestroy() {
        if (this.tabName === "tools") {
            this.SET_TOOLS_DATA({
                ...this._data,
            });
        } else {
            this.SET_MONITORING_DATA({
                ...this._data,
            });
        }

        EventBus.$off("updateMonitoringCascadingTimeline");
        EventBus.$off("setExpandedCardData");
        EventBus.$off("showExpandedCard");
    },
    methods: {
        ...mapMutations(["SET_TOOLS_DATA", "SET_ALL_TOOLS_NAMES", "SET_OVERALL_TOOLS_STATUS", "SET_BUILD_QUERIES", "SET_MONITORING_DATA", "SET_MONITORING_DATE_FILTERS", "SET_SELECTED_TOOL_QUERY", "SET_NER_FILTERS"]),
        ...mapActions([
            "fetchCompanyProducts",
            "getDataMonitoring",
            "getProductMonitoring",
            "getNewMonitoringEvents",
            "getQueryMonitoringEvents",
            "fetchAllResearchScreenData",
            "getAnalysisDropdownData",
            "getAllCaseUsernames",
            "getAllCaseEmails",
            "getAllCasePhones",
            "getAllCaseEducations",
            "getAllCaseEmployments",
            "getAllCaseHobbies",
            "getAllCaseLocations",
            "getAllCaseRegistrants",
            "getAllCaseOrganisations",
            "getAllCaseCompanies",
            "getAllCaseDomains",
            "getAllCaseNames",
            "handleAnalyseQuery",
            "fetchPermissions",
            "getToolsStatus",
            "updateSelectedToolsUsers",
            "setToolsFilterData",
        ]),

        closeExpandedResult() {
            this.expandedResultCard.content = null;
            this.expandedResultCard.isResultExpanded = false;
            this.expandedResultCard.isOldVersionSelected = false;
        },

        handleSidePanelGraph() {
            EventBus.$emit("openSideGraph", this.sideGraphOpen);
            this.sideGraphOpen = this.sideGraphOpen === false ? null : false;
        },

        async getAllToolsData(refreshTicks) {
            this.phoneList = await this.getDropdownData("phone");
            this.emailList = await this.getDropdownData("email");
            this.usernameList = await this.getDropdownData("username");
            this.educationList = await this.getDropdownData("education");
            this.employmentList = await this.getDropdownData("employment");
            this.hobbyList = await this.getDropdownData("hobby");
            this.locationList = await this.getDropdownData("location");
            this.registrantUsers = await this.getDropdownData("registrant");
            this.domainList = await this.getDropdownData("domain");
            this.companyUsers = await this.getDropdownData("company_name");
            this.nameList = await this.getDropdownData("name");
            this.urlList = await this.getDropdownData("url");
            this.organisationList = await this.getDropdownData("organisation_name");
            if (!refreshTicks) {
                this.handleChecked();
                this.redirectTool();
            }
        },

        async getDropdownData(field) {
            let data = this.analysisToolData.filter((user) => {
                return user.val_type === field;
            });
            let caseData = [];
            if (field === "username") {
                this.getAllCaseUsernames();
                caseData = this.getCaseUsernames;
            } else if (field === "email") {
                this.getAllCaseEmails();
                caseData = this.getCaseEmails;
            } else if (field === "phone") {
                this.getAllCasePhones();
                caseData = this.getCasePhones;
            } else if (field === "education") {
                this.getAllCaseEducations();
                caseData = this.getCaseEducations;
            } else if (field === "employment") {
                this.getAllCaseEmployments();
                caseData = this.getCaseEmployments;
            } else if (field === "hobby") {
                this.getAllCaseHobbies();
                caseData = this.getCaseHobbies;
            } else if (field === "location") {
                this.getAllCaseLocations();
                caseData = this.getCaseLocations;
            } else if (field === "registrant") {
                this.getAllCaseRegistrants();
                caseData = this.getCaseRegistrants;
            } else if (field === "organisation_name") {
                this.getAllCaseOrganisations();
                caseData = this.getCaseOrganisations;
            } else if (field === "domain") {
                this.getAllCaseDomains();
                caseData = this.getCaseDomains;
            } else if (field === "company_name") {
                this.getAllCaseCompanies();
                caseData = this.getCaseCompanies;
            } else if (field == "url") {
                caseData = [];
            } else if (field === "name") {
                this.getAllCaseNames();
                caseData = this.getCaseNames;
            }

            let dropdownData = [...data, ...caseData];
            return dropdownData.filter((n) => n.value?.trim());
        },

        getOptionsList(list, group) {
            return [
                {
                    group: group,
                    categories: list,
                },
            ];
        },

        getGroupLabel(type) {
            let type1 = type.split("_")[0];
            let type2 = type.split("_")[1];
            if (type2) return type1.substr(0, 1).toUpperCase() + type1.substr(1) + (type2?.substr(0, 1)?.toUpperCase() + type2?.substr(1));
            else return type1.substr(0, 1).toUpperCase() + type1.substr(1);
        },

        updateData(platform, listData, data, listDataCheck) {
            if (!listDataCheck) {
                this.toolOptions?.children?.forEach((tool) => {
                    if (tool.platform === platform) {
                        tool.data = listData;
                    }
                });
            }

            let listName = `${data.val_type}List`;
            if (data.val_type === "company_name") listName = "companyUsers";
            else if (data.val_type === "registrant") listName = "registrantUsers";
            let item = this.analysisToolData.find((dataItem) => {
                return dataItem.value === data.value && dataItem.platform === platform;
            });
            if (item) item["visited"] = true;
            else {
                this.analysisToolData.push(data);
            }
            let listItem = this[listName].find((dataItem) => {
                return dataItem.value === data.value && dataItem.platform === platform;
            });
            if (listItem) listItem["visited"] = true;
            else {
                this[listName].push(data);
            }
        },

        async addCustomData(data) {
            this.analysisToolData.push(data);
            await this.getAllToolsData(true);
        },

        updateMonitoring(data) {
            this.activeMonitoring = {
                ...this.activeMonitoring,
                ...{
                    status: data.monitoring_status,
                    frequency: data.frequency,
                    count: data.count,
                    duration: data.duration,
                },
            };
            this.updateActiveMonitoring();
        },

        updateFrequency(data) {
            if (data?.isQuery) {
                this.activeMonitoring = {
                    ...data?.query,
                    monitoring_type: "query",
                    status: data?.query.monitoring_status ? "enabled" : "disabled",
                    tool: data?.query.tool,
                };
                this.activeMonitoring.query = {
                    query_id: data?.query._id,
                    query_string: data?.maskedQuery,
                    search_string: data?.maskedQuery,
                };
            } else {
                this.activeMonitoring = {
                    ...data?.query,
                    monitoring_type: "case",
                };
            }
        },

        async getQueriesMonitoring() {
            if (this.currentToolSelected) {
                let url = `/data-monitoring/query?case_id=${this.$route.params?.id}&tool=${this.currentToolSelected}`;
                // let data = {
                //     monitoring_type: "query",
                //     case_id: this.$route.params?.id,
                //     tool: this.currentToolSelected,
                // };
                try {
                    // let response = await axios.post(url, data);
                    let response = await axios.post(url);
                    if (response?.status === 200 && response?.data?.data) {
                        this.monitoringQueries = response?.data?.data;
                        this.query_ids = this.monitoringQueries.map((mq) => mq?.query?.query_id); // map an array for query_ids with monitoring on
                    }
                } catch (error) {
                    console.error(error);
                }
            }
        },

        async getQueriesResultsCount() {
            if (this.currentToolSelected) {
                let url = `/osint-tools/query/results-count`;
                let data = {
                    case_id: this.$route.params?.id,
                    tool: this.currentToolSelected,
                };
                try {
                    // let response = await axios.post(url, data);
                    let response = await axios.post(url, data);
                    if (response?.status === 200 && response?.data?.data) {
                        this.queriesResultsCount = response?.data?.data;
                    }
                } catch (error) {
                    console.error(error);
                }
            }
        },

        async handleRefreshQueries() {
            const {search_queries} = this.$refs;
            await search_queries.handleRefreshQueries();
        },

        updateActiveMonitoring() {
            let match = false;
            if (this.activeMonitoring.monitoring_type === "query") {
                if (this.query_ids.includes(this.activeMonitoring?.query?.query_id)) match = true;
            } else {
                if (this.tools.includes(this.activeMonitoring.tool) && this.activeMonitoring._id) match = true;
            }

            if (match) this.updateDataMonitoring(this.activeMonitoring);
            else this.createDataMonitoring(this.activeMonitoring);
        },

        // Function to set new monitoring
        async createDataMonitoring(monitoring) {
            let url = `/data-monitoring`;
            let freq = parseInt(monitoring.frequency, 10);
            let data = {
                case_id: this.$route.params?.id,
                tool: monitoring.activeProcess || monitoring.tool,
                frequency: freq,
                monitoring_type: monitoring.monitoring_type || "case",
                query: monitoring.query,
                product_name: this.product?.name,
                product_id: this.product?.key,
                status: monitoring.status,
                count: parseInt(monitoring.count, 10),
                duration: parseInt(monitoring.duration, 10),
            };
            try {
                let response = await axios.post(url, data);
                if ((response?.status === 201 || response?.status === 200) && response?.data.status) {
                    this.$toast.success("Monitoring set successfully !");

                    if (this.activeMonitoring.monitoring_type === "query") {
                        await this.getQueriesMonitoring(); // Function to fetch monitoring data
                        await this.getDataMonitoring(); // Function to fetch monitoring data
                    } else {
                        await this.getDataMonitoring(); // Function to fetch monitoring data
                    }
                }
            } catch (error) {
                console.error(error);
            }
        },

        // Function to update monitoring data
        async updateDataMonitoring(monitoring) {
            let url = `/data-monitoring`;
            let freq = parseInt(monitoring.frequency, 10);
            let data = {
                id: monitoring.monitoring_type === "query" ? monitoring.monitoring_id : monitoring._id,
                status: monitoring.status,
                frequency: freq,
                count: parseInt(monitoring.count, 10),
                duration: parseInt(monitoring.duration, 10),
                monitoring_type: monitoring.monitoring_type || "case",
                query: monitoring.query,
            };
            try {
                let response = await axios.put(url, data);
                if (response?.status === 200 && response?.data.status) {
                    this.$toast.success("Monitoring updated successfully !");
                    if (this.activeMonitoring.monitoring_type === "query") {
                        await this.getQueriesMonitoring(); // Function to fetch monitoring data
                        await this.getDataMonitoring(); // Function to fetch monitoring data
                    } else {
                        await this.getDataMonitoring(); // Function to fetch monitoring data
                    }
                    // this.close();
                }
            } catch (error) {
                console.error(error);
            }
        },

        showCollapseIcon() {
            this.collapseIconVisible = true;
        },

        hideCollapseIcon() {
            this.collapseIconVisible = false;
        },

        limitText(length) {
            return ` +${length}`;
        },

        filterInputValues() {
            // filter input values
            let values = [];
            let divider = {
                $isDisabled: true,
                value: "divider",
            };
            let that = this;
            this.input_type.forEach((type) => {
                values = values.concat({
                    type: that.getGroupLabel(type.input_type),
                    values: filterDropdownData({
                        input_type: type.input_type,
                        values: JSON.parse(JSON.stringify(this[type.value])),
                    }),
                });
                // if (values.length)
                //     values = values.concat(divider);
                // values = values.concat(filterDropdownData({
                //     values: JSON.parse(JSON.stringify(this[type.value])),
                //     input_type: type.input_type,
                // }));
            });
            if (!this.input_type || !this.input_type.length) {
                this.input_value_list = [];
                this.input_value = [];
                this.filterCheckedTools();
            }
            this.inputValueOptions = values;
        },

        filterCheckedTools() {
            this.handleChecked();
        },

        selectInputType(selectedItem) {
            this.input_type.push(selectedItem);
            this.filterInputValues();
        },

        removeInputType(removedOption) {
            let index = this.input_type.findIndex((type) => {
                return type.value === removedOption.value;
            });
            if (index > -1) {
                this.input_type.splice(index, 1);
                this.filterInputValues();
            }
        },

        selectInputValue(selectedItem) {
            this.input_value.push(selectedItem);
            this.filterCheckedTools();
            this.autoSearch();
        },

        autoSearch() {
            if (this.input_type.length === 1 && this.input_value.length === 1) {
                const query = cloneDeep(this.searchedQueries.filter((n) => n.tool == this.activeProcess))?.find((ele) => Object.values(ele.query).includes(this.input_value[0].value));
                if (query) this.handleSearchQueryClicked(query);
            }
        },

        removeInputValue(removedOption) {
            let index = this.input_value.findIndex((type) => {
                return type.value === removedOption.value;
            });
            if (index > -1) {
                this.input_value.splice(index, 1);
                this.filterCheckedTools();
            }
        },

        onGraphClose() {
            this.splitGraphView = true;
        },
        toggleGraphDrawer(event) {
            // this.osintGraphDrawerOpen = !this.osintGraphDrawerOpen;
            // window.networkx.redraw();

            this.$emit("handleGraphExpansion", {val: event, key: true});
            // this.comparatorInputValue = event ? (event == false ? 70 : 0) : event == false ? 70 : 100;
            this.toggleAnalyzer = false;
        },

        handleInput(value) {
            this.$emit("handleGraphExpansion", {val: value ? false : null, key: true});
            // this.toggleGraphDrawer(value ? false : null);
            // this.expandedGraph = value ? (value = 100 ? true : false) : null;
        },

        // graphFullExpand(){
        //     return this.expandedGraph
        // },
        toggleGraphView() {
            this.splitGraphView = true;
        },

        filterKeys() {
            var keysToExclude = this.filterValueApiMap[searchType].filter((e) => e.source === searchSource)[0].excludeKeys;
        },

        flattenDict(obj, acc = [], excludeKeys = []) {
            if (typeof obj === "object") {
                for (let x in obj) {
                    if (!excludeKeys.includes(x)) {
                        if (typeof obj[x] === "object") {
                            this.flattenDict(obj[x], acc, excludeKeys);
                        } else if (typeof obj[x] === "array") {
                            for (a in obj[x]) {
                                this.flattenDict(a, acc, excludeKeys);
                            }
                        } else {
                            acc.push([x, obj[x]]);
                        }
                    }
                }
            }
            return acc;
        },

        /***
         * Extract Data from a list of [k,v] pairs, kkeping only keys according to some map defined.
         * Also keep [k.v] pairs which are unique
         */
        extractData(data, searchSource, searchType) {
            let seen = {};
            var keysToKeep = this.filterValueApiMap[searchType].filter((e) => e.source === searchSource)[0].keys;
            var result = data.filter((d) => {
                // for (const x of d){
                if (keysToKeep.includes(d[0]) && seen[d[1]] === undefined) {
                    seen[d[1]] = 1;
                    return true;
                }
                return false;
                // }
            });
            return result;
        },

        clearedSearch(data) {
            //this.graphNodes
        },

        isNodeInGraph(graph, id) {
            const ids = graph.map((e) => e.id);
            return ids.includes(id);
        },

        makeSkypeGraph(data, searchType) {
            let dups = [];
            let nodes = data.data;
            let graphNodes = [];
            let graphLinks = [];

            nodes.forEach((n) => {
                let main_node_id = `skype__username__${n.username}`;

                let skypeids_node = {
                    id: main_node_id,
                    source: ["skype"],
                    label: n.username,
                    searchType: "username",
                    type: "username",
                    // color: "red",
                    // group: "source",
                };

                let connected_nodes = [];

                connected_nodes = [
                    {node: n.province, name: "province"},
                    {node: n.birthday, name: "birthday"},
                    {node: n.phoneoffice, name: "phoneoffice"},
                    {node: n.phoneMobile, name: "phoneMobile"},
                    {node: n.phoneHome, name: "phoneHome"},
                    {node: n.jobtitle, name: "jobtitle"},
                    // { node: n.firstname, name: "firstname" },
                    {node: n.city, name: "city"},
                    {node: n.country, name: "country"},
                ];

                graphNodes.push(skypeids_node);
                graphLinks.push({from: main_node_id, to: this.currentQueryNodeId, title: "Skype"});

                connected_nodes.forEach((cn) => {
                    if (cn.node) {
                        let id = `skype__username__${n.username}__${cn.node}`;
                        let _cn = {
                            id: id,
                            label: cn.node,
                            source: ["skype"],
                            searchType: cn.name,
                            type: cn.name,
                        };

                        graphNodes.push(_cn);
                        graphLinks.push({
                            from: id,
                            to: main_node_id,
                            title: "Skype",
                        });
                    }
                });
            });
            return {nodes: graphNodes, links: graphLinks, dups: dups};
        },

        makePersonIndentifierGraph(data, searchType) {
            let nodes = data.data;
            let graphNodes = [];
            let graphLinks = [];

            nodes.forEach((n) => {
                let _name = "[Blank Name]";
                if (n.names !== undefined) _name = n.names[0].display;

                let main_node_id = `person__identifier__username__${_name}`;
                let possible_person = {
                    id: main_node_id, //kunal aggarl
                    source: ["personIdentifier"],
                    label: _name,
                    searchType: "personIdentifier",
                    type: "person",
                };

                graphLinks.push({from: main_node_id, to: this.currentQueryNodeId, title: "personIdentifier"});
                graphNodes.push(possible_person);

                let connected_nodes = [];

                if (n.addresses !== undefined) {
                    n.addresses.forEach((add) => {
                        connected_nodes.push({node: add.display, name: "address"});
                    });
                }

                if (n.jobs !== undefined) {
                    n.jobs.forEach((job) => {
                        connected_nodes.push({node: job.display, name: "current_employer"});
                    });
                }
                if (n.emails !== undefined) {
                    n.emails.forEach((email) => {
                        connected_nodes.push({node: email.display, name: "email"});
                    });
                }
                if (n.user_ids !== undefined) {
                    n.user_ids.forEach((id) => {
                        connected_nodes.push({node: id.display, name: "username"});
                    });
                }
                if (n.urls !== undefined) {
                    n.urls.forEach((url) => {
                        connected_nodes.push({node: url.display, name: "url"});
                    });
                }
                if (n.relationships !== undefined) {
                    n.relationships.forEach((rel) => {
                        connected_nodes.push({node: rel.names[0].display, name: "user"});
                    });
                }
                if (n.phones !== undefined) {
                    n.phones.forEach((phone) => {
                        connected_nodes.push({node: phone.display, name: "phone"});
                    });
                }
                if (n.origin_countries !== undefined) {
                    n.origin_countries.forEach((country) => {
                        connected_nodes.push({node: country.display, name: "country"});
                    });
                }
                if (n.usernames !== undefined) {
                    n.usernames.forEach((username) => {
                        connected_nodes.push({node: username.display, name: "username"});
                    });
                }
                connected_nodes.forEach((cn) => {
                    if (cn.node) {
                        let id = `person__identifier__username__${n.name}__${cn.node}`;
                        let _cn = {
                            id: id,
                            label: cn.node,
                            source: ["personIdentifier"],
                            searchType: cn.name,
                            type: cn.name,
                        };
                        graphNodes.push(_cn);
                        graphLinks.push({
                            from: _cn.id,
                            to: main_node_id,
                            title: "personIdentifier",
                        });
                    }
                });
            });
            return {nodes: graphNodes, links: graphLinks, dups: []};
        },
        makeTwitterQueryGraph(data) {
            let nodes = data.data;
            let graphNodes = [];
            let graphLinks = [];

            nodes.forEach((node) => {
                let main_node_id = `twitter__query__${node.screen_name}`;
                let name_search_node = {
                    id: main_node_id,
                    source: ["twitter"],
                    label: node.screen_name,
                    searchType: "username",
                    type: "username",
                };
                graphNodes.push(name_search_node);
                graphLinks.push({from: main_node_id, to: this.currentQueryNodeId, title: "Twitter"});
                if (node.name) {
                    let name_id = `twitter__query_name__${node.name}`;
                    let name_search_node = {
                        id: name_id,
                        source: ["twitter"],
                        label: node.name,
                        searchType: "name",
                        type: "name",
                    };
                    graphNodes.push(name_search_node);
                    graphLinks.push({from: name_id, to: main_node_id, title: "Twitter"});
                }
            });

            return {nodes: graphNodes, links: graphLinks};
        },
        makeNameSearch(data) {
            let nodes = data.data;
            let graphNodes = [];
            let graphLinks = [];
            let dups = [];
            nodes = nodes.filter((n) => Object.values(n)[0].length > 0);

            nodes.forEach((ele) => {
                let main_node_id = `name__search__${Object.keys(ele)[0]}`;

                let name_search_node = {
                    id: main_node_id,
                    source: ["name_search"],
                    label: Object.keys(ele)[0],
                    searchType: "name",
                    type: Object.keys(ele)[0],
                };

                graphNodes.push(name_search_node);
                graphLinks.push({from: main_node_id, to: this.currentQueryNodeId, title: "NameSearch"});

                ele[Object.keys(ele)[0]].forEach((elem) => {
                    let id = "";
                    let label = "";
                    if (Object.keys(ele)[0] === "twitter") {
                        id = `name__search__username__${Object.keys(ele)[0]}__${elem.screen_name}`;
                        label = elem.screen_name;
                    } else if (Object.keys(ele)[0] === "github") {
                        id = `name__search__username__${Object.keys(ele)[0]}__${elem.login}`;
                        label = elem.login;
                    } else if (Object.keys(ele)[0] === "gitlab" || Object.keys(ele)[0] === "instagram") {
                        id = `name__search__username__${Object.keys(ele)[0]}__${elem.username}`;
                        label = elem.username;
                    } else if (Object.keys(ele)[0] === "linkedin") {
                        id = `name__search__username__${Object.keys(ele)[0]}__${elem.publicIdentifier}`;
                        label = elem.publicIdentifier;
                    }
                    if (label) {
                        const name_elem = {
                            id,
                            label,
                            source: ["name_search"],
                            searchType: "name",
                            type: "username",
                        };

                        graphNodes.push(name_elem);

                        graphLinks.push({
                            from: id,
                            to: main_node_id,
                            title: "NameSearch",
                        });
                    }
                });
            });
            return {nodes: graphNodes, links: graphLinks, dups: dups};
        },

        makeCompanyGraph(data) {
            let nodes = data.data;
            let graphNodes = [];
            let graphLinks = [];
            // nodes = nodes.map((ele) => ele.company).filter((ele) => ele?.jurisdiction);
            nodes = nodes.filter((ele) => ele?.jurisdiction);
            let dups = [];
            nodes.forEach((elem) => {
                let main_node_id = `company___${getJurisdictionName(elem.jurisdiction)}`;

                let name_search_node = {
                    id: main_node_id,
                    source: ["company"],
                    label: getJurisdictionName(elem.jurisdiction.split("_")[0]),
                    searchType: "name",
                    type: "country",
                    flag_code: elem.jurisdiction.split("_")[0],
                    flag: true,
                };

                graphNodes.push(name_search_node);
                graphLinks.push({from: main_node_id, to: this.currentQueryNodeId, title: "CompanyName"});

                const all_comp = nodes.filter((n) => n.jurisdiction === elem.jurisdiction);

                all_comp.forEach((ele) => {
                    if (ele.company_name) {
                        const id = `company__name__${ele.company_name}`;
                        const company_elem = {
                            id: id,
                            label: ele.company_name,
                            source: ["company"],
                            searchType: "company",
                            type: "company",
                        };

                        graphNodes.push(company_elem);
                        graphLinks.push({
                            from: id,
                            to: main_node_id,
                            title: "CompanyName",
                        });

                        // Opencorporates
                        if (ele.api_all_data?.officer?.name || ele.api_all_data?.officer?.directorName) {
                            const id = `officer__name__${ele.api_all_data.officer.name}` || `officer__name__${ele.api_all_data.officer.directorName}`;
                            const officers_elem = {
                                id: id,
                                label: ele.api_all_data.officer.name || ele.api_all_data.officer.directorName,
                                source: ["company"],
                                searchType: "name",
                                type: "name",
                            };

                            graphNodes.push(officers_elem);
                            graphLinks.push({
                                from: id,
                                to: company_elem.id,
                                title: "CompanyName",
                            });
                        }

                        ele.officers?.forEach((ele1) => {
                            const id = `officer__name__${ele1.officer.name}`;
                            const officers_elem = {
                                id: id,
                                label: ele1.officer.name,
                                source: ["company"],
                                searchType: "name",
                                type: ele1.officer.entity_type === "Organisation" || ele1.officer.entity_type === "Company" ? "company" : "name",
                            };

                            graphNodes.push(officers_elem);
                            graphLinks.push({
                                from: id,
                                to: company_elem.id,
                                title: "CompanyName",
                            });
                        });

                        const {nodes, links} = this.handleBVDgraph(ele?.api_all_data, company_elem.id);
                        graphNodes.push(...nodes);
                        graphLinks.push(...links);
                        // ele?.api_all_data?.PHONE_NUMBER?.forEach((ele2) => {
                        //     const id = `phone_number_${ele2}`;
                        //     const officers_elem = {
                        //         id: id,
                        //         label: ele2,
                        //         source: ["company"],
                        //         searchType: "phone",
                        //         type: "phone",
                        //     };
                        //     graphNodes.push(officers_elem);
                        //     graphLinks.push({
                        //         from: id,
                        //         to: company_elem.id,
                        //         title: "CompanyName",
                        //     });
                        // });

                        // ele?.api_all_data?.SUBSIDIARIES_INFO?.forEach((ele3) => {
                        //     if (ele3.SUB_NAME) {
                        //         const id = `company__name___${ele3.SUB_NAME}__`;
                        //         const officers_elem = {
                        //             id: id,
                        //             label: ele3.SUB_NAME,
                        //             source: ["company"],
                        //             searchType: "name",
                        //             type: "company",
                        //         };
                        //         graphNodes.push(officers_elem);
                        //         graphLinks.push({
                        //             from: id,
                        //             to: company_elem.id,
                        //             title: "CompanyName",
                        //         });
                        //         if (ele3.SUB_PHONE_NUMBER) {
                        //             const sub_phone_id = `sub__phone__${ele3.SUB_PHONE_NUMBER}__`;
                        //             const phone_elem = {
                        //                 id: sub_phone_id,
                        //                 label: ele3.SUB_PHONE_NUMBER,
                        //                 source: ["company"],
                        //                 searchType: "phone",
                        //                 type: "phone",
                        //             };
                        //             graphNodes.push(phone_elem);
                        //             graphLinks.push({
                        //                 from: sub_phone_id,
                        //                 to: id,
                        //                 title: "CompanyName",
                        //             });
                        //         }
                        //     }
                        // });

                        ele.chain_data?.entity?.forEach((ele2) => {
                            const id = `ubo__name__${ele2.name}`;
                            const ubo_elem = {
                                id: id,
                                label: ele2.name,
                                source: ["company"],
                                searchType: "name",
                                type: ele2.name_type == "Company" ? "company" : "name",
                            };

                            graphNodes.push(ubo_elem);
                            graphLinks.push({
                                from: id,
                                to: company_elem.id,
                                title: "CompanyName",
                            });
                        });
                    }
                    //Clarified by
                    if (ele?.api_all_data?.subject_name?.name_en) {
                        const id = `company__name__${ele.api_all_data.subject_name.name_en}`;
                        const company_elem = {
                            id: id,
                            label: ele.api_all_data.subject_name.name_en,
                            source: ["company"],
                            searchType: "company",
                            type: "name",
                        };

                        graphNodes.push(company_elem);
                        graphLinks.push({
                            from: id,
                            to: main_node_id,
                            title: "CompanyName",
                        });
                        ele?.management?.results?.forEach((result) => {
                            if (result?.entity_name?.name_en) {
                                const result_id = `company_management__${result?.entity_name?.name_en}`;
                                const result_elem = {
                                    id: result_id,
                                    label: result?.entity_name?.name_en,
                                    source: ["company"],
                                    searchType: "name",
                                    type: result.entity_type === "Organisation" || result.entity_type === "Company" ? "company" : "name",
                                };

                                graphNodes.push(result_elem);
                                graphLinks.push({
                                    from: result_id,
                                    to: company_elem.id,
                                    title: "CompanyName",
                                });
                            }
                        });
                        ele?.holdings?.results?.forEach((result) => {
                            if (result?.entity_name?.name_en) {
                                const result_id = `company_management__${result?.entity_name?.name_en}`;
                                const result_elem = {
                                    id: result_id,
                                    label: result?.entity_name?.name_en,
                                    source: ["company"],
                                    searchType: "name",
                                    type: result.entity_type === "Organisation" || result.entity_type === "Company" ? "company" : "name",
                                };

                                graphNodes.push(result_elem);
                                graphLinks.push({
                                    from: result_id,
                                    to: company_elem.id,
                                    title: "CompanyName",
                                });
                            }
                        });
                        ele?.activities?.results?.forEach((result) => {
                            if (result?.entity_name?.name_en) {
                                const result_id = `company_management__${result?.entity_name?.name_en}`;
                                const result_elem = {
                                    id: result_id,
                                    label: result?.entity_name?.name_en,
                                    source: ["company"],
                                    searchType: "name",
                                    type: result.entity_type === "Organisation" || result.entity_type === "Company" ? "company" : "name",
                                };

                                graphNodes.push(result_elem);
                                graphLinks.push({
                                    from: result_id,
                                    to: company_elem.id,
                                    title: "CompanyName",
                                });
                            }
                        });
                        ele?.ownership?.results?.forEach((result) => {
                            if (result?.entity_name?.name_en) {
                                const result_id = `company_management__${result?.entity_name?.name_en}`;
                                const result_elem = {
                                    id: result_id,
                                    label: result?.entity_name?.name_en,
                                    source: ["company"],
                                    searchType: "name",
                                    type: result.entity_type === "Organisation" || result.entity_type === "Company" ? "company" : "name",
                                };

                                graphNodes.push(result_elem);
                                graphLinks.push({
                                    from: result_id,
                                    to: company_elem.id,
                                    title: "CompanyName",
                                });
                            }
                        });
                    }
                });
                //Company Watch Logic
                const all_officers = nodes
                    .map((ele) => ({
                        ...ele.api_all_data,
                        ...{CurrentAppointments: ele.CurrentAppointments},
                        ...{PreviousAppointments: ele.PreviousAppointments},
                    }))
                    .filter((ele) => ele.directorName);

                all_officers.forEach((ele, index) => {
                    const id = `officer__name__${ele.directorName}`;
                    const company_elem = {
                        id: id,
                        label: ele.directorName,
                        source: ["company"],
                        searchType: "name",
                        type: "name",
                    };

                    graphNodes.push(company_elem);
                    graphLinks.push({
                        from: id,
                        to: main_node_id,
                        title: "CompanyName",
                    });
                    ele?.CurrentAppointments?.forEach((ele2) => {
                        const id = `company__name__${ele2.companyName}__`;
                        const ubo_elem = {
                            id: id,
                            label: ele2.companyName,
                            source: ["company"],
                            searchType: "name",
                            type: "company",
                        };

                        graphNodes.push(ubo_elem);
                        graphLinks.push({
                            from: id,
                            to: company_elem.id,
                            title: "CompanyName",
                        });
                    });
                    ele?.PreviousAppointments?.forEach((ele2) => {
                        const id = `company__name__${ele2.companyName}__`;
                        const ubo_elem = {
                            id: id,
                            label: ele2.companyName,
                            source: ["company"],
                            searchType: "name",
                            type: "company",
                        };

                        graphNodes.push(ubo_elem);
                        graphLinks.push({
                            from: id,
                            to: company_elem.id,
                            title: "CompanyName",
                        });
                    });
                });

                const all_shareholders = nodes.map((ele) => ele.api_all_data).filter((ele) => ele.shareholders);
                all_shareholders.forEach((ele, index) => {
                    const id = `company__${ele.name}`;
                    const company_elem = {
                        id: id,
                        label: ele.name,
                        source: ["company"],
                        searchType: "company",
                        type: "company_name",
                    };

                    graphNodes.push(company_elem);
                    graphLinks.push({
                        from: id,
                        to: main_node_id,
                        title: "CompanyName",
                    });
                    ele.shareholders?.forEach((shareHolder) => {
                        if (shareHolder.fullName) {
                            const id = `company__name___${shareHolder.fullName}__share_holder`;
                            const officers_elem = {
                                id: id,
                                label: `${shareHolder.fullName}`,
                                source: ["company"],
                                searchType: "name",
                                type: this.getAnalyserSource(shareHolder.shareholderType?.description, "individual"),
                            };
                            graphNodes.push(officers_elem);
                            graphLinks.push({
                                from: id,
                                to: company_elem.id,
                                title: "CompanyName",
                            });
                            // type: shareHolder.SH_ENTITY_TYPE === "Financial company" ? "company" : "name",
                        }
                    });
                });
            });

            //BVD officers
            let bvd_officers_node = data.data.filter((ele) => ele.key === "officer_bvd");
            if (bvd_officers_node.length > 0) {
                bvd_officers_node.forEach(({api_all_data: node}) => {
                    let main_node_id = `company___${node.CONTACTS_MEMBERSHIP_NameCompany}`;
                    let officer_bvd_node = {
                        id: main_node_id,
                        source: ["company"],
                        label: node.CONTACTS_MEMBERSHIP_NameCompany,
                        searchType: "company",
                        type: "company",
                    };
                    graphNodes.push(officer_bvd_node);
                    graphLinks.push({from: main_node_id, to: this.currentQueryNodeId, title: "CompanyName"});
                    if (node.officerName) {
                        let id = `company___${node.officerName}__officer_bvd`;
                        let officer_bvd_node = {
                            id: id,
                            source: ["company"],
                            label: node.officerName,
                            searchType: "name",
                            type: "name",
                        };
                        graphNodes.push(officer_bvd_node);
                        graphLinks.push({from: id, to: main_node_id, title: "CompanyName"});
                        const {nodes, links} = this.handleBVDgraph(node, officer_bvd_node.id);
                        graphNodes.push(...nodes);
                        graphLinks.push(...links);
                    }
                });
            }

            return {nodes: graphNodes, links: graphLinks, dups: dups};
        },

        handleBVDgraph(node, to_id) {
            let graphNodes = [];
            let graphLinks = [];
            Array.isArray(node?.PHONE_NUMBER ?? []) &&
                node?.PHONE_NUMBER?.forEach((phone) => {
                    const id = `phone_number_${phone}`;
                    const officers_elem = {
                        id: id,
                        label: phone,
                        source: ["company"],
                        searchType: "phone",
                        type: "phone",
                    };
                    graphNodes.push(officers_elem);
                    graphLinks.push({
                        from: id,
                        to: to_id,
                        title: "CompanyName",
                    });
                });
            Array.isArray(node?.SUBSIDIARIES_INFO ?? []) &&
                node?.SUBSIDIARIES_INFO?.forEach((ele3) => {
                    if (ele3.SUB_NAME) {
                        const id = `company__name___${ele3.SUB_NAME}__`;
                        const officers_elem = {
                            id: id,
                            label: ele3.SUB_NAME,
                            source: ["company"],
                            searchType: "name",
                            type: this.getAnalyserSource(ele3.SUB_ENTITY_TYPE, "one or more named individuals or families"),
                        };
                        graphNodes.push(officers_elem);
                        graphLinks.push({
                            from: id,
                            to: to_id,
                            title: "CompanyName",
                        });
                        if (ele3.SUB_PHONE_NUMBER) {
                            const sub_phone_id = `sub__phone__${ele3.SUB_PHONE_NUMBER}__`;
                            const phone_elem = {
                                id: sub_phone_id,
                                label: ele3.SUB_PHONE_NUMBER,
                                source: ["company"],
                                searchType: "phone",
                                type: "phone",
                            };
                            graphNodes.push(phone_elem);
                            graphLinks.push({
                                from: sub_phone_id,
                                to: id,
                                title: "CompanyName",
                            });
                        }
                    }
                });

            Array.isArray(node?.SHAREHOLDER_INFORMATION ?? []) &&
                node?.SHAREHOLDER_INFORMATION?.forEach((shareHolder) => {
                    if (shareHolder.SH_NAME) {
                        const id = `company__name___${shareHolder.SH_NAME}__share_holder`;
                        const officers_elem = {
                            id: id,
                            label: shareHolder.SH_NAME,
                            source: ["company"],
                            searchType: "name",
                            type: this.getAnalyserSource(shareHolder.SH_ENTITY_TYPE, "one or more named individuals or families"),
                        };
                        graphNodes.push(officers_elem);
                        graphLinks.push({
                            from: id,
                            to: to_id,
                            title: "CompanyName",
                        });
                        // type: shareHolder.SH_ENTITY_TYPE === "Financial company" ? "company" : "name",
                        if (shareHolder?.SUB_PHONE_NUMBER) {
                            const sub_phone_id = `sub__phone__${shareHolder.SUB_PHONE_NUMBER}__`;
                            const phone_elem = {
                                id: sub_phone_id,
                                label: shareHolder.SUB_PHONE_NUMBER,
                                source: ["company"],
                                searchType: "phone",
                                type: "phone",
                            };
                            graphNodes.push(phone_elem);
                            graphLinks.push({
                                from: sub_phone_id,
                                to: id,
                                title: "CompanyName",
                            });
                        }
                    }
                });

            Array.isArray(node?.SHAREHOLDERS ?? []) &&
                node?.SHAREHOLDERS?.forEach((shareHolder) => {
                    if (shareHolder.SH_LAST_NAME || shareHolder.SH_FIRST_NAME) {
                        const id = `company__name___${shareHolder.SH_FIRST_NAME}__${shareHolder.SH_LAST_NAME}__share_holder`;
                        const officers_elem = {
                            id: id,
                            label: `${shareHolder.SH_FIRST_NAME} ${shareHolder.SH_LAST_NAME}`,
                            source: ["company"],
                            searchType: "name",
                            type: this.getAnalyserSource(shareHolder.SH_ENTITY_TYPE, "one or more named individuals or families"),
                        };
                        graphNodes.push(officers_elem);
                        graphLinks.push({
                            from: id,
                            to: to_id,
                            title: "CompanyName",
                        });
                        // type: shareHolder.SH_ENTITY_TYPE === "Financial company" ? "company" : "name",
                    }
                });

            Array.isArray(node?.GLOBAL_ULTIMATE_OWNER_INFO ?? []) &&
                node?.GLOBAL_ULTIMATE_OWNER_INFO?.forEach((owners) => {
                    if (owners.GUO_NAME) {
                        const id = `company__name___${owners.GUO_NAME}__owner`;
                        const officers_elem = {
                            id: id,
                            label: owners.GUO_NAME,
                            source: ["company"],
                            searchType: "name",
                            type: this.getAnalyserSource(owners.GUO_ENTITY_TYPE, "one or more named individuals or families"),
                        };
                        graphNodes.push(officers_elem);
                        graphLinks.push({
                            from: id,
                            to: to_id,
                            title: "CompanyName",
                        });
                        // type: shareHolder.SH_ENTITY_TYPE === "Financial company" ? "company" : "name",
                        if (owners?.GUO_PHONE_NUMBER) {
                            const sub_phone_id = `sub__phone__${owners.GUO_PHONE_NUMBER}__`;
                            const phone_elem = {
                                id: sub_phone_id,
                                label: owners.GUO_PHONE_NUMBER,
                                source: ["company"],
                                searchType: "phone",
                                type: "phone",
                            };
                            graphNodes.push(phone_elem);
                            graphLinks.push({
                                from: sub_phone_id,
                                to: id,
                                title: "CompanyName",
                            });
                        }
                    }
                });

            Array.isArray(node?.CONTROLLING_SHAREHOLDERS ?? []) &&
                node?.CONTROLLING_SHAREHOLDERS?.forEach((shareholder) => {
                    if (shareholder.CSH_NAME) {
                        const id = `company__name___${shareholder.CSH_NAME}__shareholder`;
                        const officers_elem = {
                            id: id,
                            label: shareholder.CSH_NAME,
                            source: ["company"],
                            searchType: "name",
                            type: this.getAnalyserSource(shareholder.CSH_ENTITY_TYPE, "one or more named individuals or families"),
                        };
                        graphNodes.push(officers_elem);
                        graphLinks.push({
                            from: id,
                            to: to_id,
                            title: "CompanyName",
                        });
                        if (shareholder?.CSH_PHONE_NUMBER) {
                            const sub_phone_id = `sub__phone__${shareholder.CSH_PHONE_NUMBER}__`;
                            const phone_elem = {
                                id: sub_phone_id,
                                label: shareholder.CSH_PHONE_NUMBER,
                                source: ["company"],
                                searchType: "phone",
                                type: "phone",
                            };
                            graphNodes.push(phone_elem);
                            graphLinks.push({
                                from: sub_phone_id,
                                to: id,
                                title: "CompanyName",
                            });
                        }
                    }
                });

            Array.isArray(node?.IMMEDIATE_PARENT_INFO ?? []) &&
                node?.IMMEDIATE_PARENT_INFO?.forEach((info) => {
                    if (info.ISH_NAME) {
                        const id = `company__name___${info.ISH_NAME}__info`;
                        const officers_elem = {
                            id: id,
                            label: info.ISH_NAME,
                            source: ["company"],
                            searchType: "name",
                            type: this.getAnalyserSource(info.ISH_ENTITY_TYPE, "one or more named individuals or families"),
                        };
                        graphNodes.push(officers_elem);
                        graphLinks.push({
                            from: id,
                            to: to_id,
                            title: "CompanyName",
                        });
                        if (info?.ISH_PHONE_NUMBER) {
                            const sub_phone_id = `sub__phone__${info.ISH_PHONE_NUMBER}__`;
                            const phone_elem = {
                                id: sub_phone_id,
                                label: info.ISH_PHONE_NUMBER,
                                source: ["company"],
                                searchType: "phone",
                                type: "phone",
                            };
                            graphNodes.push(phone_elem);
                            graphLinks.push({
                                from: sub_phone_id,
                                to: id,
                                title: "CompanyName",
                            });
                        }
                    }
                });

            Array.isArray(node?.DIRECTORS ?? []) &&
                node?.DIRECTORS?.forEach((director) => {
                    if (director.CPYCONTACTS_HEADER_FullNameOriginalLanguagePreferred) {
                        const id = `company__name___${director.CPYCONTACTS_HEADER_FullNameOriginalLanguagePreferred}__director`;
                        const officers_elem = {
                            id: id,
                            label: director.CPYCONTACTS_HEADER_FullNameOriginalLanguagePreferred,
                            source: ["company"],
                            searchType: "name",
                            type: this.getAnalyserSource(director.CPYCONTACTS_MEMBERSHIP_Function, "auditor", "company_name"),
                        };
                        graphNodes.push(officers_elem);
                        graphLinks.push({
                            from: id,
                            to: to_id,
                            title: "CompanyName",
                        });
                    }
                });
            return {nodes: graphNodes, links: graphLinks};
        },

        makeAdverseMediaGraph(data) {
            let nodes = data.data;
            let graphNodes = [];
            let graphLinks = [];
            nodes.forEach((ele) => {
                let main_node_id = `adverse_media_graph___${ele.name}`;
                let adverse_media_node = {
                    id: main_node_id,
                    source: ["adverse-media"],
                    label: ele.name,
                    searchType: "name",
                    type: ele?.entity_type == "person" ? "name" : "company",
                };
                graphNodes.push(adverse_media_node);
                graphLinks.push({from: main_node_id, to: this.currentQueryNodeId, title: "AdverseMedia"});

                ele?.api_all_data?.doc?.media?.forEach((ele1) => {
                    let id = `adverse_media_graph__${ele.name}__${ele1.url}`;
                    let media_link = {
                        id,
                        label: ele1.url,
                        source: ["adverse-media"],
                        searchType: "name",
                        type: "link",
                    };
                    graphNodes.push(media_link);
                    graphLinks.push({
                        from: media_link.id,
                        to: main_node_id,
                        title: "AdverseMedia",
                    });
                });

                ele?.api_all_data?.doc?.associates?.forEach((ele2) => {
                    // let id = `adverse_media_graph__${ele.name}__${ele1}`;
                    const associate_name = `${get(ele2, "name", "")}`;
                    let id = `adverse_media_graph__${ele.name}__${associate_name}`;
                    let associate = {
                        id,
                        label: associate_name,
                        source: ["adverse-media"],
                        searchType: "name",
                        type: "name",
                    };
                    graphNodes.push(associate);
                    graphLinks.push({
                        from: associate.id,
                        to: main_node_id,
                        title: "AdverseMedia",
                    });
                });
                let sources = ["wl", "ame", "soe"];
                sources?.forEach((src) => {
                    ele?.api_all_data[src]?.associates?.forEach((ele2) => {
                        let name = `${get(ele2, "name")}`;
                        const associate_name = name;
                        let id = `adverse_media_graph__${ele.name}__${associate_name}`;
                        let associate = {
                            id,
                            label: associate_name,
                            source: ["adverse-media"],
                            searchType: "name",
                            type: this.getAnalyserSource(ele2.entity_type, "person"),
                        };
                        graphNodes.push(associate);
                        graphLinks.push({
                            from: associate.id,
                            to: main_node_id,
                            title: "AdverseMedia",
                        });
                    });
                });

                let aka_names = ele.aka_names ? ele.aka_names.split(",") : [];
                aka_names?.forEach((ele1) => {
                    let id = `adverse_media_graph__${ele.name}__${ele1}`;
                    let media_link = {
                        id,
                        label: ele1,
                        source: ["adverse-media"],
                        searchType: "name",
                        type: this.getAnalyserSource(ele.entity_type, "person"),
                    };
                    graphNodes.push(media_link);
                    graphLinks.push({
                        from: media_link.id,
                        to: main_node_id,
                        title: "AdverseMedia",
                    });
                });

                ele?.api_all_data?.associates?.forEach((ele2) => {
                    // let id = `adverse_media_graph__${ele.name}__${ele1}`;
                    ele2?.associate?.NameDetails?.Name?.forEach((ele3) => {
                        const associate_name = `${get(ele3, "NameValue[0].FirstName", "")} ${get(ele3, "NameValue[0].MiddleName", "")} ${get(ele3, "NameValue[0].Surname", "")} ${get(ele3, "NameValue[0].MaidenName", "")}`;
                        let id = `adverse_media_graph__${ele.name}__${associate_name}`;
                        let media_link = {
                            id,
                            label: associate_name,
                            source: ["adverse-media"],
                            searchType: "name",
                            type: "name",
                        };
                        graphNodes.push(media_link);
                        graphLinks.push({
                            from: media_link.id,
                            to: main_node_id,
                            title: "AdverseMedia",
                        });
                    });
                });

                ele?.api_all_data?.reportedLinked?.forEach((ele2) => {
                    const reporter_name = `${get(ele2, "name", "")}`;
                    let id = `adverse_media_graph__${ele.name}__${reporter_name}`;
                    let reporter = {
                        id,
                        label: reporter_name,
                        source: ["adverse-media"],
                        searchType: "name",
                        type: "name",
                    };
                    graphNodes.push(reporter);
                    graphLinks.push({
                        from: reporter.id,
                        to: main_node_id,
                        title: "AdverseMedia",
                    });
                });

                ele?.api_all_data?.companiesReported?.forEach((ele2) => {
                    const company_name = `${get(ele2, "name", "")}`;
                    let id = `adverse_media_graph__${ele.name}__${company_name}`;
                    let company = {
                        id,
                        label: company_name,
                        source: ["adverse-media"],
                        searchType: "name",
                        type: "company",
                    };
                    graphNodes.push(company);
                    graphLinks.push({
                        from: company.id,
                        to: main_node_id,
                        title: "AdverseMedia",
                    });
                });
            });
            return {nodes: graphNodes, links: graphLinks};
        },

        makeOffshoreLeaksGraph(data) {
            let nodes = data.data;
            let graphNodes = [];
            let graphLinks = [];
            nodes.forEach((ele) => {
                let main_node_id = `offshore_leaks_graph___${ele.name}`;
                let offshore_leaks_node = {
                    id: main_node_id,
                    source: ["offshoreleaks"],
                    label: ele.name,
                    searchType: "name",
                    type: ele?.entity_type == "person" ? "name" : "company",
                };
                graphNodes.push(offshore_leaks_node);
                graphLinks.push({from: main_node_id, to: this.currentQueryNodeId, title: "OffshoreLeaks"});

                for (const data_type in ele?.api_all_data?.nodes) {
                    const nodeList = ele?.api_all_data?.nodes[data_type];
                    nodeList?.forEach((ele2) => {
                        let reporter_name = "";
                        if (data_type === "address") reporter_name = `${get(ele2, "doc.name")}` || `${get(ele2, "doc.address")}`;
                        else reporter_name = `${get(ele2, "doc.name", "")}`;

                        let id = `offshore_leaks_graph__${ele.name}__${reporter_name}`;
                        let reporter = {
                            id,
                            label: reporter_name,
                            source: ["offshoreleaks"],
                            searchType: data_type === "intermediary" || data_type === "entity" || data_type === "other" ? "company_name" : "name",
                            type: data_type === "intermediary" || data_type === "entity" || data_type === "other" ? "company_name" : "name",
                        };
                        graphNodes.push(reporter);
                        graphLinks.push({
                            from: reporter.id,
                            to: main_node_id,
                            title: "OffshoreLeaks",
                        });
                    });
                }
            });
            return {nodes: graphNodes, links: graphLinks};
        },

        makeLexisBridgerGraph(data) {
            let nodes = data.data;
            let graphNodes = [];
            let graphLinks = [];
            nodes.forEach((ele) => {
                let main_node_id = `lexis_bridger_graph___${ele.name}`;
                let lexis_bridger_node = {
                    id: main_node_id,
                    source: ["lexis"],
                    label: ele.name,
                    searchType: "name",
                    type: ele?.entity_type == "person" ? "name" : "company",
                };
                graphNodes.push(lexis_bridger_node);
                graphLinks.push({from: main_node_id, to: this.currentQueryNodeId, title: "LexisBridger"});

                for (const data_type in ele?.api_all_data?.nodes) {
                    const nodeList = ele?.api_all_data?.nodes[data_type];
                    nodeList?.forEach((ele2) => {
                        let reporter_name = "";
                        if (data_type === "address") reporter_name = `${get(ele2, "doc.name")}` || `${get(ele2, "doc.address")}`;
                        else reporter_name = `${get(ele2, "doc.name", "")}`;

                        let id = `lexis_bridger_graph__${ele.name}__${reporter_name}`;
                        let reporter = {
                            id,
                            label: reporter_name,
                            source: ["lexis"],
                            searchType: data_type === "intermediary" || data_type === "entity" || data_type === "other" ? "company_name" : "name",
                            type: data_type === "intermediary" || data_type === "entity" || data_type === "other" ? "company_name" : "name",
                        };
                        graphNodes.push(reporter);
                        graphLinks.push({
                            from: reporter.id,
                            to: main_node_id,
                            title: "LexisBridger",
                        });
                    });
                }
            });
            return {nodes: graphNodes, links: graphLinks};
        },

        makeCompanyDomainCompaniesGraph(data) {
            let nodes = data.data;
            let graphNodes = [];
            let graphLinks = [];
            nodes.forEach((ele) => {
                let id = `company_domain_companies__${ele.company.name}`;
                let company_link = {
                    id,
                    label: ele.company.name,
                    source: ["domain"],
                    searchType: "domain",
                    type: "company",
                };
                graphNodes.push(company_link);
                graphLinks.push({
                    from: id,
                    to: this.currentQueryNodeId,
                    title: "Company",
                });
                ele?.company?.companies?.forEach((ele1) => {
                    let companies_id = `company_domain_companies__${ele1.company.name}__${ele1.company.company_number}`;
                    let companies_link = {
                        id: companies_id,
                        label: ele1.company.name,
                        source: ["domain"],
                        searchType: "domain",
                        type: "company",
                    };
                    graphNodes.push(companies_link);
                    graphLinks.push({
                        from: companies_link.id,
                        to: id,
                        title: "Company",
                    });
                });
                ele?.company?.employees?.forEach((ele2) => {
                    let employees_id = `company_domain_employees__${ele2.name}__${ele2.id}`;
                    let employees_link = {
                        id: employees_id,
                        label: ele2.name,
                        source: ["domain"],
                        searchType: "domain",
                        type: "name",
                    };
                    graphNodes.push(employees_link);
                    graphLinks.push({
                        from: employees_link.id,
                        to: id,
                        title: "Company",
                    });
                    if (ele2.location) {
                        let location_id = `company_domain_location__${ele2.location}__${ele2.id}`;
                        let location_link = {
                            id: location_id,
                            label: ele2.location,
                            source: ["domain"],
                            searchType: "domain",
                            type: "address",
                        };
                        graphNodes.push(location_link);
                        graphLinks.push({
                            from: location_link.id,
                            to: employees_id,
                            title: "Company",
                        });
                    }
                    if (ele2.current_title) {
                        let localId = `company_domain_designation__${ele2.current_title}__${ele2.id}`;
                        graphNodes.push({id: localId, label: ele2.current_title, source: ["domain"], searchType: "domain", type: "title"});
                        graphLinks.push({from: localId, to: employees_id, title: "Company"});
                    }
                });
            });
            return {nodes: graphNodes, links: graphLinks, dups: []};
        },

        makeDomainInformationGraph(data) {
            let nodes = data.data;
            let graphNodes = [];
            let graphLinks = [];
            nodes.forEach((ele) => {
                let id = `company_domain_companies__${ele.company.name}`;
                let company_link = {
                    id: id,
                    label: ele.company.domainData.domainName,
                    source: ["domain"],
                    searchType: "domain",
                    type: "domain",
                };
                graphNodes.push(company_link);
                graphLinks.push({
                    from: id,
                    to: this.currentQueryNodeId,
                    title: "Domain",
                });
                if (ele?.company?.domainData?.contactEmail) {
                    let companies_id = `company_domain_companies__${ele.company.name}__${ele.company.domainData.contactEmail}`;
                    let companies_link = {
                        id: companies_id,
                        label: ele.company.domainData.contactEmail,
                        source: ["domain"],
                        searchType: "email",
                        type: "email",
                    };
                    graphNodes.push(companies_link);
                    graphLinks.push({
                        from: companies_link.id,
                        to: id,
                        title: "Company",
                    });
                }
                if (ele?.company?.domainData?.registrarName) {
                    let registrarname = `company_domain_companies__${ele.company.name}__${ele.company.domainData.registrarName}`;
                    let regisrarname_link = {
                        id: registrarname,
                        label: ele.company.domainData.registrarName,
                        source: ["domain"],
                        searchType: "company",
                        type: "company",
                    };
                    graphNodes.push(regisrarname_link);
                    graphLinks.push({
                        from: registrarname,
                        to: id,
                        title: "Company",
                    });
                }
                if (ele?.company?.domainData?.administrativeContact?.organization) {
                    let registrarname = `company_domain_companies__${ele.company.name}__${ele.company.domainData.administrativeContact.organization}`;
                    let regisrarname_link = {
                        id: registrarname,
                        label: ele.company.domainData.administrativeContact.organization,
                        source: ["domain"],
                        searchType: "domain",
                        type: "company",
                    };
                    graphNodes.push(regisrarname_link);
                    graphLinks.push({
                        from: registrarname,
                        to: id,
                        title: "Company",
                    });
                }
                if (ele?.company?.domainData?.administrativeContact?.email) {
                    if (ele?.company?.domainData?.administrativeContact?.organization && ele.company?.domainData?.administrativeContact.email) {
                        let registrarname = `company_domain_companies__${ele.company.domainData.administrativeContact.organization}__${ele.company.domainData.administrativeContact.email}`;
                        let regisrarname_link = {
                            id: registrarname,
                            label: ele.company.domainData.administrativeContact.email,
                            source: ["domain"],
                            searchType: "domain",
                            type: "email",
                        };
                        graphNodes.push(regisrarname_link);
                        graphLinks.push({
                            from: registrarname,
                            to: id,
                            title: "Company",
                        });
                    } else {
                        let registrarname = `company_domain_companies__${ele.company.domainData.domainName}__${ele.company.domainData.administrativeContact.email}`;
                        let regisrarname_link = {
                            id: registrarname,
                            label: ele.company.domainData.administrativeContact.email,
                            source: ["domain"],
                            searchType: "domain",
                            type: "email",
                        };
                        graphNodes.push(regisrarname_link);
                        graphLinks.push({
                            from: registrarname,
                            to: id,
                            title: "Company",
                        });
                    }
                }
                if (ele?.company?.domainData?.administrativeContact?.name) {
                    let registrarname = `company_domain_companies__${ele.company.domainData.administrativeContact.organization}__${ele.company.domainData.administrativeContact.name}`;
                    let regisrarname_link = {
                        id: registrarname,
                        label: ele.company.domainData.administrativeContact.name,
                        source: ["domain"],
                        searchType: "domain",
                        type: "name",
                    };
                    graphNodes.push(regisrarname_link);
                    graphLinks.push({
                        from: registrarname,
                        to: id,
                        title: "Company",
                    });
                }
                if (ele?.company?.domainData?.administrativeContact?.telephone) {
                    let registrarname = `company_domain_companies__${ele.company.domainData.administrativeContact.organization}__${ele.company.domainData.administrativeContact.telephone}`;
                    let regisrarname_link = {
                        id: registrarname,
                        label: ele.company.domainData.administrativeContact.telephone,
                        source: ["domain"],
                        searchType: "domain",
                        type: "phone",
                    };
                    graphNodes.push(regisrarname_link);
                    graphLinks.push({
                        from: registrarname,
                        to: id,
                        title: "Company",
                    });
                }
                if (ele?.company?.domainData?.registrant?.organization) {
                    let registrarname = `company_domain_companies__${ele.company.domainData.domainName}__${ele.company.domainData.registrant.organization}`;
                    let regisrarname_link = {
                        id: registrarname,
                        label: ele.company.domainData.registrant.organization,
                        source: ["domain"],
                        searchType: "domain",
                        type: "company",
                    };
                    graphNodes.push(regisrarname_link);
                    graphLinks.push({
                        from: registrarname,
                        to: id,
                        title: "Company",
                    });
                }
                if (ele?.company?.domainData?.registrant?.name) {
                    let registrarname = `company_domain_companies__${ele.company.domainData.registrant.organization}__${ele.company.domainData.registrant.name}`;
                    let regisrarname_link = {
                        id: registrarname,
                        label: ele.company.domainData.registrant.name,
                        source: ["domain"],
                        searchType: "domain",
                        type: "name",
                    };
                    graphNodes.push(regisrarname_link);
                    graphLinks.push({
                        from: registrarname,
                        to: id,
                        title: "Company",
                    });
                }
                if (ele?.company?.domainData?.registrant?.email) {
                    let registrarname = `company_domain_companies__${ele.company.domainData.registrant.organization}__${ele.company.domainData.registrant.email}`;
                    let regisrarname_link = {
                        id: registrarname,
                        label: ele.company.domainData.registrant.email,
                        source: ["domain"],
                        searchType: "domain",
                        type: "email",
                    };
                    graphNodes.push(regisrarname_link);
                    graphLinks.push({
                        from: registrarname,
                        to: id,
                        title: "Company",
                    });
                }
                if (ele?.company?.domainData?.registrant?.telephone) {
                    let registrarname = `company_domain_companies__${ele.company.domainData.registrant.organization}__${ele.company.domainData.registrant.telephone}`;
                    let regisrarname_link = {
                        id: registrarname,
                        label: ele.company.domainData.registrant.telephone,
                        source: ["domain"],
                        searchType: "domain",
                        type: "phone",
                    };
                    graphNodes.push(regisrarname_link);
                    graphLinks.push({
                        from: registrarname,
                        to: id,
                        title: "Company",
                    });
                }
                if (ele?.company?.domainData?.technicalContact?.organization) {
                    let registrarname = `company_domain_companies__${ele.company.domainData.domainName}__${ele.company.domainData.technicalContact.organization}`;
                    let regisrarname_link = {
                        id: registrarname,
                        label: ele.company.domainData.technicalContact.organization,
                        source: ["domain"],
                        searchType: "domain",
                        type: "company",
                    };
                    graphNodes.push(regisrarname_link);
                    graphLinks.push({
                        from: registrarname,
                        to: id,
                        title: "Company",
                    });
                }
                if (ele?.company?.domainData?.technicalContact?.name) {
                    let registrarname = `company_domain_companies__${ele.company.domainData.technicalContact.organization}__${ele.company.domainData.technicalContact.name}`;
                    let regisrarname_link = {
                        id: registrarname,
                        label: ele.company.domainData.technicalContact.name,
                        source: ["domain"],
                        searchType: "domain",
                        type: "name",
                    };
                    graphNodes.push(regisrarname_link);
                    graphLinks.push({
                        from: registrarname,
                        to: id,
                        title: "Company",
                    });
                }
                if (ele?.company?.domainData?.technicalContact?.email) {
                    let registrarname = `company_domain_companies__${ele.company.domainData.technicalContact.organization}__${ele.company.domainData.technicalContact.email}`;
                    let regisrarname_link = {
                        id: registrarname,
                        label: ele.company.domainData.technicalContact.email,
                        source: ["domain"],
                        searchType: "domain",
                        type: "email",
                    };
                    graphNodes.push(regisrarname_link);
                    graphLinks.push({
                        from: registrarname,
                        to: id,
                        title: "Company",
                    });
                }
                if (ele?.company?.domainData?.technicalContact?.telephone) {
                    let registrarname = `company_domain_companies__${ele.company.domainData.technicalContact.organization}__${ele.company.domainData.technicalContact.telephone}`;
                    let regisrarname_link = {
                        id: registrarname,
                        label: ele.company.domainData.technicalContact.telephone,
                        source: ["domain"],
                        searchType: "domain",
                        type: "phone",
                    };
                    graphNodes.push(regisrarname_link);
                    graphLinks.push({
                        from: registrarname,
                        to: id,
                        title: "Company",
                    });
                }
            });

            return {nodes: graphNodes, links: graphLinks, dups: []};
        },

        makeDomainSearchGraph(data) {
            let nodes = data.data;
            let graphNodes = [];
            let graphLinks = [];
            nodes.forEach((ele) => {
                let id = `company_domain_companies__${ele.company.name}`;
                let company_link = {
                    id: id,
                    label: ele.company.domainData.domainName,
                    source: ["domain-search"],
                    searchType: "domain-search",
                    type: "domain",
                };
                graphNodes.push(company_link);
                graphLinks.push({
                    from: id,
                    to: this.currentQueryNodeId,
                    title: "Domain",
                });
                if (ele?.company?.domainData?.contactEmail) {
                    let companies_id = `company_domain_companies__${ele.company.name}__${ele.company.domainData.contactEmail}`;
                    let companies_link = {
                        id: companies_id,
                        label: ele.company.domainData.contactEmail,
                        source: ["domain-search"],
                        searchType: "domain-search",
                        type: "email",
                    };
                    graphNodes.push(companies_link);
                    graphLinks.push({
                        from: companies_link.id,
                        to: id,
                        title: "Company",
                    });
                }
                if (ele?.company?.domainData?.registrarName) {
                    let registrarname = `company_domain_companies__${ele.company.name}__${ele.company.domainData.registrarName}`;
                    let regisrarname_link = {
                        id: registrarname,
                        label: ele.company.domainData.registrarName,
                        source: ["domain-search"],
                        searchType: "domain-search",
                        type: "company",
                    };
                    graphNodes.push(regisrarname_link);
                    graphLinks.push({
                        from: registrarname,
                        to: id,
                        title: "Company",
                    });
                }
                if (ele?.company?.domainData?.administrativeContact?.organization) {
                    let registrarname = `company_domain_companies__${ele.company.name}__${ele.company.domainData.administrativeContact.organization}`;
                    let regisrarname_link = {
                        id: registrarname,
                        label: ele.company.domainData.administrativeContact.organization,
                        source: ["domain-search"],
                        searchType: "domain-search",
                        type: "company",
                    };
                    graphNodes.push(regisrarname_link);
                    graphLinks.push({
                        from: registrarname,
                        to: id,
                        title: "Company",
                    });
                }
                if (ele?.company?.domainData?.administrativeContact?.email) {
                    if (ele?.company?.domainData?.administrativeContact?.organization && ele.company?.domainData?.administrativeContact.email) {
                        let registrarname = `company_domain_companies__${ele.company.domainData.administrativeContact.organization}__${ele.company.domainData.administrativeContact.email}`;
                        let regisrarname_link = {
                            id: registrarname,
                            label: ele.company.domainData.administrativeContact.email,
                            source: ["domain-search"],
                            searchType: "domain-search",
                            type: "email",
                        };
                        graphNodes.push(regisrarname_link);
                        graphLinks.push({
                            from: registrarname,
                            to: id,
                            title: "Company",
                        });
                    } else {
                        let registrarname = `company_domain_companies__${ele.company.domainData.domainName}__${ele.company.domainData.administrativeContact.email}`;
                        let regisrarname_link = {
                            id: registrarname,
                            label: ele.company.domainData.administrativeContact.email,
                            source: ["domain-search"],
                            searchType: "domain-search",
                            type: "email",
                        };
                        graphNodes.push(regisrarname_link);
                        graphLinks.push({
                            from: registrarname,
                            to: id,
                            title: "Company",
                        });
                    }
                }
                if (ele?.company?.domainData?.administrativeContact?.name) {
                    let registrarname = `company_domain_companies__${ele.company.domainData.administrativeContact.organization}__${ele.company.domainData.administrativeContact.name}`;
                    let regisrarname_link = {
                        id: registrarname,
                        label: ele.company.domainData.administrativeContact.name,
                        source: ["domain-search"],
                        searchType: "domain-search",
                        type: "name",
                    };
                    graphNodes.push(regisrarname_link);
                    graphLinks.push({
                        from: registrarname,
                        to: id,
                        title: "Company",
                    });
                }
                if (ele?.company?.domainData?.administrativeContact?.telephone) {
                    let registrarname = `company_domain_companies__${ele.company.domainData.administrativeContact.organization}__${ele.company.domainData.administrativeContact.telephone}`;
                    let regisrarname_link = {
                        id: registrarname,
                        label: ele.company.domainData.administrativeContact.telephone,
                        source: ["domain-search"],
                        searchType: "domain-search",
                        type: "phone",
                    };
                    graphNodes.push(regisrarname_link);
                    graphLinks.push({
                        from: registrarname,
                        to: id,
                        title: "Company",
                    });
                }
                if (ele?.company?.domainData?.registrant?.organization) {
                    let registrarname = `company_domain_companies__${ele.company.domainData.domainName}__${ele.company.domainData.registrant.organization}`;
                    let regisrarname_link = {
                        id: registrarname,
                        label: ele.company.domainData.registrant.organization,
                        source: ["domain-search"],
                        searchType: "domain-search",
                        type: "company",
                    };
                    graphNodes.push(regisrarname_link);
                    graphLinks.push({
                        from: registrarname,
                        to: id,
                        title: "Company",
                    });
                }
                if (ele?.company?.domainData?.registrant?.name) {
                    let registrarname = `company_domain_companies__${ele.company.domainData.registrant.organization}__${ele.company.domainData.registrant.name}`;
                    let regisrarname_link = {
                        id: registrarname,
                        label: ele.company.domainData.registrant.name,
                        source: ["domain-search"],
                        searchType: "domain-search",
                        type: "name",
                    };
                    graphNodes.push(regisrarname_link);
                    graphLinks.push({
                        from: registrarname,
                        to: id,
                        title: "Company",
                    });
                }
                if (ele?.company?.domainData?.registrant?.email) {
                    let registrarname = `company_domain_companies__${ele.company.domainData.registrant.organization}__${ele.company.domainData.registrant.email}`;
                    let regisrarname_link = {
                        id: registrarname,
                        label: ele.company.domainData.registrant.email,
                        source: ["domain-search"],
                        searchType: "domain-search",
                        type: "email",
                    };
                    graphNodes.push(regisrarname_link);
                    graphLinks.push({
                        from: registrarname,
                        to: id,
                        title: "Company",
                    });
                }
                if (ele?.company?.domainData?.registrant?.telephone) {
                    let registrarname = `company_domain_companies__${ele.company.domainData.registrant.organization}__${ele.company.domainData.registrant.telephone}`;
                    let regisrarname_link = {
                        id: registrarname,
                        label: ele.company.domainData.registrant.telephone,
                        source: ["domain-search"],
                        searchType: "domain-search",
                        type: "phone",
                    };
                    graphNodes.push(regisrarname_link);
                    graphLinks.push({
                        from: registrarname,
                        to: id,
                        title: "Company",
                    });
                }
                if (ele?.company?.domainData?.technicalContact?.organization) {
                    let registrarname = `company_domain_companies__${ele.company.domainData.domainName}__${ele.company.domainData.technicalContact.organization}`;
                    let regisrarname_link = {
                        id: registrarname,
                        label: ele.company.domainData.technicalContact.organization,
                        source: ["domain-search"],
                        searchType: "domain-search",
                        type: "company",
                    };
                    graphNodes.push(regisrarname_link);
                    graphLinks.push({
                        from: registrarname,
                        to: id,
                        title: "Company",
                    });
                }
                if (ele?.company?.domainData?.technicalContact?.name) {
                    let registrarname = `company_domain_companies__${ele.company.domainData.technicalContact.organization}__${ele.company.domainData.technicalContact.name}`;
                    let regisrarname_link = {
                        id: registrarname,
                        label: ele.company.domainData.technicalContact.name,
                        source: ["domain-search"],
                        searchType: "name",
                        type: "name",
                    };
                    graphNodes.push(regisrarname_link);
                    graphLinks.push({
                        from: registrarname,
                        to: id,
                        title: "Company",
                    });
                }
                if (ele?.company?.domainData?.technicalContact?.email) {
                    let registrarname = `company_domain_companies__${ele.company.domainData.technicalContact.organization}__${ele.company.domainData.technicalContact.email}`;
                    let regisrarname_link = {
                        id: registrarname,
                        label: ele.company.domainData.technicalContact.email,
                        source: ["domain-search"],
                        searchType: "domain-search",
                        type: "email",
                    };
                    graphNodes.push(regisrarname_link);
                    graphLinks.push({
                        from: registrarname,
                        to: id,
                        title: "Company",
                    });
                }
                if (ele?.company?.domainData?.technicalContact?.telephone) {
                    let registrarname = `company_domain_companies__${ele.company.domainData.technicalContact.organization}__${ele.company.domainData.technicalContact.telephone}`;
                    let regisrarname_link = {
                        id: registrarname,
                        label: ele.company.domainData.technicalContact.telephone,
                        source: ["domain-search"],
                        searchType: "domain-search",
                        type: "phone",
                    };
                    graphNodes.push(regisrarname_link);
                    graphLinks.push({
                        from: registrarname,
                        to: id,
                        title: "Company",
                    });
                }
            });

            return {nodes: graphNodes, links: graphLinks, dups: []};
        },

        makeLinkedinDiscoverGraph(data) {
            let nodes = data.data;
            let graphNodes = [];
            let graphLinks = [];

            if (!Array.isArray(nodes)) {
                if (nodes.profile) {
                    let name_id = `linkedindiscover__query_name__${nodes.profile}`;
                    let name_search_node = {
                        id: name_id,
                        source: ["linkedin"],
                        label: nodes.profile,
                        searchType: "email",
                        type: "url",
                    };
                    graphNodes.push(name_search_node);
                    graphLinks.push({from: name_id, to: this.currentQueryNodeId, title: "URL"});
                }
            }
            return {nodes: graphNodes, links: graphLinks, dups: []};
        },

        makeLinkedinGraph(data) {
            let nodes = data.data;
            let graphNodes = [];
            let graphLinks = [];

            if (!Array.isArray(nodes)) {
                const id = `linkedin_name__${nodes.name}__`;
                let person = {
                    id,
                    label: nodes.name,
                    source: ["linkedin"],
                    searchType: "email",
                    type: "person",
                };
                graphNodes.push(person);
                graphLinks.push({
                    from: id,
                    to: this.currentQueryNodeId,
                    title: "Person",
                });

                if (nodes.current_employer) {
                    let localId = `linkedin_name__${nodes.name}__${nodes.current_employer}`;
                    graphNodes.push({id: localId, label: nodes.current_employer, source: ["linkedin"], searchType: "email", type: "Current Employer"});
                    graphLinks.push({from: localId, to: id, title: "Employer"});
                }

                if (nodes.current_personal_email) {
                    let localId = `linkedin_name__${nodes.name}__${nodes.current_personal_email}`;
                    graphNodes.push({id: localId, label: nodes.current_personal_email, source: ["linkedin"], searchType: "email", type: "email"});
                    graphLinks.push({from: localId, to: id, title: "Personal Email"});
                }

                if (nodes.current_work_email) {
                    let localId = `linkedin_name__${nodes.name}__${nodes.current_work_email}`;
                    graphNodes.push({id: localId, label: nodes.current_work_email, source: ["linkedin"], searchType: "email", type: "email"});
                    graphLinks.push({from: localId, to: id, title: "Work Email"});
                }

                if (nodes.current_title) {
                    let localId = `linkedin_name__${nodes.name}__${nodes.current_title}`;
                    graphNodes.push({id: localId, label: nodes.current_title, source: ["linkedin"], searchType: "email", type: "title"});
                    graphLinks.push({from: localId, to: id, title: "Title"});
                }

                if (nodes.location) {
                    let localId = `linkedin_name__${nodes.name}__${nodes.location}`;
                    graphNodes.push({id: localId, label: nodes.location, source: ["linkedin"], searchType: "email", type: "location"});
                    graphLinks.push({from: localId, to: id, title: "Location"});
                }

                if (nodes.location) {
                    let localId = `linkedin_name__${nodes.name}__${nodes.location}`;
                    graphNodes.push({id: localId, label: nodes.location, source: ["linkedin"], searchType: "email", type: "location"});
                    graphLinks.push({from: localId, to: id, title: "Location"});
                }

                nodes.emails.forEach((e) => {
                    let localId = `linkedin_name__${nodes.name}__${e.email}`;
                    graphNodes.push({id: localId, label: e.email, source: ["linkedin"], searchType: "email", type: "email"});
                    graphLinks.push({from: localId, to: id, title: "Email"});
                });

                nodes.phones.forEach((e) => {
                    let localId = `linkedin_name__${nodes.name}__${e.number}`;
                    graphNodes.push({id: localId, label: e.number, source: ["linkedin"], searchType: "email", type: "phone"});
                    graphLinks.push({from: localId, to: id, title: "Phone"});
                });

                Object.entries(nodes.links).forEach((e) => {
                    let localId = `linkedin_name__${nodes.name}__${e[1]}`;
                    graphNodes.push({id: localId, label: e[1], source: ["linkedin"], searchType: "email", type: "url"});
                    graphLinks.push({from: localId, to: id, title: "URL"});
                });
            } else {
                nodes.forEach((node) => {
                    let employees_id = `linkedIn_employees__${node.name}__${node.id}`;
                    let employees_link = {
                        id: employees_id,
                        label: node.name,
                        source: ["linkedin"],
                        searchType: "domain",
                        type: "name",
                    };
                    graphNodes.push(employees_link);
                    graphLinks.push({
                        from: employees_link.id,
                        to: this.currentQueryNodeId,
                        title: "Company",
                    });
                    if (node.location) {
                        let location_id = `linkedIn_location__${node.location}__${node.id}`;
                        let location_link = {
                            id: location_id,
                            label: node.location,
                            source: ["linkedin"],
                            searchType: "domain",
                            type: "address",
                        };
                        graphNodes.push(location_link);
                        graphLinks.push({
                            from: location_link.id,
                            to: employees_id,
                            title: "Company",
                        });
                    }
                    if (node.current_title) {
                        let localId = `linkedIn_designation__${node.current_title}__${node.id}`;
                        graphNodes.push({id: localId, label: node.current_title, source: ["linkedin"], searchType: "domain", type: "title"});
                        graphLinks.push({from: localId, to: employees_id, title: "linkedin"});
                    }
                });
            }

            return {nodes: graphNodes, links: graphLinks, dups: []};
        },

        makeFacebookGraph(data) {
            let nodes = data.data;
            let graphNodes = [];
            let graphLinks = [];
            let dups = [];

            nodes.forEach((n) => {
                let main_node_id = n.lname ? `facebook__fname__${n.fname}__${n.lname}` : `facebook__fname__${n.fname}`;

                let facebookids_node = {
                    id: main_node_id,
                    source: ["facebook"],
                    label: n.lname ? n.fname + " " + n.lname : n.fname,
                    searchType: "username",
                    type: "username",
                    // color: "red",
                    // group: "source",
                };

                let connected_nodes = [];

                connected_nodes = [
                    {node: n.email, name: "email"},
                    {node: n.birthday, name: "birthday"},
                    {node: n.company, name: "company"},
                    {node: n.phone, name: "phone"},
                    {node: n.fb_url, name: "url"},
                    {node: n.city, name: "city"},
                    {node: n.country, name: "country"},
                ];

                graphNodes.push(facebookids_node);
                graphLinks.push({from: main_node_id, to: this.currentQueryNodeId, title: "Facebook"});

                connected_nodes.forEach((cn) => {
                    if (cn.node) {
                        let id = `facebook__username__${n.fname}__${cn.node}`;
                        let _cn = {
                            id: id,
                            label: cn.node,
                            source: ["facebook"],
                            searchType: cn.name,
                            type: cn.name,
                        };

                        graphNodes.push(_cn);
                        graphLinks.push({
                            from: id,
                            to: main_node_id,
                            title: "Facebook",
                        });
                    }
                });
            });

            return {nodes: graphNodes, links: graphLinks, dups: []};
        },

        async onSearchResult(data) {
            let dataClone = cloneDeep(data);
            data = dataClone;
            let searchResult = data.data;
            let searchSource = data.source;
            let searchType = data.searchType;
            if (searchResult?.emails && (searchSource == "linkedin" || searchSource == "linkedin-discover") && searchType != "domain") {
                searchResult.emails = searchResult.emails.filter((ele) => ele.smtp_valid !== "invalid");
            }

            //To remove person identifier images link from the graph
            if (searchSource == "personIdentifier" && searchType == "personIdentifier") {
                searchResult = searchResult.map((ele) => {
                    let json = {...ele};
                    if (json.images) {
                        delete json.images;
                    }
                    return json;
                });
            }

            let nodeToLink = this.nodeToLink;

            let nodesFound = [];
            let linksFound = [];
            let graphData = {dups: []};

            if (searchType === "personIdentifier") {
                graphData = this.makePersonIndentifierGraph(data);
                nodesFound = graphData.nodes;
                linksFound = graphData.links;
            } else if ((searchType === "email" || searchType === "domain") && searchSource === "linkedin") {
                graphData = this.makeLinkedinGraph(data);
                nodesFound = graphData.nodes;
                linksFound = graphData.links;
            } else if (searchType === "email" && searchSource === "linkedin-discover") {
                graphData = this.makeLinkedinDiscoverGraph(data);
                nodesFound = graphData.nodes;
                linksFound = graphData.links;
            } else if (searchType === "company" && searchSource === "domain") {
                graphData = this.makeCompanyDomainCompaniesGraph(data);
                nodesFound = graphData.nodes;
                linksFound = graphData.links;
            } else if (searchType === "domain" && searchSource === "domain") {
                graphData = this.makeDomainInformationGraph(data);
                nodesFound = graphData.nodes;
                linksFound = graphData.links;
            } else if (searchType === "domain-search" && searchSource === "domain-search") {
                graphData = this.makeDomainSearchGraph(data);
                nodesFound = graphData.nodes;
                linksFound = graphData.links;
            } else if (searchType === "skype_query" && searchSource === "skype") {
                graphData = this.makeSkypeGraph(data);
                nodesFound = graphData.nodes;
                linksFound = graphData.links;
            } else if (searchType === "name" && searchSource === "name_search") {
                let graphData = this.makeNameSearch(data);
                nodesFound = graphData.nodes;
                linksFound = graphData.links;
            } else if (searchSource === "company") {
                let graphData = this.makeCompanyGraph(data);
                nodesFound = graphData.nodes;
                linksFound = graphData.links;
            } else if (searchSource === "adverse-media") {
                let graphData = this.makeAdverseMediaGraph(data);
                nodesFound = graphData.nodes;
                linksFound = graphData.links;
            } else if (searchSource === "offshoreleaks") {
                let graphData = this.makeOffshoreLeaksGraph(data);
                nodesFound = graphData.nodes;
                linksFound = graphData.links;
            } else if (searchSource === "lexis-bridger") {
                let graphData = this.makeLexisBridgerGraph(data);
                nodesFound = graphData.nodes;
                linksFound = graphData.links;
            } else if (searchSource === "twitter" && searchType === "query") {
                let graphData = this.makeTwitterQueryGraph(data);
                nodesFound = graphData.nodes;
                linksFound = graphData.links;
            } else if (searchSource === "facebook") {
                let graphData = this.makeFacebookGraph(data);
                nodesFound = graphData.nodes;
                linksFound = graphData.links;
            } else {
                var keysToExclude = this.filterValueApiMap[searchType].filter((e) => e.source === searchSource)[0].excludeKeys || [];
                data = this.flattenDict(searchResult, [], keysToExclude);
                let extractedData = this.extractData(data, searchSource, searchType);

                /* Create connections between the nodes that we get ,
                 * after cleaning the search resultss & the search Query node.
                 */
                extractedData.forEach((d) => {
                    let nodeId = `${searchSource}__${d[1]}`;

                    let node = {
                        id: nodeId,
                        label: d[1], //+ " -- " + d[0],
                        type: d[0],
                        source: [searchSource],
                        searchType: searchType,
                    };
                    if (d[1]) nodesFound.push(node);

                    linksFound.push({
                        to: nodeToLink,
                        from: nodeId /*label:d[0]*/,
                        title: searchSource,
                    });
                });
            }

            // Process  data here for changes to data nodes.
            // ex. mapping different node types to one type,
            // ex. map address, city, country to location type.

            [...nodesFound, ...this.queryData.nodes, ...graphData.dups].forEach((n) => {
                if (this.nodeKeyTypeMap[n.type] !== undefined) {
                    n.type = this.nodeKeyTypeMap[n.type];
                    n.searchType = this.nodeKeyTypeMap[n.type];
                }
            });
            this.pushToQueue({
                nodes: [...nodesFound, ...this.queryData.nodes, ...graphData.dups],
                edges: [...linksFound, ...this.queryData.edges],
            });
        },

        pushToQueue(obj) {
            this.graphDataQueue.push(obj);
        },

        async GraphDataConsumer() {
            if (this.graphDataQueue.length > 0) {
                const data = this.graphDataQueue.shift();
                const uniqueEdges = await this.saveGraphData(data.nodes, data.edges);
                let nodesToUpdate = [];
                uniqueEdges.data.nodes.forEach((el) => {
                    let existingNode = this.graphNodes.find((n) => n.id === el.id);
                    if (existingNode) {
                        Object.assign(existingNode, el);
                    } else {
                        this.graphNodes.push(el);
                    }
                });
                this.graphLinks.push(...uniqueEdges.data.edges);

                if (data.nodes.length > 0 && data.nodes[0].source.includes("caseData")) {
                    this.isCaseDataFiltered = true;
                }
                setTimeout(this.GraphDataConsumer, 100);
            } else {
                setTimeout(this.GraphDataConsumer, 1000);
            }
        },

        getAnalyserSource(key, matchKeyword, matchSource) {
            if (key && typeof key === "string" && key?.toLowerCase() === matchKeyword) return matchSource || "name";
            else return matchSource ? "name" : "company_name";
        },

        /* A node which depicts tools query. */
        createCenterNode(searchInput, type) {
            // if (Object.values(searchInput).length === 1) {
            //     var config = {
            //         shape: "box",
            //         label: Object.values(searchInput)[0],
            //     };

            //     return config;
            // }

            let isMultiLineCenterNode = false;
            var count = 0;
            var emailIndex = -1;
            var nameIndex = -1;
            var priorityInputNode = null;

            const inputs = Object.entries(searchInput);
            let inputType = null;
            for (let i = 0; i < inputs.length; i++) {
                const [k, v] = inputs[i];
                if (k === "email") emailIndex = i;
                if (k === "name" || k === "username") nameIndex = true;
                if (v !== "") {
                    inputType = k;
                    count++;
                }
            }

            if (count >= 2) {
                isMultiLineCenterNode = true;
                // If we have multiple inputs & email is present maek it as priority
                if (emailIndex >= 0) priorityInputNode = inputs[emailIndex][1];
                // else if we have name as input make it as priority
                else if (nameIndex >= 0) priorityInputNode = inputs[nameIndex][1];
                //else pick the first input as priority node.
                else priorityInputNode = inputs[0][1];
            }
            let name = "";
            try {
                let checkName = searchInput["first_name"].length > 0 || searchInput["last_name"].length > 0 || searchInput["middle_name"];
                if (this.activeProcess === "person-identifier-tool" && !type && type !== "case-data" && checkName) {
                    //Fix this(NOTE)
                    // name = `${searchInput["first_name"]} ${searchInput["middle_name"]} ${searchInput["last_name"]}`;
                    name = [searchInput["first_name"], searchInput["middle_name"], searchInput["last_name"]].filter((e) => e.trim() !== "").join(" ");
                    delete searchInput["first_name"];
                    delete searchInput["last_name"];
                    delete searchInput["middle_name"];
                }
            } catch (error) {
                if (searchInput.name) {
                    name = searchInput.name;
                }
            }
            var config = {
                shape: "box",
                label: uniq([name, ...Object.values(searchInput)])
                    .filter((o) => o !== "" && o !== null && o !== "undefined" && o !== " ")
                    .join(",\n"),
            };

            if (count === 1) {
                config["type"] = inputType;
            } else {
                config["type"] = "multi_query_center_node";
            }

            searchInput["name"] = `${name}`;

            return config;
        },
        async onSearch(data) {
            let input = data.data;
            let searchData = {...input};
            let source = data.source;
            let searchType = data.searchType;
            let type = data.type;

            let searchedNodes = [];
            let searchedLinks = [];
            let centerNodeConfig = this.createCenterNode(searchData);
            centerNodeConfig.label = `${centerNodeConfig.label}`;
            let uniqueKey = `${this.activeProcess}__${Object.values(searchData).join("__")}`;
            let centerNode = {
                borderWidth: 2,
                label: centerNodeConfig.label,
                type: centerNodeConfig.type,
                id: uniqueKey,
                source: source == "linkedin-discover" ? ["linkedin"] : [source],
                isQueryNode: true,
            };

            this.currentQueryNodeId = uniqueKey;

            this.currentQueryNodeId = uniqueKey;
            let inputLength = Object.values(searchData).filter((el) => el !== " " && el !== "" && el !== undefined && el !== null);
            if (inputLength > 1) {
                for (const [k, v] of Object.entries(searchData)) {
                    if (v === "") continue;
                    this.searchedKeywords = [];
                    this.searchedKeywords.push(v);
                    const searchId = `${source}__${uniqueKey}__${v}`;
                    searchedNodes.push({
                        label: `${v}`,
                        id: searchId,
                        color: {border: "gray", font: "black", background: "#fff"},
                        borderWidth: 2,
                        searchType: searchType,
                        type: k,
                        source: source == "linkedin-discover" ? ["linkedin"] : [source],
                        isSubQueryNode: true,
                    });
                    searchedLinks.push({to: centerNode.id, from: searchId, title: source});
                }
            }
            this.nodeToLink = centerNode.id;
            searchedNodes.push(centerNode);
            this.queryData.nodes = searchedNodes;
            this.queryData.edges = searchedLinks;
        },

        async clickEvent(tree) {
            if (tree.leaf) {
                this.treeData = tree;
                this.isTool = tree.isTool;
                this.activeProcess = tree.activeProcess;
                if (tree.activeProcess !== this.$route.params.toolName) {
                    this.$router.push({name: this.$route.name, params: {toolName: tree.activeProcess}}).catch((err) => {
                        console.log(err);
                    });
                    // const inOutFilters = ["adverse-media-tool", "news-tool"].includes(tree.activeProcess);
                    const {monitoring, saved} = this.getToolsFilterData;
                    if (monitoring !== "all" || saved !== "all") {
                        const filters = {
                            monitoring: "all",
                            saved: "all",
                        };
                        this.setToolsFilterData(filters);
                        await this.getToolsStatus();
                    }
                    this.$refs?.search_queries?.selectAllQueries(false, false);
                    await this.getQueriesMonitoring();
                    await this.getQueriesResultsCount();
                    if (this.$route.name === "monitoring") {
                        await this.getQueryMonitoringEvents(this.currentToolSelected); // Function to get new monitoring events on query
                    }
                }
            }
            this.autoSearch();
        },

        handleChecked(allChecked) {
            if ((allChecked || allChecked === false) && this.activeProcess) {
                let index = this.toolOptions?.children.findIndex((tool) => tool.activeProcess === this.activeProcess);
                this.toolOptions.children[index].allChecked = allChecked;
            } else {
                this.toolOptions?.children?.forEach((tool) => {
                    let allChecked = false;
                    let data = [];
                    let newData = [];
                    // tool.fields?.forEach((type) => {
                    //     if (!(["person-identifier-tool", "search-engine-tool", "news-tool", "domain-search-tool", "company-name"].includes(tool.activeProcess) && type === "nameList")) data = data.concat(this[type]);
                    // });
                    tool.fields?.forEach((type) => {
                        if (!(["person-identifier-tool", "search-engine-tool", "news-tool", "domain-search-tool", "gdc-tool", "company-name"].includes(tool.activeProcess) && type === "nameList") && !(["news-tool"].includes(tool.activeProcess) && type === "companyUsers")) data = data.concat(this[type]);
                    });
                    data = filterDropdownData({
                        platform: tool.platform,
                        values: JSON.parse(JSON.stringify(data)),
                    });
                    newData = JSON.parse(JSON.stringify(data));
                    if (this.input_value?.length) {
                        newData = newData.filter((entry) => {
                            let flag = false;
                            this.input_value?.forEach((ip_value) => {
                                if (!flag) {
                                    flag = ip_value.value === entry.value && ip_value.input_type === entry.val_type ? true : false;
                                }
                            });
                            return flag;
                        });
                    }
                    tool.data = data;
                    if (newData?.length) allChecked = newData.findIndex((n) => !n.visited) > -1 ? false : true;
                    tool.allChecked = allChecked;
                });
            }
        },

        async loadOptions() {
            const {data: toolsData} = await axios.get("/analysis-tools/development");
            const tools = {
                children: toolsData
                    .map((n) => ({
                        text: n.text,
                        activeProcess: n.tool_name,
                        monitoring: n.monitoring,
                        isTool: true,
                        active: n.active,
                        errorMessage: n.errorrMessage,
                        fields: this.toolFieldsMapping[n.tool_name],
                        platform: this.toolPlatforms[n.tool_name],
                        allChecked: false,
                        order: n.order,
                        heading: n?.heading ?? null,
                        type: n?.type ?? null,
                        manualMonitoring: n?.manualMonitoring ?? null,
                        sources: n?.sources ?? [],
                    }))
                    .sort(function(a, b) {
                        if (a.order > b.order) return 1;
                        else return -1;
                    }),
                // .sort((a, b) => a.text.localeCompare(b.text)),
            };
            // let companychecker = {
            //     text: "Company Checker",
            //     activeProcess: "company-checker",
            //     monitoring: true,
            //     isTool: true,
            //     fields: ["companyUsers", "locationList"],
            //     platform: "company-checker",
            //     allChecked: false,
            //     order: 170,
            //     heading: "Corporate Information",
            //     analyseSelected: false,
            //     type: null,
            //     manualMonitoring: null,
            //     sources: ["company_name", "location"],
            // };
            // tools["children"].push(companychecker);
            this.SET_ALL_TOOLS_NAMES(tools);
            this.toolOptions = tools;
        },

        initGraph(data) {
            try {
                this.graphNodes.push(...data.nodes); //.map(n => { return { ...n } });
                this.graphLinks.push(...data.edges);
            } catch (err) {}
        },
        async fetchGraphData() {
            const case_id = this.$store.getters.getCaseId;
            const url = `/analysis-tools/${case_id}/graph`;
            try {
                const result = await axios.get(url);
                return result.data.data;
            } catch (error) {}
        },
        async saveGraphData(nodes, edges) {
            const case_id = this.$store.getters.getCaseId;
            const url = "/analysis-tools/graph";
            const data = {
                case_id: case_id,
                nodes: nodes,
                edges: edges,
            };
            try {
                const result = await axios.post(url, data);
                return result;
            } catch (error) {
                // this.$toast.error("Graph saving failed");
            }
        },
        addFields(fieldData, child = false) {
            let field = JSON.parse(JSON.stringify(fieldData));
            field.count = field.addedFields.length + 1;
            fieldData.addedFields.push({...field, name: field.name.replace(/\d+/g, "") + `${field.addedFields.length + 1}`});

            let count = child ? 0 : 1;
            fieldData.addedFields.forEach((res) => {
                count += 1;
                res.name = res.name.replace(/\d+/g, "");
                res.name = res.name + ` ${count}`;
                res.count = count;
            });
        },
        redirectTool() {
            //Redirect to same tool as before reload....
            const toolName = this.currentToolSelected;
            if (toolName) {
                const toolData = this.toolOptions?.children?.find((n) => n.activeProcess === toolName);
                if (toolData) {
                    this.treeData = {
                        text: toolData.text,
                        activeProcess: toolData.activeProcess,
                        monitoring: toolData.monitoring,
                        data: toolData.data,
                        isTool: true,
                        leaf: true,
                        type: toolData?.type ?? null,
                        manualMonitoring: toolData?.manualMonitoring ?? null,
                    };
                    // this.clickEvent(this.treeData);
                    this.isTool = true;
                    this.activeProcess = toolData.activeProcess;
                }
            } else {
                this.treeData = null;
                this.isTool = false;
                this.activeProcess = "";
                this.$refs?.filters?.$children.forEach((ele) => {
                    if (ele?.selectedRow) {
                        ele.selectedRow = "";
                    }
                });
                this.$router.push({name: this.$route.name}).catch(() => {});
            }
            Object.assign(this.dateFilters, {
                data: {
                    timeline: [],
                    meta_data: null,
                },
            });
        },
        async handleSearchQueryClicked(query, changeRange = false) {
            this.selectedToolQuery = query;
            this.isSearchQueryClicked = true;
            if (this.currentToolSelected !== "search-engine-tool") {
                await axios.put(`/tools-status/${query?._id}`);
            }
            const {tools} = this.$refs;
            let query_id = "";
            let sel_query = null;
            let selected_query = null;
            if (query._id) {
                query_id = query._id;
                selected_query = this.fetchSearchedQueries?.find((el) => el._id === query_id);
                sel_query = this.fetchSearchedQueries?.find((el) => el._id === query_id)?.query;
            }
            tools.request_id = query_id;
            const {search_queries} = this.$refs;
            search_queries.handleClick(selected_query, true);
            switch (this.currentToolSelected) {
                case "whatsapp-tool":
                    tools.phoneNumber.number = {value: sel_query.number};
                    tools.phoneNumber.countryCode = {
                        res: {
                            _id: sel_query.id,
                        },
                        value: sel_query.country_with_code,
                    };
                    tools.request_id = sel_query.query_id;
                    tools.searchNumber();
                case "gitlab-tool":
                    tools.searchKeyword = Object.values(sel_query)[0];
                    tools.selectedItem = {
                        value: Object.values(sel_query)[0],
                    };
                    tools.selectVal = Object.values(sel_query)[0];
                    tools.search();
                    break;

                case "email-reputation-tool":
                    tools.email = Object.values(sel_query)[0];
                    tools.selectedItem = {
                        value: Object.values(sel_query)[0],
                    };
                    tools.selectVal = Object.values(sel_query)[0];
                    tools.search();
                    break;

                case "court-records-tool":
                    // Don't search when we have query related with old(tools) or global inputs;
                    if (sel_query?.search_query) {
                        return;
                    }
                    const isSearchWithSearchQuery = true;

                    tools.allSources["uniCourt"].isChecked = false;
                    tools.allSources["judyRecords"].isChecked = false;
                    tools.sources = [];
                    // reset the page number
                    tools.pagination = {
                        current_page: 1,
                        judy_records_total_pages: 1,
                        uni_court_records_total_pages: 1,
                        total_records: 1,
                        total_max_pages: 1,
                    };

                    // clear all the initial input data
                    for (const key in tools.tagList) {
                        tools.tagList[key] = [];
                    }

                    // clear all the additional input data
                    if (tools.newTagList) {
                        tools.newTagList.forEach((item) => {
                            for (const key in item) {
                                item[key] = [];
                            }
                        });
                    }
                    // Fill the data in inputs fields
                    if (sel_query?.case_search_list.length > 0) {
                        const initialInputsTags = sel_query?.case_search_list[0];
                        const dropDownItems = ["condition_operator", "party_types", "proximity", "representation", "scope", "search_type"];
                        // for initial inputs
                        for (const input in initialInputsTags) {
                            if (!dropDownItems.includes(input)) {
                                let str = "";
                                let value = initialInputsTags[input];
                                if (typeof value === "string") {
                                    str = value
                                        .replaceAll(" AND ", "->")
                                        .replaceAll(" OR ", "->")
                                        .replaceAll(" NOT ", "->");
                                }
                                if (Array.isArray(value)) {
                                    str = value[0];
                                }
                                tools.tagList[input] = str.split("->");
                                if (input === "attorney" || input === "judge" || input === "case_name" || input === "case_number") {
                                    // get child component
                                    const {courtFormRef} = tools.$refs;
                                    if (courtFormRef) {
                                        courtFormRef.toggle.moreSearchOptions = true;
                                    }
                                }
                            } else if (input !== "condition_operator" && sel_query?.sources.includes("unicourt")) {
                                switch (input) {
                                    case "search_type": {
                                        let displayName = "";
                                        if (initialInputsTags[input] === "case_number") {
                                            displayName = "Case number";
                                        } else if (initialInputsTags[input] === "case_name") {
                                            displayName = "Case name";
                                        } else if (initialInputsTags[input] === "all") {
                                            displayName = "Everything";
                                        } else {
                                            displayName = this.getDisplayDropDownName(initialInputsTags[input]);
                                        }
                                        tools.selectedDropDownItem[input] = {name: displayName, value: initialInputsTags[input]};
                                        break;
                                    }
                                    case "scope": {
                                        let displayName = this.getDisplayDropDownName(initialInputsTags[input]);
                                        tools.selectedDropDownItem[input] = {name: displayName, value: initialInputsTags[input]};
                                        break;
                                    }
                                    case "proximity": {
                                        let displayName = this.getDisplayDropDownName(initialInputsTags[input]);
                                        tools.selectedDropDownItem[input] = {name: displayName, value: initialInputsTags[input]};
                                        break;
                                    }
                                    case "party_types": {
                                        let displayName = this.getDisplayDropDownName(initialInputsTags[input]);
                                        tools.selectedDropDownItem[input] = {name: displayName, value: initialInputsTags[input]};
                                        break;
                                    }
                                    case "representation": {
                                        let displayName = this.getDisplayDropDownName(initialInputsTags[input]);
                                        tools.selectedDropDownItem[input] = {name: displayName, value: initialInputsTags[input]};
                                        break;
                                    }
                                }
                            }
                        }
                        // for additional inputs
                        if (sel_query?.case_search_list.length > 1) {
                            sel_query?.case_search_list.forEach((el, index) => {
                                if (index > 0 && index < sel_query?.case_search_list.length) {
                                    let obj = {};
                                    for (const input in el) {
                                        if (!dropDownItems.includes(input)) {
                                            let str = "";
                                            let value = sel_query?.case_search_list[index][input];
                                            if (typeof value === "string") {
                                                str = value
                                                    .replaceAll(" AND ", "->")
                                                    .replaceAll(" OR ", "->")
                                                    .replaceAll(" NOT ", "->");
                                            }
                                            if (Array.isArray(value)) {
                                                str = value[0];
                                            }
                                            obj[input] = str.split("->");
                                        } else if (sel_query?.sources.includes("unicourt")) {
                                            switch (input) {
                                                case "search_type": {
                                                    let displayName = "";
                                                    if (el[input] === "case_number") {
                                                        displayName = "Case number";
                                                    } else if (el[input] === "case_name") {
                                                        displayName = "Case name";
                                                    } else if (el[input] === "all") {
                                                        displayName = "Everything";
                                                    } else {
                                                        displayName = this.getDisplayDropDownName(el[input]);
                                                    }
                                                    obj[input] = {name: displayName, value: el[input]};
                                                    break;
                                                }
                                                case "scope": {
                                                    let displayName = this.getDisplayDropDownName(el[input]);
                                                    obj[input] = {name: displayName, value: el[input]};
                                                    break;
                                                }
                                                case "proximity": {
                                                    let displayName = this.getDisplayDropDownName(el[input]);
                                                    obj[input] = {name: displayName, value: el[input]};
                                                    break;
                                                }
                                                case "party_types": {
                                                    let displayName = this.getDisplayDropDownName(el[input]);
                                                    obj[input] = {name: displayName, value: el[input]};
                                                    break;
                                                }
                                                case "representation": {
                                                    let displayName = this.getDisplayDropDownName(el[input]);
                                                    obj[input] = {name: displayName, value: el[input]};
                                                    break;
                                                }
                                                case "condition_operator": {
                                                    obj[input] = {name: el[input]};
                                                    break;
                                                }
                                            }
                                        }
                                    }
                                    let selectedIndex = index - 1;
                                    tools.newTagList.splice(selectedIndex, 1, obj);
                                }
                            });
                        }
                    }

                    if (Array.isArray(sel_query?.sources) && sel_query?.sources.includes("unicourt")) {
                        // tools.handleSourceExpand("uniCourt", "uniCourt", true);
                        tools.allSources["uniCourt"].isChecked = true;
                        tools.sources.push("uniCourt");
                        // for api payload
                        tools.caseSearchList = sel_query?.case_search_list;
                    }
                    if (Array.isArray(sel_query?.sources) && sel_query?.sources.includes("judyrecords")) {
                        tools.allSources["judyRecords"].isChecked = true;
                        tools.sources.push("judyRecords");
                        tools.caseSearchList = sel_query?.case_search_list;

                        let finalSearchString = "";
                        for (const key in tools.tagList) {
                            if (tools.tagList[key].length > 0) {
                                finalSearchString = finalSearchString + " " + tools.tagList[key].join(" ");
                            }
                        }
                        tools.finalSearchString = finalSearchString.trim();
                    }
                    await tools.search(false, isSearchWithSearchQuery);
                    break;
                case "flickr-tool":

                case "email-search-tool":
                    tools.valueInput = Object.values(sel_query)[0];
                    tools.selectedItem = {
                        value: Object.values(sel_query)[0],
                    };
                    tools.selectVal = Object.values(sel_query)[0];
                    tools.handleSearch();
                    break;

                case "partial-domain-search-tool":
                    tools.valueInput = Object.values(sel_query)[0];
                    (tools.selectedItem = Object.values(sel_query)[0]), (tools.selectVal = Object.values(sel_query)[0]);
                    tools.handleSearch();
                    break;

                case "facebook-tool":
                    switch (Object.keys(sel_query)[0]) {
                        case "country_code":
                            tools.inputValue = "Phone";
                            tools.phoneNumber.number = {value: sel_query.number};
                            tools.phoneNumber.countryCode = {
                                res: {
                                    _id: sel_query.id,
                                },
                                value: sel_query.country_with_code,
                            };
                            tools.request_id = sel_query.query_id;
                            break;
                        case "fb_id":
                            tools.inputValue = "FBID";
                            tools.facebookId = Object.values(sel_query)[0];
                            break;
                        case "email":
                            tools.inputValue = "Email";
                            tools.selectedItem = {
                                value: Object.values(sel_query)[0],
                            };
                            tools.selectVal = Object.values(sel_query)[0];
                            tools.searchKeyword = Object.values(sel_query)[0];
                            break;
                        case "fname":
                            tools.inputValue = "Name";
                            tools.firstName = sel_query.fname ? sel_query.fname : "";
                            tools.lastName = sel_query.lname ? sel_query.lname : "";
                            break;
                        case "fb_url":
                            tools.inputValue = "URL";
                            tools.selectedItem = {value: Object.values(sel_query)[0]};
                            tools.searchKeyword = Object.values(sel_query)[0];
                            break;
                    }
                    tools.search();
                    break;
                case "dark-web-tool":
                    tools.handleClearAll();
                    tools.selectedTab = "breaches";
                    tools.fieldType = Object.keys(sel_query)[0] != "query" ? tools.fieldTypeOptions[0].categories.find((n) => n.value == Object.keys(sel_query)[0]) : {text: "All Fields (Default)", value: "all"};
                    switch (Object.keys(sel_query)[0]) {
                        case "username":
                            tools.selectVal["username"] = Object.values(sel_query)[0];
                            tools.setTheCustomUsername(Object.values(sel_query)[0]);
                            break;
                        case "email":
                            tools.selectVal["email"] = Object.values(sel_query)[0];
                            tools.setTheCustomEmail(Object.values(sel_query)[0]);
                            break;
                        case "phone":
                            tools.selectVal["phone"] = Object.values(sel_query)[0];
                            tools.setTheCustomPhone(Object.values(sel_query)[0]);
                            break;
                        case "domain":
                            tools.selectVal["domain"] = Object.values(sel_query)[0];
                            tools.setTheCustomDomain(Object.values(sel_query)[0]);
                            break;
                        case "name":
                            tools.selectVal["name"] = Object.values(sel_query)[0];
                            tools.setTheCustomName(Object.values(sel_query)[0]);
                            break;
                        case "address":
                            tools.address = Object.values(sel_query)[0];
                        default:
                            tools[Object.keys(sel_query)[0] === "all" ? "query" : Object.keys(sel_query)[0]] = Object.values(sel_query)[0];
                            break;
                    }
                    tools.triggerRoute = false;
                    tools.search();
                    break;

                case "github-tool":
                    switch (Object.keys(sel_query)[0]) {
                        case "email":
                            tools.searchKeyword = "";
                            tools.searchUsername = "";
                            tools.searchEmail = Object.values(sel_query)[0];
                            tools.selectedItem = {
                                value: Object.values(sel_query)[0],
                            };
                            tools.inputValue = "Email";
                            tools.searchType = "email";
                            tools.selectVal = Object.values(sel_query)[0];
                            break;
                        case "query":
                            tools.searchEmail = "";
                            tools.searchKeyword = "";
                            tools.searchUsername = Object.values(sel_query)[0];
                            tools.selectedItemUsername = {
                                value: Object.values(sel_query)[0],
                            };
                            tools.inputValue = "Username";
                            tools.searchType = "username";
                            tools.selectNameVal = Object.values(sel_query)[0];
                            break;
                        case "code":
                            tools.searchEmail = "";
                            tools.searchUsername = "";
                            tools.searchKeyword = Object.values(sel_query)[0];
                            tools.inputValue = "Code Search";
                            tools.searchType = "text";
                            break;
                        default:
                            break;
                    }
                    tools.triggerRoute = false;
                    tools.search();
                    break;

                case "company-name":
                    const sortedQuery = Object.keys(sel_query)
                        .sort((a, b) => a.localeCompare(b))
                        .reduce((acc, key) => {
                            acc[key] = sel_query[key];
                            return acc;
                        }, {});
                    let keys = Object.keys(sortedQuery);
                    if (keys.includes("address") && sel_query.sources) {
                        keys.shift();
                    }
                    if (keys.includes("address_county")) {
                        keys.shift();
                    }

                    if (keys.includes("address_locality")) {
                        keys.shift();
                    }
                    if (keys.includes("address_region")) {
                        keys.shift();
                    }
                    if (keys.includes("candidate_maximum_quantity")) {
                        keys.shift();
                    }
                    if (keys.includes("city")) {
                        keys.shift();
                    }
                    if (keys.includes("code")) {
                        keys.shift();
                    }
                    if (keys.includes("confidence_lower_level_threshold_value")) {
                        keys.shift();
                    }
                    if (keys.includes("countryofresidence")) {
                        keys.shift();
                    }
                    if (keys.includes("country")) {
                        keys.shift();
                    }
                    if (keys.includes("dbcode")) {
                        keys.shift();
                    }

                    if (keys.includes("dobfrom")) {
                        keys.shift();
                    }
                    if (keys.includes("duns")) {
                        keys.shift();
                    }
                    if (keys.includes("email")) {
                        keys.shift();
                    }
                    if (keys.includes("nationality")) {
                        keys.shift();
                    }
                    if (keys.includes("mode")) {
                        keys.shift();
                    }
                    if (sel_query?.address && sel_query?.address != "") {
                        keys[0] = "address";
                    }
                    tools.showSearch = true;
                    tools.showResult = false;
                    tools.request_id = query_id;
                    switch (keys[0]) {
                        case "company":
                            tools.entityCount = 1;
                            tools.queries = [];
                            tools.officer = "";
                            tools.address = "";
                            tools.searchKeyword = sel_query?.company ?? "";
                            tools.selectVal = sel_query?.company ? [sel_query?.company] : [];
                            tools.address = sel_query?.street_address_line1 || "";
                            tools.postCode = sel_query?.postal_code || "";
                            tools.locality = sel_query?.address_locality || "";
                            tools.region = sel_query?.address_region || "";
                            tools.registrationNumber = sel_query?.registration_number || "";
                            tools.phone = sel_query?.telephone_number || "";
                            tools.duns = sel_query?.duns || "";
                            tools.email = sel_query?.email || "";
                            let jurisdictionComp = null;
                            if (sel_query.code) {
                                jurisdictionComp = tools.jurisdictionList[0].categories.find((n) => n.value === sel_query.code);
                            }
                            if (sel_query.code && sel_query.sources.includes("db")) {
                                dbJurisdictionName = tools.dbJurisdictionList[0]?.categories.find((n) => n.country_code.toLowerCase() === sel_query.code);
                            }
                            tools.dbJurisdiction = dbJurisdictionName ?? null;
                            tools.jurisdiction = jurisdictionComp ?? null;
                            if (sel_query.mode) {
                                let search_mode_find = tools.searchMode?.props?.options?.find((ele) => ele.value === sel_query.mode);
                                tools.searchMode.value = search_mode_find;
                            }
                            tools.selectedItem = {
                                value: sel_query?.company ?? "",
                            };
                            tools.fieldType = {
                                text: "Company",
                                value: "company",
                            };
                            if (sel_query.sources) {
                                tools.openCorporatesCheck = sel_query.sources.includes("openCorporates");
                                tools.clarifiedByCheck = sel_query.sources.includes("clarifiedBy");
                                tools.companyWatchCheck = sel_query.sources.includes("companyWatch");
                                tools.bvdCheck = sel_query.sources.includes("bvd");
                                tools.dnbCheck = sel_query.sources.includes("db");
                            }
                            break;
                        case "name":
                            tools.entityCount = 1;
                            tools.queries = [];
                            tools.officer = "";
                            tools.address = "";
                            tools.jurisdiction = "";
                            tools.searchKeyword = sel_query?.name ?? "";
                            tools.selectVal = sel_query?.name ? [sel_query?.name] : [""];
                            let jurisdictionName = null;
                            let dbJurisdictionName = null;
                            if (sel_query.code && sel_query.sources.includes("opencorporates")) {
                                jurisdictionName = tools.jurisdictionList[0]?.categories.find((n) => n.value === sel_query.code);
                            }
                            tools.jurisdiction = jurisdictionName ?? null;
                            if (sel_query.code && sel_query.sources.includes("db")) {
                                dbJurisdictionName = tools.dbJurisdictionList[0]?.categories.find((n) => n.country_code.toLowerCase() === sel_query.code);
                            }
                            tools.dbJurisdiction = dbJurisdictionName ?? null;
                            tools.dbaddress = sel_query?.street_address_line1 ?? "";
                            tools.postCode = sel_query?.postal_code ?? "";
                            tools.locality = sel_query?.address_locality ?? "";
                            tools.region = sel_query?.address_region ?? "";
                            tools.registrationNumber = sel_query?.registration_number ?? "";
                            tools.phone = sel_query?.telephone_number ?? "";
                            tools.duns = sel_query?.duns ?? "";
                            tools.email = sel_query?.email ?? "";
                            let search_mode_find = null;
                            if (sel_query?.street_address_line1 || sel_query?.postal_code || sel_query?.address_locality || sel_query?.address_region || sel_query?.registration_number || sel_query?.telephone_number || sel_query?.duns || sel_query?.email) {
                                tools.isAdvancedSearch = true;
                            }
                            if (sel_query.mode) {
                                search_mode_find = tools.searchMode?.props?.options?.find((ele) => ele.value === sel_query.mode);
                                tools.searchMode.value = search_mode_find;
                            }
                            tools.selectedItem = {
                                value: sel_query?.name ?? "",
                            };
                            tools.fieldType = {
                                text: "Company",
                                value: "company",
                            };
                            if (sel_query?.sources) {
                                tools.openCorporatesCheck = sel_query.sources.includes("opencorporates");
                                tools.clarifiedByCheck = sel_query.sources.includes("clarifiedby");
                                tools.companyWatchCheck = sel_query.sources.includes("companywatch");
                                tools.bvdCheck = sel_query.sources.includes("bvd");
                                tools.dnbCheck = sel_query.sources.includes("db");
                            }
                            break;
                        case "officer":
                            tools.entityCount = 1;
                            tools.queries = [];
                            tools.searchKeyword = "";
                            tools.address = "";
                            tools.jurisdiction = "";
                            tools.officer = sel_query?.officer ?? "";
                            tools.selectVal = sel_query?.officer ? [sel_query?.officer] : [""];
                            tools.fieldType = {
                                text: "Officer",
                                value: "officer",
                            };
                            if (sel_query.code) {
                                jurisdictionName = tools.jurisdictionList[0].categories.find((n) => n.value === sel_query.code);
                            }
                            tools.jurisdiction = jurisdictionName ?? null;
                            if (sel_query.mode) {
                                let search_mode_find = tools.searchMode?.props?.options?.find((ele) => ele.value === sel_query.mode);
                                tools.searchMode.value = search_mode_find;
                            }

                            if (sel_query?.sources) {
                                tools.openCorporatesCheck = sel_query.sources.includes("opencorporates");
                                tools.clarifiedByCheck = sel_query.sources.includes("clarifiedby");
                                tools.companyWatchCheck = sel_query.sources.includes("companywatch");
                                tools.bvdCheck = sel_query.sources.includes("bvd");
                            }
                            break;
                        case "address":
                            tools.entityCount = 1;
                            tools.queries = [];
                            tools.searchKeyword = "";
                            tools.officer = "";
                            tools.jurisdiction = "";
                            tools.address = sel_query?.address ?? "";
                            tools.fieldType = {
                                text: "Address",
                                value: "address",
                            };

                            break;
                        default:
                            break;
                    }
                    tools.showMoreInfo = false;
                    tools.selectedQuery = null;
                    tools.selectedCard = null;
                    tools.triggerRoute = false;
                    await tools.search();
                    break;

                case "google-tool":
                    tools.searchKeyword = "";
                    tools.googleID = "";
                    tools.selectVal = "";
                    tools.selectNameVal = "";
                    switch (Object.keys(sel_query)[0]) {
                        case "email":
                            tools.searchKeyword = Object.values(sel_query)[0];
                            tools.selectedItem = {
                                value: Object.values(sel_query)[0],
                            };
                            tools.inputValue = "Email";
                            tools.selectVal = Object.values(sel_query)[0];
                            break;
                        case "phone":
                            tools.searchKeyword = Object.values(sel_query)[0];
                            tools.selectedItemUsername = {
                                value: Object.values(sel_query)[0],
                            };
                            tools.inputValue = "Phone";
                            tools.selectNameVal = Object.values(sel_query)[0];
                            break;
                        case "query":
                            tools.googleID = Object.values(sel_query)[0];
                            tools.inputValue = "Google ID";
                            break;
                        default:
                            break;
                    }
                    tools.searchType = Object.keys(sel_query)[0] !== "query" ? Object.keys(sel_query)[0] : "googleID";
                    tools.triggerRoute = false;
                    tools.search();
                    break;

                case "linkedin-tool":
                    if (Object.keys(sel_query)[0] === "email" || Object.keys(sel_query)[0] === "domain" || Object.keys(sel_query)[0] === "linkedin_url") {
                        tools.nameKeyword = "";
                        tools.companyName = "";
                        tools.selectedName = null;
                        tools.searchEmail = "";
                        tools.selectedEmail = null;
                        tools.discoveryDetails = [];
                        tools.searchKeyword = Object.values(sel_query)[0];
                        tools.selectedItem = {
                            value: Object.values(sel_query)[0],
                        };
                        tools.inputValue = {
                            text: startCase(Object.keys(sel_query)[0]) == "Linkedin Url" ? "URL" : startCase(Object.keys(sel_query)[0]),
                            value: startCase(Object.keys(sel_query)[0]) == "Linkedin Url" ? "URL" : startCase(Object.keys(sel_query)[0]),
                        };
                        tools.selectVal = Object.values(sel_query)[0];
                        tools.triggerRoute = false;
                        tools.selectedTab = "details";
                        tools.search();
                    } else if (Object.keys(sel_query)[1] === "discovery") {
                        tools.nameKeyword = "";
                        tools.companyName = "";
                        tools.selectedName = null;
                        tools.searchKeyword = "";
                        tools.selectedItem = null;
                        tools.triggerRoute = false;
                        tools.searchEmail = Object.values(sel_query)[0];
                        tools.selectedEmail = {
                            value: Object.values(sel_query)[0],
                        };
                        tools.selectEmailVal = Object.values(sel_query)[0];
                        tools.selectedTab = "discovery";
                        tools.discoverySearch();
                    } else if (Object.keys(sel_query)[1] === "name" || Object.keys(sel_query)[0] === "company") {
                        tools.searchKeyword = "";
                        tools.nameKeyword = "";
                        tools.searchEmail = "";
                        tools.companyName = "";
                        tools.selectedItem = null;
                        tools.nameKeyword = Object.values(sel_query)[1];
                        tools.companyName = Object.values(sel_query)[0];
                        tools.selectedName = {
                            value: Object.values(sel_query)[1],
                        };
                        tools.triggerRoute = false;
                        tools.selectedTab = "details";
                        tools.selectNameVal = Object.values(sel_query)[1];
                        tools.search();
                    }
                    break;

                case "news-tool":
                    if (query._id) {
                        let query_id = query._id;
                        let {query: search, sources, from_date, to_date, language, news_sources, language_factiva, regions, keywords, nearness} = sel_query;
                        let search_keyword = search?.split(" near")[0];
                        // search_keyword = search_keyword.replace("(", "");
                        // search_keyword = search_keyword.replace(")", "");
                        tools.resetFilter();
                        // tools.clearFilters();
                        tools.request_id = query_id;
                        tools.searchKeyword = search_keyword;
                        tools.start_date.value = from_date ?? null;
                        tools.end_date.value = to_date ?? null;
                        tools.dowjones_sources.value = news_sources ?? [];
                        // tools.dowjones_jurisdiction.value = regions ?? [];
                        tools.selectedKeywordsData = {
                            keywords: {},
                            nearCount: nearness,
                        };
                        for (const index in keywords) {
                            tools.selectedKeywordsData.keywords = {
                                ...tools.selectedKeywordsData.keywords,
                                ...{
                                    [keywords[index].keyword_id]: keywords[index].words,
                                },
                            };
                        }
                        tools.setSelectedKeywordsData(tools.selectedKeywordsData);
                        if (language) {
                            tools.language.value = tools.allLanguages[0]?.categories?.find((ele) => ele.value === language) ?? {label: "English", value: "en"};
                        }
                        language_factiva = typeof language_factiva === "string" ? language_factiva?.split(",") : language_factiva;
                        language_factiva = language_factiva?.length ? language_factiva : [];
                        if (language_factiva?.length) {
                            language_factiva?.forEach((lang) => {
                                let match = tools.allLanguages[0]?.categories?.find((ele) => ele.value === lang);
                                tools.language_factiva.value.push(match);
                            });
                        }
                        regions = typeof regions === "string" ? regions?.split(",") : regions;
                        regions = regions?.length ? regions : [];
                        if (regions?.length) {
                            regions?.forEach((reg) => {
                                let match = tools.allRegions[0]?.categories?.find((ele) => ele.value === reg);
                                tools.dowjones_jurisdiction.value.push(match);
                            });
                        }

                        if (sources) {
                            tools.sources = sources;
                            if (tools.sources.includes("news")) {
                                tools.allSources["news"].isChecked = true;
                            }
                            if (tools.sources.includes("dowjonesfactiva")) {
                                tools.allSources["djfactiva"].isChecked = true;
                            }
                        }

                        tools.showSearch = true;
                        tools.showResult = false;
                        tools.searchKeywordStringFormatted = search;
                        tools.isExistingSearchQuery = true;
                        // tools.isExistingSearchQuery = true;
                    }
                    // else {
                    //     tools.searchKeyword = Object.values(sel_query)[0];
                    // }
                    // tools.language = language;
                    await tools.search();
                    break;

                case "person-identifier-tool":
                    tools.handleClear();
                    if (Array.isArray(sel_query.name)) {
                        const {email, phone, name, location, social_details, educations, jobs, age} = sel_query;
                        if (email || phone || name || location || social_details || educations || jobs || age) {
                            if (email) {
                                const data = email.map((el) => el.email);
                                tools.formData.contacts[0] = {
                                    ...tools.formData?.contacts?.[0],
                                    emailInput: data,
                                };
                                tools.selectedItem["email"] = data.map((el) => tools.getTag(el));
                            }
                            if (age) {
                                tools.formData.age[0] = {
                                    ...tools.formData?.age?.[0],
                                    age: age,
                                };
                            }
                            if (phone) {
                                const data = phone.map((el) => el.phone);
                                tools.formData.contacts[0] = {
                                    ...tools.formData?.contacts?.[0],
                                    phoneInput: data,
                                };
                                tools.selectedItem["phone"] = data.map((el) => tools.getTag(el));
                            }
                            if (name) {
                                for (const i in name) {
                                    tools.formData.names[i] = {
                                        ...tools.formData?.names?.[i],
                                        fNameInput: name[i]?.first_name,
                                        mNameInput: name[i]?.middle_name,
                                        lNameInput: name[i]?.last_name,
                                    };
                                }
                            }
                            if (location) {
                                for (const i in location) {
                                    tools.formData.locations[i] = {
                                        ...tools.formData?.locations?.[i],
                                        countryCodeInput: location[i]?.country,
                                        stateCodeInput: location[i]?.state,
                                        cityInput: location[i]?.city,
                                    };
                                }
                            }
                            if (social_details) {
                                for (const i in social_details) {
                                    tools.formData.socialProfiles[i] = {
                                        ...tools.formData?.socialProfiles?.[i],
                                        usernameInput: social_details[i]?.username,
                                        platformInput: social_details[i]?.social_profile || "",
                                    };
                                    tools.selectedItem["username"][i] = tools.getTag(social_details[i].username);
                                    tools.selectedItem["platform"][i] = tools.getTag(social_details[i].social_profile);
                                }
                            }
                            if (educations) {
                                for (const i in educations) {
                                    tools.formData.educations[i] = {
                                        ...tools.formData?.educations?.[i],
                                        degreeInput: educations[i].degree,
                                        schoolInput: educations[i].school,
                                        fromDateInput: educations[i].date_range.start_date,
                                        toDateInput: educations[i].date_range.end_date,
                                    };
                                    tools.selectedItem["education"][i] = tools.getTag(educations[i].school);
                                }
                            }
                            if (jobs) {
                                for (const i in jobs) {
                                    tools.formData.employments[i] = {
                                        ...tools.formData?.employments?.[i],
                                        titleInput: jobs[i].title,
                                        organisationInput: jobs[i].organization,
                                        industryInput: jobs[i].industry,
                                        fromDateInput: jobs[i].date_range.start_date,
                                        toDateInput: jobs[i].date_range.end_date,
                                    };
                                    tools.selectedItem["employment"][i] = tools.getTag(jobs[i].organization);
                                }
                            }
                        }
                    } else {
                        const {email, phone, first_name, last_name, middle_name, country, state, city, username, age} = sel_query;
                        if (email || phone || first_name || last_name || middle_name || country || state || city || username || age) {
                            if (email) {
                                tools.formData.contacts[0].emailInput = [];
                                tools.formData.contacts[0].emailInput.push(email);
                                tools.selectedItem["email"][0] = tools.getTag(email);
                            }
                            if (age) {
                                tools.formData.age[0].age = "";
                                tools.formData.age[0].age = age;
                            }
                            if (phone) {
                                tools.formData.contacts[0].phoneInput = [];
                                tools.formData.contacts[0].phoneInput.push(phone);
                                tools.selectedItem["phone"][0] = tools.getTag(phone);
                            }
                            if (username) {
                                tools.formData.socialProfiles[0].usernameInput = username;
                                tools.selectedItem["username"][0] = tools.getTag(username);
                            }
                            tools.formData.names[0].fNameInput = first_name ?? "";
                            tools.formData.names[0].lNameInput = last_name ?? "";
                            tools.formData.names[0].mNameInput = middle_name ?? "";
                            tools.formData.locations[0].countryCodeInput = country ?? "";
                            tools.formData.locations[0].stateCodeInput = state ?? "";
                            tools.formData.locations[0].cityInput = city ?? "";
                        }
                    }
                    tools.triggerRoute = false;
                    tools.toggleAllCategories();
                    tools.handleSearch();
                    break;

                case "gdc-tool":
                    tools.requestId = query_id;
                    tools.showForm = false;
                    tools.showResult = false;
                    tools.isLoading = true;
                    tools.nationalDetails = [];
                    tools.companyDetails = [];
                    var {address, identity, email, phone} = sel_query;
                    if (address || identity || phone || email) {
                        if (address || identity || phone || email) {
                            tools.countryCode.value = address.countryCode;
                            tools.houseInput = address.houseNumber;
                            tools.addressL1Input = address.addressLine1;
                            tools.addressL2Input = address.addressLine2;
                            tools.throughfareInput = address.thoroughfare;
                            tools.provinceInput = address.province;
                            tools.postalInput = address.postalCode;
                            tools.localityInput = address.locality;
                            tools.addHouseInput = address.houseNumberAddition;
                            tools.emailInput = email.full_email_address;
                            tools.fNameInput = identity.first_name;
                            tools.lNameInput = identity.last_name;
                            tools.mNameInput = identity.middle_name;
                            tools.nationalDetails = identity.nationalid;
                            tools.companyName = identity.organization_name ? identity.organization_name : "";
                            tools.companyDetails = identity.businessid ? identity.businessid : [];
                            tools.publishedDate = identity.dob;
                            tools.numberInput = phone.phone_number;
                            tools.countryCode.name = address.country;
                            tools.nationalvalue = address.nationalvalue;
                            if (identity.organization_name) {
                                tools.selectedEntity = {
                                    text: "Company",
                                    value: "company",
                                };
                            } else {
                                tools.selectedEntity = {
                                    text: "Person",
                                    value: "person",
                                };
                            }
                        }
                    }
                    tools.isLoading = false;
                    await tools.searched();
                    break;

                case "adverse-media-tool":
                case "offshoreleaks-tool":
                case "risk-tool":
                    tools.resetFilter();
                    tools.request_id = query_id;
                    tools.showResult = false;
                    tools.items_count = 10;
                    var {name, year_of_birth, fuzziness, adverseCountry, lexiscountry, lexisyearOfbirth, checks, sources, entity_type, icij_entity_type, adverseAge, search_mode} = sel_query;
                    if (name || year_of_birth || fuzziness || adverseCountry || lexiscountry || lexisyearOfbirth || checks || sources || entity_type || icij_entity_type || adverseAge || search_mode) {
                        if (name) {
                            tools.searchKeyword = name;
                            tools.selectedItem = [
                                {
                                    value: name,
                                },
                            ];
                            tools.selectVal = [name];
                        }
                        if (checks) {
                            tools.checks = checks;
                            tools.peps = tools.checks.includes("peps");
                            tools.sanction = tools.checks.includes("sanctions");
                            tools.adverseMedia = tools.checks.includes("adverse");
                        }
                        if (sources) {
                            tools.sources = sources;
                            if (tools.sources.includes("complyadvantage")) {
                                tools.allSources["complyAdvantage"].isChecked = true;
                            }
                            if (tools.sources.includes("lexis")) {
                                tools.allSources["lexisBridger"].isChecked = true;
                            }
                            if (tools.sources.includes("worldcheck")) {
                                tools.allSources["worldCheck"].isChecked = true;
                            }
                            /// dowjones split enitites sources
                            if (tools.sources.includes("wl")) {
                                tools.allSources["djWl"].isChecked = true;
                            }
                            if (tools.sources.includes("soe")) {
                                tools.allSources["djSoe"].isChecked = true;
                            }
                            if (tools.sources.includes("ame") && !tools.allSources["djAme"].disabled) {
                                tools.allSources["djAme"].isChecked = true;
                            }
                            if (tools.sources?.includes("offshoreleaks")) {
                                tools.allSources["offshoreLeaks"].isChecked = true;
                            }
                        }
                        if (year_of_birth) {
                            tools.yearOfBirth.value = year_of_birth;
                        }
                        if (lexisyearOfbirth) {
                            tools.lexisYearOfBirth.value = lexisyearOfbirth;
                        }
                        if (search_mode) {
                            let search_mode_find = tools.searchMode?.props?.options?.find((ele) => ele.value === search_mode);
                            tools.searchMode.value = search_mode_find;
                        }
                        if (fuzziness) {
                            tools.fuzzinessLevel.value = fuzziness;
                        }
                        if (entity_type) {
                            if (typeof entity_type === "string") {
                                let entityFind = tools.entityType?.props.options?.find((ele) => ele.value === entity_type);
                                tools.entityType.value = entityFind ? entityFind : {name: "All", value: ""};
                                let lexisEntity = tools.lexisEntityType?.props.options?.find((ele) => ele.value === entity_type);
                                tools.lexisEntityType.value = lexisEntity ? lexisEntity : {name: "Person", value: "Person"};
                                // let entityFindComplyAdvantage = tools.entityTypecomplyadvantage?.props.options?.find((ele) => ele.value === entity_type);
                                // tools.entityTypecomplyadvantage.value = entityFindComplyAdvantage ? entityFindComplyAdvantage : { name: "All", value: "" };

                                // let entityFindWorldCheck = tools.entityTypeworldcheck?.props.options?.find((ele) => ele.value === entity_type);
                                // tools.entityTypeworldcheck.value = entityFindWorldCheck ? entityFindWorldCheck : { name: "All", value: "" };

                                // let entityFindwl = tools.entityTypewl?.props.options?.find((ele) => ele.value === entity_type);
                                // tools.entityTypewl.value = entityFindwl ? entityFindwl : { name: "All", value: "" };

                                // let entityFindsoe = tools.entityTypesoe?.props.options?.find((ele) => ele.value === entity_type);
                                // tools.entityTypesoe.value = entityFindsoe ? entityFindsoe : { name: "All", value: "" };

                                // let entityFindame = tools.entityTypeame?.props.options?.find((ele) => ele.value === entity_type);
                                // tools.entityTypeame.value = entityFindame ? entityFindame : { name: "All", value: "" };
                            } else {
                                let entityFindComplyAdvantage = tools.entityTypecomplyadvantage?.props.options?.find((ele) => ele.value === entity_type["complyadvantage"]);
                                tools.entityTypecomplyadvantage.value = entityFindComplyAdvantage ? entityFindComplyAdvantage : {name: "All", value: ""};

                                let entityFindWorldCheck = tools.entityTypeworldcheck?.props.options?.find((ele) => ele.value === entity_type["worldcheck"]);
                                tools.entityTypeworldcheck.value = entityFindWorldCheck ? entityFindWorldCheck : {name: "All", value: ""};

                                let entityFindwl = tools.entityTypewl?.props.options?.find((ele) => ele.value === entity_type["wl"]);
                                tools.entityTypewl.value = entityFindwl ? entityFindwl : {name: "All", value: ""};

                                let entityFindsoe = tools.entityTypesoe?.props.options?.find((ele) => ele.value === entity_type["soe"]);
                                tools.entityTypesoe.value = entityFindsoe ? entityFindsoe : {name: "All", value: ""};

                                let entityFindame = tools.entityTypeame?.props.options?.find((ele) => ele.value === entity_type["ame"]);
                                tools.entityTypeame.value = entityFindame ? entityFindame : {name: "All", value: ""};
                            }
                        }

                        if (icij_entity_type?.length) {
                            tools.icij_entity_type = icij_entity_type;
                        }

                        if (adverseCountry) {
                            tools.country.value = adverseCountry.map((ele) => {
                                let country = tools.countriesOptions[0]?.categories.find((ele1) => ele1.value == ele);
                                return country;
                            });
                        }
                        if (lexiscountry) {
                            let country = tools.countriesOptions[0]?.categories.find((ele1) => ele1.value == lexiscountry);
                            tools.lexisCountry.value = country;
                        }
                        tools.triggerRoute = false;
                        // tools.clearFilters();

                        await tools.search();
                    }
                    break;

                case "lexis-bridger-tool":
                    await tools.resetFilter();
                    tools.lexis_request_id = query_id;
                    tools.showResult = false;
                    tools.items_count = 10;
                    // tools.fillYears();
                    // tools.fetchAllJuridictions();
                    var {entity_type, person_entity, company_entity, additional_info, ids, phones, addresses} = sel_query;

                    if (entity_type || additional_info || ids || phones || addresses) {
                        if (entity_type === "Individual") {
                            tools.entityType = "person";
                            tools.fName = {
                                name: person_entity.first_name,
                                value: person_entity.first_name,
                            };
                            tools.mName = {
                                name: person_entity.middle_name,
                                value: person_entity.middle_name,
                            };
                            tools.lName = {
                                name: person_entity.last_name,
                                value: person_entity.last_name,
                            };
                        } else {
                            tools.entityType = "company";
                            tools.selectedItem = {
                                name: company_entity.name,
                                value: company_entity.name,
                            };
                        }

                        if (ids) {
                            for (let index in ids) {
                                const type = ids[index].type;
                                const value = ids[index].number;
                                if (type === "National") tools.nationalId.value = value;
                                else if (type === "DUNS") tools.dunsId.value = value;
                                else tools[type.toLowerCase()].value = value;
                            }
                        }

                        if (phones) {
                            for (let index in phones) {
                                const type = phones[index].type;
                                const value = phones[index].number;
                                tools[type.toLowerCase()].value = value;
                            }
                        }

                        if (addresses) {
                            for (let index in addresses) {
                                const type = addresses[index].type;
                                let key;
                                if (type === "Current") key = "curr";
                                else if (type === "Previous") key = "prev";
                                else if (type === "Mailing") key = "mailing";
                                tools[`${key}_address1`].value = addresses[index].street1;
                                tools[`${key}_address2`].value = addresses[index].street2;
                                tools[`${key}_city`].value = addresses[index].city;
                                tools[`${key}_state`].value = addresses[index].stateprovincedistrict ? tools[`${key}_state`].props.options?.find((opt) => opt.value === addresses[index].stateprovincedistrict) : null;
                                tools[`${key}_postalCode`].value = addresses[index].postalcode;
                                tools[`${key}_lexisCountry`].value = addresses[index].country ? tools[`${key}_lexisCountry`].props.options?.[0].categories?.find((opt) => opt.value === addresses[index].country) : null;
                            }
                        }

                        if (additional_info) {
                            for (let index in additional_info) {
                                const type = additional_info[index].type;
                                const value = additional_info[index].number || additional_info[index].value;
                                if (type === "Citizenship") tools.citizenship.value = value ? tools.citizenship.props.options?.[0].categories?.find((opt) => opt.value === value) : null;
                                else if (type !== "DOB") tools[type.toLowerCase()].value = value;
                                else {
                                    tools["lexisDateOfBirth"].drops[0].value = additional_info[index].date.date ? parseInt(additional_info[index].date.date, 10).toString() : "";
                                    tools["lexisDateOfBirth"].drops[1].value = additional_info[index].date.month ? tools["lexisDateOfBirth"].drops[1].props.options[parseInt(additional_info[index].date.month, 10) - 1] : "";
                                    tools["lexisDateOfBirth"].drops[2].value = additional_info[index].date.year;
                                }
                            }
                        }
                        await tools.mapInputFilters();
                        tools.triggerRoute = false;
                        // tools.clearFilters();
                        await tools.search();
                    }
                    break;
                case "username-tool":
                case "name-search-tool":
                    tools.searchKeyword = Object.values(sel_query)[0];
                    tools.selectedItem = {
                        value: Object.values(sel_query)[0],
                    };
                    tools.selectVal = Object.values(sel_query)[0];
                    tools.search();
                    break;

                case "company-domain":
                    tools.searchKeyword = Object.values(sel_query)[0];
                    tools.selectedItem = {
                        value: Object.values(sel_query)[0],
                    };
                    tools.inputValue = {
                        text: startCase(Object.keys(sel_query)[0]) == "Query" ? "Company Name" : startCase(Object.keys(sel_query)[0]),
                        value: startCase(Object.keys(sel_query)[0]) == "Query" ? "name" : Object.keys(sel_query)[0],
                    };
                    tools.selectVal = Object.values(sel_query)[0];
                    tools.search();
                    break;
                case "twitter-tool":
                    tools.inputValue = {};
                    tools.inputValue = {
                        text: startCase(Object.keys(sel_query)[0]),
                        value: startCase(Object.keys(sel_query)[0]),
                    };
                    if (startCase(Object.keys(sel_query)[0]) === "Username") {
                        tools.selectedTab = "details";
                        tools.searchKeyword = Object.values(sel_query)[0];
                        tools.selectedItem = {
                            value: Object.values(sel_query)[0],
                        };
                        tools.selectVal = Object.values(sel_query)[0];
                    } else if (startCase(Object.keys(sel_query)[0]) === "Hashtag") {
                        tools.selectedTab = "tweets";
                        tools.hashtag = Object.values(sel_query)[0];
                    } else {
                        tools.selectedTab = "details";
                        tools.person = Object.values(sel_query)[0];
                    }
                    tools.triggerRoute = false;
                    tools.search();
                    break;

                case "skype-tool":
                    tools.inputValue = {};
                    tools.searchKeyword = Object.values(sel_query)[0];
                    tools.selectedItem = {
                        value: Object.values(sel_query)[0],
                    };
                    tools.selectVal = Object.values(sel_query)[0];
                    let text_val = startCase(Object.keys(sel_query)[0]) == "Query" ? "Name" : startCase(Object.keys(sel_query)[0]);
                    tools.inputValue = {
                        text: text_val === "Phone" ? "Phone Number" : text_val,
                        value: text_val,
                    };
                    tools.triggerRoute = false;
                    tools.search();
                    break;

                case "tiktok-tool":
                    switch (Object.keys(sel_query)[0]) {
                        case "username":
                            tools.searchKeyword = "";
                            tools.searchUsername = Object.values(sel_query)[0];
                            tools.selectedItemUsername = {
                                value: Object.values(sel_query)[0],
                            };
                            tools.inputValue = "Username";
                            break;
                        case "hashtag":
                            tools.searchUsername = "";
                            tools.searchKeyword = Object.values(sel_query)[0];
                            tools.inputValue = "Hashtag";
                            break;
                        default:
                            break;
                    }
                    tools.selectVal = Object.values(sel_query)[0];
                    tools.searchType = Object.keys(sel_query)[0];
                    tools.triggerRoute = false;
                    tools.search();
                    break;

                case "instagram-tool":
                    switch (Object.keys(sel_query)[0]) {
                        case "username":
                            tools.searchKeyword = Object.values(sel_query)[0];
                            tools.selectedItem = {
                                value: Object.values(sel_query)[0],
                            };
                            tools.inputValue = "Username";
                            break;
                        case "query":
                            tools.query = Object.values(sel_query)[0];
                            tools.inputValue = "Query";
                            break;
                        default:
                            break;
                    }
                    tools.selectVal = Object.values(sel_query)[0];
                    tools.triggerRoute = false;
                    tools.search();
                    break;

                case "domain-search-tool":
                    tools.valueInput = Object.values(sel_query)[0];
                    tools.selectedItem = {
                        value: Object.values(sel_query)[0],
                    };
                    tools.inputValue = {
                        text: startCase(Object.keys(sel_query)[0]) == "Query" ? "Registrant" : startCase(Object.keys(sel_query)[0]),
                        value: startCase(Object.keys(sel_query)[0]) == "Query" ? "Registrant" : startCase(Object.keys(sel_query)[0]),
                    };
                    tools.triggerRoute = false;
                    tools.handleSearch();
                    break;

                default:
                    break;
            }

            if (this.$route.name === "monitoring") {
                if (this.currentToolSelected === "search-engine-tool") {
                    let tree = tools.tree;
                    tools.handleQuerySearch(tree);
                }
                const endDt = new Date();
                const startdt = new Date(endDt.getTime() - 30 * 24 * 60 * 60 * 1000);
                let dt = [startdt, endDt];
                if (this.getMonitoringDateFilter && this.getMonitoringDateFilter.startDate && this.getMonitoringDateFilter.endDate) {
                    dt = [this.getMonitoringDateFilter.startDate, this.getMonitoringDateFilter.endDate];
                }
                await this.setMonitoringDateFilter(dt, changeRange);
            }
            this.isSearchQueryClicked = false;
        },

        viewQuery({query}) {
            let name = Object.keys(query).filter((n) => n == "name");
            if (name) {
                query = {name: query["name"], ...query};
            }
            return Object.entries(query)
                .map((n) => (n[0] != "page_size" && n[0] != "start" ? n[1] : ""))
                .filter((n) => n)
                .join(", ");
        },

        async handleMonitoringStatus(event) {
            // const index = this.fetchSearchedQueries.findIndex((n) => n._id == event._id);
            // if (index != -1) {
            //     this.fetchSearchedQueries[index].monitoring_status = event.monitoring_status;
            //     this.$forceUpdate();
            // }
            await axios.put("/tools-monitoring", {
                case_id: this.$route.params.id,
                id: event._id,
                monitoring_status: event.is_monitoring,
                tool: this.currentToolSelected,
            });
            await this.getToolsStatus();
            const query = {
                ...event,
                ...{
                    _id: event._id,
                    monitoring_id: event.monitoring_id,
                    tool: this.currentToolSelected,
                },
            };
            this.updateFrequency({
                query: query,
                isQuery: true,
                maskedQuery: this.viewQuery(query),
            });
            let inherit_settings = this.monitoringTools[this.currentToolSelected] && this.monitoringTools[this.currentToolSelected].status === "enabled" && !this.monitoringTools[this.currentToolSelected].has_inner_level_monitoring ? this.monitoringTools[this.currentToolSelected] : this.productMonitoring.find((product) => product.tool === this.currentToolSelected);
            this.updateMonitoring({
                frequency: inherit_settings?.frequency,
                monitoring_status: event.is_monitoring ? "enabled" : "disabled",
            });
        },

        async fetchCaseData() {
            this.isCaseDataApiCall = true;
            const caseId = this.$store.getters.getCaseId;
            let data = {caseData: [], subject: {}, connections: []};
            let url = `/entity-identifier?caseid=${caseId}&placeholder_only=false`;
            try {
                let response = await axios.get(url);
                data.caseData = response.data.entities;
                data.subject = response.data.main_node[0];
                data.connections = response.data.connections;
                this.isCaseDataApiCall = false;
                return data;
            } catch (error) {
                console.error(error);
                this.isCaseDataApiCall = false;
                return data;
            }
        },

        getNodeDomain(node) {
            let nodeDomain;
            if (node.entity_identifier === "social_profile") {
                nodeDomain = node.platform;
            } else {
                try {
                    const url = new URL(node.url);
                    let hostname = url.hostname.replace("www.", "");
                    nodeDomain = hostname.replace(".com", "");
                } catch (error) {}
            }

            return nodeDomain;
        },

        extractDomains(caseData) {
            let domains = [];

            caseData.forEach((item) => {
                let itemDomain = this.getNodeDomain(item);
                if (itemDomain && !domains.includes(itemDomain)) {
                    domains.push(itemDomain);
                }
            });

            return domains;
        },
        getCaseNodeLabel(node) {
            let desc = "";

            if (node.graph_label) {
                desc = node.graph_label;
            } else {
                desc = node["entity"];
            }

            return desc;
        },
        getCaseCenterNodes(nodes) {
            let centerNodes = [];
            for (let node of nodes) {
                if (!node.show_on_graph && node.status !== "CONFIRMED" && node.entity_identifier && node.entity_identifier === "search") {
                    continue;
                }
                for (let atter of node.attributes) {
                    if (atter.attribute_id === this.getAppConfig.mainEntity && atter.value) {
                        centerNodes.push(this.getSubjectNode(node));
                    }
                }
            }
            return centerNodes;
        },

        getSubjectNode(node) {
            const centerNodeConfig = this.createCenterNode({name: node?.entity ?? "[BLANK SUBJECT]"}, "case-data");
            const nodeType = this.nodeKeyTypeMap[node.entity_identifier] ? this.nodeKeyTypeMap[node.entity_identifier] : node.entity_identifier;
            return {
                ...centerNodeConfig,
                margin: 20,
                color: {border: "#4220FB", font: "#fff", background: "#6E6EFD"},
                borderWidth: 2,
                type: nodeType,
                id: node._id,
                searchType: nodeType,
                isCaseData: true,
                source: ["caseData"],
            };
        },
        async addCasedata() {
            let caseNodes = [];
            let caseLinks = [];
            if (this.caseData.nodes.length > 0 && this.caseData.edges.length > 0) {
                caseNodes = this.caseData.nodes;
                caseLinks = this.caseData.edges;
            } else {
                let centerNodes = [];
                let {caseData, subject, connections} = await this.fetchCaseData(caseData);
                centerNodes = this.getCaseCenterNodes(caseData);
                if (subject) {
                    centerNodes.push(this.getSubjectNode(subject));
                }
                const centerNodeIds = centerNodes.map((n) => n.id);
                let dataNodesIds = caseData.filter((el) => !centerNodeIds.includes(el._id) && el.show_on_graph && el.status === "CONFIRMED" && el.entity_identifier && el.entity_identifier !== "search").map((el) => el._id);

                let nodesToKeep = new Set();
                let connectionsToKeep = [];
                for (let conn of connections) {
                    let connFound = false;
                    if (conn.show_on_graph) {
                        if (dataNodesIds.includes(conn.from)) {
                            nodesToKeep.add(conn.from);
                            connFound = true;
                        }
                        if (dataNodesIds.includes(conn.to)) {
                            nodesToKeep.add(conn.to);
                            connFound = true;
                        }
                    }
                    if (connFound) {
                        const id = uuidv4();
                        // delete conn._id
                        connectionsToKeep.push({id: id, from: conn.from, to: conn.to, title: "caseData"});
                    }
                }

                for (let node of caseData) {
                    if (node._id === "e1ba37fc-40fb-44d4-81ee-da1c6cd0de85") {
                    }
                    if (centerNodeIds.includes(node._id) || !nodesToKeep.has(node._id)) {
                        continue;
                    }

                    const nodeLabel = this.getCaseNodeLabel(node);
                    let nodeType = node.entity_identifier == "social_profile" ? "username" : node.entity_identifier;
                    if (this.nodeKeyTypeMap[nodeType]) {
                        nodeType = this.nodeKeyTypeMap[nodeType];
                    }
                    caseNodes.push({
                        label: nodeLabel,
                        id: node._id,
                        searchType: nodeType,
                        type: nodeType,
                        source: ["caseData"],
                        isCaseData: true,
                        nodeType: nodeType,
                        // url: item.url,
                    });
                }
                caseNodes = caseNodes.concat(centerNodes);
                caseLinks = connectionsToKeep;
            }
            this.pushToQueue({
                nodes: caseNodes,
                edges: caseLinks,
            });
        },
        removeCaseData(nodesPositions) {
            for (const [nodeId, position] of Object.entries(nodesPositions)) {
                let nodeObj = this.caseData.nodes.find((node) => node.id === nodeId);
                if (nodeObj) {
                    nodeObj.x = position.x;
                    nodeObj.y = position.y;
                }
            }
        },
        setTheCustomEmail(value) {
            this.selectedItem.email = {
                value: value,
            };
            // this.emailList.push(this.getTag(value));
            this.setSearchedUser(value, false, "email");
        },
        setTheCustomPhone(value) {
            this.selectedItem.phone = {
                value: value,
            };
            // this.phoneList.push(this.getTag(value));
            this.setSearchedUser(value, false, "phone");
        },
        setTheCustomUsername(value) {
            this.selectedItem.username = {
                value: value,
            };
            // this.usernameList.push(this.getTag(value));
            this.setSearchedUser(value, false, "username");
        },
        setTheCustomDomain(value) {
            this.selectedItem.domain = {
                value: value,
            };
            // this.domainList.push(this.getTag(value));
            this.setSearchedUser(value, false, "domain");
        },
        setTheCustomCompanyName(value) {
            this.selectedItem.company_name = {
                value: value,
            };
            // this.companyUsers.push(this.getTag(value));
            this.setSearchedUser(value, false, "company_name");
        },
        setTheCustomRegistrant(value) {
            this.selectedItem.registrant_name = {
                value: value,
            };
            // this.registrantUsers.push(this.getTag(value));
            this.setSearchedUser(value, false, "registrant");
        },

        validatePhone(keyword) {
            keyword = keyword.replaceAll(" ", "");
            keyword = keyword.substr(0, 1) + keyword.substr(1).replaceAll("+", "");
            keyword = keyword.replace(/-|_|/gi, "");
            keyword = keyword.replaceAll("(", "");
            keyword = keyword.replaceAll(")", "");
            const phoneRegex = /^[+]{1}[0-9-]/;
            return phoneRegex.test(keyword);
        },

        async handleGlobalAnalyzer(selectedTool) {
            EventBus.$emit("topprogress", "start");
            this.SET_OVERALL_TOOLS_STATUS(true);
            let reqGitlab,
                reqWhatsApp,
                reqEmailRep,
                reqGoogle,
                reqDark,
                reqDark2,
                reqDark3,
                reqDark4,
                // reqDark5,
                reqFlickr,
                reqGithub,
                reqLinkedIn,
                reqLinkedInDiscover,
                reqPI,
                reqSkype,
                reqTwitter,
                reqCompDomain,
                reqNewsTool,
                reqNewsTool2,
                reqFactivaTool,
                reqFactivaTool2,
                reqNameSearch,
                reqEmailSearch,
                reqCompanyName,
                reqCompanyName2,
                reqDomainSt,
                reqGithub2,
                reqSkype2,
                reqLinkedIn2,
                reqSkype3,
                reqDomainSt2,
                reqDomainSt3,
                reqGoogle2,
                reqDark5,
                reqLinkedIn3,
                reqSkype4,
                reqInsta,
                reqTiktok,
                reqUsernameTool,
                reqPepAdvSan,
                reqPepOffshoreLeaks,
                reqCompanyNameClari,
                reqCompanyNameWatch,
                reqCompanyNameBvd,
                reqCompanyNameOff,
                reqCompanyWatchOff,
                reqPepWorldCheck,
                reqPepDowJones,
                reqCompanyNameClariOff,
                reqPepAdvSanComp,
                reqPepWorldCheckComp,
                reqPepDowJonesComp,
                reqcourtRecords,
                reqFacebook,
                reqLexis,
                reqdnbcomp,
                reqPepOffshoreLeaksComp;
            let tools = [...this.toolOptions.children];
            if (selectedTool) {
                tools = tools.filter((n) => this.selectedToolsList.includes(n.activeProcess));
            }
            tools = tools.filter((ele) => this.getPermissions.find((ele1) => FEATURES["tools"][ele1] == ele.activeProcess));
            if (!this.selectedItem.phone.value || (this.selectedItem.phone.value && this.validatePhone(this.selectedItem.phone.value))) {
                for (const tool of tools) {
                    let header = {
                        "x-tool-name": tool.activeProcess,
                    };
                    switch (tool.activeProcess) {
                        case "whatsapp-tool":
                            if (this.selectedItem.phone.value) {
                                let phone = await splitCountryCode(this.selectedItem.phone.value);
                                reqWhatsApp = await whatsappServices.searchNumber(phone.number, phone.id, true);
                            }
                            break;
                        case "search-engine-tool":
                            let obj = {
                                emailInput: this.selectedItem.email?.value ?? "",
                                usernameInput: this.selectedItem.username?.value ?? "",
                                fNameInput: this.fNameInput,
                                mNameInput: this.mNameInput,
                                lNameInput: this.lNameInput,
                                nickNameInput: "",
                                locationInput: this.locationInput,
                                educationInput: this.educationInput,
                                employmentInput: this.employmentInput,
                                rolesInput: [],
                                hobbiesInput: [],
                                phoneInput: this.selectedItem.phone?.value ?? "",
                                companyInput: this.selectedItem.company_name?.value ?? "",
                                companyEmailInput: this.selectedItem.email?.value ?? "",
                                companyPhoneInput: this.selectedItem.phone?.value ?? "",
                                companyLocationInput: this.locationInput,
                                domainInput: "",
                                case_id: this.case_id,
                            };
                            sessionStorage.setItem("search-engine-inputs", JSON.stringify(obj));
                            if (this.currentToolSelected === "search-engine-tool") {
                                const {tools} = this.$refs;
                                tools.fNameInput = this.fNameInput;
                                tools.mNameInput = this.mNameInput;
                                tools.lNameInput = this.lNameInput;
                                tools.fullNameInput = this.fNameInput + " " + this.mNameInput + " " + this.lNameInput;
                                tools.nickNameInput = "";
                                tools.locationInput = this.locationInput;
                                tools.educationInput = this.educationInput;
                                tools.employmentInput = this.employmentInput;
                                tools.rolesInput = [];
                                tools.hobbiesInput = [];
                                tools.emailInput = this.selectedItem.email?.value ?? "";
                                tools.phoneInput = this.selectedItem.phone?.value ?? "";
                                tools.usernameInput = this.selectedItem.username?.value ?? "";
                                tools.companyInput = this.selectedItem.company_name?.value ?? "";
                                tools.domainInput = "";
                                tools.selectedItem = this.selectedItem;
                                tools.selectedItem.company = {value: this.selectedItem.company_name?.value ?? ""};
                                tools.selectedItem.email = {value: this.selectedItem.email?.value ?? ""};
                                tools.selectedItem.phone = {value: this.selectedItem.phone?.value ?? ""};
                                tools.selectedCompanyItem.email = {value: this.selectedItem.email?.value ?? ""};
                                tools.selectedCompanyItem.phone = {value: this.selectedItem.phone?.value ?? ""};
                                tools.companyLocationInput = this.locationInput;
                                tools.selectedItem.username = {value: this.selectedItem.username?.value ?? ""};
                                tools.selectedEntityId = "";
                                tools.tabChanged = true;
                                tools.currentStep = 0;
                                tools.runQuery = false;
                                tools.expandSection = true;
                                tools.selectedEntity = "person";
                            }
                            this.SET_BUILD_QUERIES(true);
                            if (this.selectedItem?.email) this.setSearchedUser(this.selectedItem?.email.value, true, "email");
                            if (this.selectedItem?.username) this.setSearchedUser(this.selectedItem?.username.value, true, "username");
                            if (this.selectedItem?.phone) this.setSearchedUser(this.selectedItem?.phone.value, true, "phone");
                            if (this.selectedItem?.company_name) this.setSearchedUser(this.selectedItem?.company_name.value, true, "company_name");
                            if (this.selectedItem?.location?.length) {
                                this.selectedItem.location.forEach((location) => {
                                    this.setSearchedUser(location.value, true, "location");
                                });
                            }
                            if (this.selectedItem?.employment?.length) {
                                this.selectedItem.employment.forEach((employment) => {
                                    this.setSearchedUser(employment.value, true, "employment");
                                });
                            }
                            if (this.selectedItem?.education?.length) {
                                this.selectedItem.education.forEach((education) => {
                                    this.setSearchedUser(education.value, true, "education");
                                });
                            }
                            break;
                        case "gitlab-tool":
                            if (this.selectedItem.email.value) {
                                reqGitlab = OsintAxios.post(
                                    `tools/gitlab/email`,
                                    {
                                        email: this.selectedItem.email.value.trim(),
                                    },
                                    {
                                        headers: header,
                                    }
                                );
                            }
                            break;
                        case "email-reputation-tool":
                            if (this.selectedItem.email.value) {
                                reqEmailRep = OsintAxios.post(
                                    `tools/reputation/email`,
                                    {
                                        email: this.selectedItem.email.value.trim(),
                                    },
                                    {
                                        headers: header,
                                    }
                                );
                            }
                            break;
                        case "google-tool":
                            if (this.selectedItem.email.value) {
                                let googleToolBody = {};
                                googleToolBody.email = this.selectedItem.email.value.trim();
                                reqGoogle = OsintAxios.post("/tools/google", googleToolBody, {
                                    headers: header,
                                });
                            }
                            if (this.selectedItem.phone.value) {
                                let googleToolBody = {};
                                googleToolBody.phone = this.selectedItem.phone.value;
                                reqGoogle2 = OsintAxios.post("/tools/google", googleToolBody, {
                                    headers: header,
                                });
                            }
                            break;
                        case "court-records-tool":
                            if (this.fNameInput || this.lNameInput || this.mNameInput) {
                                reqcourtRecords = OsintAxios.post(
                                    "/scraping/judyrecords",
                                    {search_query: `${this.fNameInput} ${this.mNameInput} ${this.lNameInput}`.replace(new RegExp("\\s{2,}", "gm"), " ")},
                                    {
                                        headers: header,
                                    }
                                );
                            }

                            break;
                        case "flickr-tool":
                            if (this.selectedItem.email.value) {
                                reqFlickr = OsintAxios.post(
                                    "/tools/flickr",
                                    {
                                        email: this.selectedItem.email.value.trim(),
                                    },
                                    {headers: header}
                                );
                            }
                            break;
                        case "dark-web-tool":
                            if (this.selectedItem.email.value) {
                                reqDark = OsintAxios.post(
                                    "/dark-web/search",
                                    {email: this.selectedItem.email.value.trim()},
                                    {
                                        headers: header,
                                    }
                                );
                            }
                            if (this.selectedItem.username.value) {
                                reqDark2 = OsintAxios.post(
                                    "/dark-web/search",
                                    {username: this.selectedItem.username.value},
                                    {
                                        headers: header,
                                    }
                                );
                            }
                            if (this.selectedItem.phone.value) {
                                reqDark3 = OsintAxios.post(
                                    "/dark-web/search",
                                    {phone: this.selectedItem.phone.value},
                                    {
                                        headers: header,
                                    }
                                );
                            }
                            if (this.fNameInput || this.lNameInput || this.mNameInput) {
                                reqDark4 = OsintAxios.post(
                                    "/dark-web/search",
                                    {name: `${this.fNameInput} ${this.mNameInput} ${this.lNameInput}`.replace(new RegExp("\\s{2,}", "gm"), " ")},
                                    {
                                        headers: header,
                                    }
                                );
                            }
                            if (this.selectedItem?.address?.value) {
                                reqDark5 = OsintAxios.post(
                                    "/dark-web/search",
                                    {name: `${this.fNameInput} ${this.mNameInput} ${this.lNameInput}`.replace(new RegExp("\\s{2,}", "gm"), " ")},
                                    {
                                        headers: header,
                                    }
                                );
                            }
                            // if (this.selectedItem.domain.value) {
                            //     reqDark5 = OsintAxios.post(
                            //         "/dark-web/search",
                            //         { domain: this.selectedItem.domain.value },
                            //         {
                            //             headers: header,
                            //         }
                            //     );
                            // }
                            break;
                        case "github-tool":
                            if (this.selectedItem.email.value) {
                                reqGithub = OsintAxios.post(
                                    "/tools/github/email",
                                    {
                                        email: this.selectedItem.email.value.trim(),
                                    },
                                    {headers: header}
                                );
                            }
                            if (this.selectedItem.username.value) {
                                reqGithub2 = OsintAxios.post(
                                    "/tools/github/username",
                                    {
                                        query: this.selectedItem.username.value,
                                    },
                                    {headers: header}
                                );
                            }
                            break;
                        case "linkedin-tool":
                            if (this.selectedItem.email.value) {
                                if (this.getPermissions.includes("linkedin__social_media")) {
                                    reqLinkedIn = OsintAxios.post(
                                        "/linkedin/search/email",
                                        {
                                            email: this.selectedItem.email.value.trim(),
                                        },
                                        {headers: header}
                                    );
                                }
                                if (this.getPermissions.includes("linkedin_discover__social_media")) {
                                    reqLinkedInDiscover = OsintAxios.post(
                                        "/intel/linkedin/discover",
                                        {
                                            discover_email: this.selectedItem.email.value,
                                            discovery: true,
                                        },
                                        {headers: header}
                                    );
                                }
                            }
                            // if (this.selectedItem.domain.value) {
                            //     reqLinkedIn2 = OsintAxios.post(
                            //         "/linkedin/search/domain",
                            //         {
                            //             domain: this.selectedItem.domain.value,
                            //             start: 1,
                            //             page_size: 10,
                            //         },
                            //         { headers: header }
                            //     );
                            // }
                            if ((this.fNameInput || this.lNameInput || this.mNameInput) && this.selectedItem.company_name.value) {
                                reqLinkedIn3 = OsintAxios.post(
                                    "/linkedin/search/name/company",
                                    {
                                        name: `${this.fNameInput} ${this.mNameInput} ${this.lNameInput}`.replace(new RegExp("\\s{2,}", "gm"), " "),
                                        company: this.selectedItem.company_name.value,
                                    },
                                    {headers: header}
                                );
                            }
                            break;
                        case "person-identifier-tool":
                            const piToolBody = {
                                name: [
                                    {
                                        first_name: this.fNameInput,
                                        middle_name: this.mNameInput,
                                        last_name: this.lNameInput,
                                        name_selected: this.fNameInput || this.mNameInput || this.lNameInput ? true : false,
                                    },
                                ],
                                age: null,
                                email: [
                                    {
                                        email: this.selectedItem?.email?.value || "",
                                    },
                                ],
                                phone: [
                                    {
                                        phone: this.selectedItem?.phone?.value || "",
                                    },
                                ],
                                social_details: [
                                    {
                                        social_profile: "",
                                        username: this.selectedItem?.username?.value || "",
                                        social_detail_selected: this.selectedItem?.username?.value ? true : false,
                                    },
                                ],
                                jobs: [
                                    {
                                        title: "",
                                        organization: "",
                                        industry: "",
                                        date_range: {
                                            start_date: "",
                                            end_date: "",
                                        },
                                        job_selected: false,
                                    },
                                ],
                                educations: [
                                    {
                                        degree: "",
                                        school: "",
                                        date_range: {
                                            start_date: "",
                                            end_date: "",
                                        },
                                        education_selected: false,
                                    },
                                ],
                                location: [
                                    {
                                        country: "",
                                        state: "",
                                        city: "",
                                        location_selected: false,
                                    },
                                ],
                                contact_selected: this.selectedItem?.email?.value || this.selectedItem?.phone?.value ? true : false,
                                gender_selected: false,
                                age_selected: false,
                                top_match: this.product_data.automated ? true : false,
                            };

                            const {data: sourcesCall} = await OsintAxios.post("/person-identifier/search-sources", piToolBody, {headers: header});

                            reqPI = OsintAxios.post(
                                "/person-identifier/adv",
                                {
                                    ...piToolBody,
                                    request_id: sourcesCall.request_id,
                                },
                                {headers: header}
                            );
                            break;
                        case "facebook-tool":
                            if (this.selectedItem.email.value || this.selectedItem.phone.value || this.fNameInput) {
                                let bodyData = {};
                                if (this.selectedItem.phone.value) {
                                    let phone = await splitCountryCode(this.selectedItem.phone.value);
                                    if (phone.number && phone.id) {
                                        bodyData = {
                                            id: phone.id,
                                            number: phone.number,
                                        };
                                    } else bodyData = {number: phone.number};
                                    reqFacebook = OsintAxios.post("/intel/facebook-search", bodyData, {headers: header});
                                }
                                if (this.selectedItem.email.value) {
                                    bodyData = {};
                                    bodyData.email = this.selectedItem.email.value;
                                    reqFacebook = OsintAxios.post("/intel/facebook-search", bodyData, {headers: header});
                                }
                                if (this.fNameInput) {
                                    bodyData = {};
                                    bodyData.fname = this.fNameInput;
                                    bodyData.lname = this.lNameInput ? this.lNameInput : "";
                                    reqFacebook = OsintAxios.post("/intel/facebook-search", bodyData, {headers: header});
                                }
                            }
                            break;
                        case "skype-tool":
                            if (this.selectedItem.email.value) {
                                reqSkype = OsintAxios.post(
                                    "/tools/skype",
                                    {
                                        email: this.selectedItem.email.value.trim(),
                                    },
                                    {headers: header}
                                );
                            }
                            if (this.selectedItem.username.value) {
                                reqSkype2 = OsintAxios.post(
                                    "/tools/skype",
                                    {
                                        username: this.selectedItem.username.value,
                                    },
                                    {headers: header}
                                );
                            }
                            if (this.selectedItem.phone.value) {
                                reqSkype3 = OsintAxios.post(
                                    "/tools/skype",
                                    {
                                        phone: this.selectedItem.phone.value,
                                    },
                                    {headers: header}
                                );
                            }
                            if (this.fNameInput || this.lNameInput || this.mNameInput) {
                                reqSkype4 = OsintAxios.post(
                                    "/tools/skype",
                                    {
                                        query: `${this.fNameInput} ${this.mNameInput} ${this.lNameInput}`.replace(new RegExp("\\s{2,}", "gm"), " "),
                                    },
                                    {headers: header}
                                );
                            }

                            break;
                        case "twitter-tool":
                            if (this.selectedItem.username.value) {
                                reqTwitter = OsintAxios.post(
                                    "/twitter/user",
                                    {
                                        username: this.selectedItem.username.value,
                                    },
                                    {headers: header}
                                );
                            }
                            break;
                        case "company-domain":
                            // if (this.selectedItem.domain.value) {
                            //     reqCompDomain = OsintAxios.post(
                            //         "/domain/registration",
                            //         {
                            //             domain: this.selectedItem.domain.value,
                            //         },
                            //         { headers: header }
                            //     );
                            // }
                            break;
                        case "news-tool":
                            if (this.fNameInput || this.lNameInput || this.mNameInput) {
                                const newsTool = await this.newsToolCall(`${this.fNameInput} ${this.mNameInput} ${this.lNameInput}`.replace(new RegExp("\\s{2,}", "gm"), " "), header);
                                reqNewsTool = newsTool.reqNewsTool;
                                reqFactivaTool = newsTool.reqFactivaTool;
                            }
                            if (this.selectedItem.company_name.value) {
                                const newsTool = await this.newsToolCall(this.selectedItem.company_name.value, header);
                                reqNewsTool2 = newsTool.reqNewsTool;
                                reqFactivaTool2 = newsTool.reqFactivaTool;
                            }
                            break;
                        case "name-search-tool":
                            if (this.fNameInput || this.lNameInput || this.mNameInput) {
                                reqNameSearch = OsintAxios.post(
                                    "/tools/query",
                                    {query: `${this.fNameInput} ${this.mNameInput} ${this.lNameInput}`.replace(new RegExp("\\s{2,}", "gm"), " ")},
                                    {
                                        headers: header,
                                    }
                                );
                            }
                            break;
                        case "email-search-tool":
                            if (this.selectedItem.email.value) {
                                reqEmailSearch = OsintAxios.post(
                                    "/tools/query",
                                    {query: this.selectedItem.email.value.trim()},
                                    {
                                        headers: header,
                                    }
                                );
                            }
                            break;
                        case "domain-search-tool":
                            if (this.selectedItem.email.value) {
                                reqDomainSt = OsintAxios.post(
                                    "/domain/lookup",
                                    {
                                        email: this.selectedItem.email.value.trim(),
                                    },
                                    {
                                        headers: header,
                                    }
                                );
                            }
                            if (this.fNameInput || this.lNameInput || this.mNameInput) {
                                reqDomainSt2 = OsintAxios.post(
                                    "/domain/lookup",
                                    {
                                        query: `${this.fNameInput} ${this.mNameInput} ${this.lNameInput}`.replace(new RegExp("\\s{2,}", "gm"), " "),
                                    },
                                    {
                                        headers: header,
                                    }
                                );
                            }
                            // if (this.selectedItem.domain.value) {
                            //     reqDomainSt3 = OsintAxios.post(
                            //         "/domain/registration",
                            //         {
                            //             domain: this.selectedItem.domain.value,
                            //         },
                            //         {
                            //             headers: header,
                            //         }
                            //     );
                            // }
                            break;
                        case "username-tool":
                            if (this.selectedItem.username.value) {
                                reqUsernameTool = OsintAxios.post(
                                    "/username/token",
                                    {
                                        username: this.selectedItem.username.value,
                                    },
                                    {
                                        headers: header,
                                    }
                                );
                            }
                            break;
                        case "company-name":
                            if (this.selectedItem.company_name.value) {
                                let obj = {
                                    name: this.selectedItem.company_name.value,
                                    sources: [],
                                    mode: "keyword",
                                    // ["opencorporates", "clarifiedby", "companywatch", "bvd"].sort((a, b) => a.localeCompare(b))
                                };
                                if (this.getPermissions.includes("bvd__corp_record")) {
                                    obj.sources.push("bvd");
                                }
                                if (this.getPermissions.includes("clarifedby__corp_record")) {
                                    obj.sources.push("clarifiedby");
                                }
                                if (this.getPermissions.includes("company_watch__corp_record")) {
                                    obj.sources.push("companywatch");
                                }
                                if (this.getPermissions.includes("open_corporates__corp_record")) {
                                    obj.sources.push("opencorporates");
                                }
                                // if (this.getPermissions.includes("dnb_reseller__corp_record")) {
                                //     obj.sources.push("db");
                                // }
                                if (this.getPermissions.includes("db__corp_record")) {
                                    obj.sources.push("db");
                                }
                                const {data: sourcesCall} = await OsintAxios.post("/company/search/sources", obj, {headers: header});
                                if (obj.sources.includes("opencorporates")) {
                                    reqCompanyName = OsintAxios.post("/company/search", {name: this.selectedItem.company_name.value, request_id: sourcesCall.request_id}, {headers: header});
                                }
                                if (obj.sources.includes("clarifiedby")) {
                                    reqCompanyNameClari = OsintAxios.post("/company/clarifiedby/search", {name: this.selectedItem.company_name.value, type: "organisations", request_id: sourcesCall.request_id}, {headers: header});
                                }
                                if (obj.sources.includes("companywatch")) {
                                    reqCompanyNameWatch = OsintAxios.post(
                                        "/company/company-watch/search",
                                        {
                                            name: this.selectedItem.company_name.value,
                                            mode: sourcesCall.mode,
                                            request_id: sourcesCall.request_id,
                                        },
                                        {headers: header}
                                    );
                                }

                                if (obj.sources.includes("db")) {
                                    reqdnbcomp = OsintAxios.post(
                                        "/db/type_head",
                                        {
                                            name: this.selectedItem.company_name.value,
                                            request_id: sourcesCall.request_id,
                                        },
                                        {headers: header}
                                    );
                                }
                                if (obj.sources.includes("bvd")) {
                                    reqCompanyNameBvd = OsintAxios.post("/company/bvd/search", {name: this.selectedItem.company_name.value, request_id: sourcesCall.request_id}, {headers: header});
                                }
                            }
                            if (this.fNameInput || this.lNameInput || this.mNameInput) {
                                let obj = {
                                    officer: `${this.fNameInput} ${this.mNameInput} ${this.lNameInput}`.replace(new RegExp("\\s{2,}", "gm"), " "),
                                    sources: [],
                                    mode: "keyword",
                                    // ["opencorporates", "clarifiedby", "companywatch", "bvd"].sort((a, b) => a.localeCompare(b))
                                };
                                if (this.getPermissions.includes("bvd__corp_record")) {
                                    obj.sources.push("bvd");
                                }
                                if (this.getPermissions.includes("clarifedby__corp_record")) {
                                    obj.sources.push("clarifiedby");
                                }
                                if (this.getPermissions.includes("company_watch__corp_record")) {
                                    obj.sources.push("companywatch");
                                }
                                if (this.getPermissions.includes("open_corporates__corp_record")) {
                                    obj.sources.push("opencorporates");
                                }
                                // reqCompanyName2 = OsintAxios.post("/company/search/person", obj, { headers: header });
                                const {data: sourcesCall} = await OsintAxios.post("/company/search/sources", obj, {headers: header});
                                if (obj.sources.includes("opencorporates")) {
                                    reqCompanyNameOff = OsintAxios.post("/company/search/person", {officer: `${this.fNameInput} ${this.mNameInput} ${this.lNameInput}`.replace(new RegExp("\\s{2,}", "gm"), " "), request_id: sourcesCall.request_id}, {headers: header});
                                }
                                if (obj.sources.includes("companywatch")) {
                                    reqCompanyWatchOff = OsintAxios.post(
                                        "/company/company-watch/person",
                                        {
                                            officer: `${this.fNameInput} ${this.mNameInput} ${this.lNameInput}`.replace(new RegExp("\\s{2,}", "gm"), " "),
                                            mode: sourcesCall.mode,
                                            request_id: sourcesCall.request_id,
                                        },
                                        {
                                            headers: header,
                                        }
                                    );
                                }
                                if (obj.sources.includes("clarifiedby")) {
                                    reqCompanyNameClariOff = OsintAxios.post(
                                        "/company/clarifiedby/search",
                                        {name: `${this.fNameInput} ${this.mNameInput} ${this.lNameInput}`.replace(new RegExp("\\s{2,}", "gm"), " "), request_id: sourcesCall.request_id, type: "individuals"},
                                        {
                                            headers: header,
                                        }
                                    );
                                }
                                if (obj.sources.includes("bvd")) {
                                    reqCompanyNameClariOff = OsintAxios.post(
                                        "/company/bvd/person",
                                        {name: `${this.fNameInput} ${this.mNameInput} ${this.lNameInput}`.replace(new RegExp("\\s{2,}", "gm"), " "), request_id: sourcesCall.request_id},
                                        {
                                            headers: header,
                                        }
                                    );
                                }
                            }
                            break;
                        case "instagram-tool":
                            if (this.selectedItem.username.value) {
                                reqInsta = OsintAxios.post("/instagram/user", {username: this.selectedItem.username.value}, {headers: header});
                            }
                            break;
                        case "tiktok-tool":
                            if (this.selectedItem.username.value) {
                                reqTiktok = OsintAxios.post("/tiktok/user", {username: this.selectedItem.username.value}, {headers: header});
                            }
                            break;
                        case "offshoreleaks-tool":
                            if (this.fNameInput || this.lNameInput || this.mNameInput) {
                                const mediaTool = await this.offshoreLeaksCall(`${this.fNameInput} ${this.mNameInput} ${this.lNameInput}`.replace(new RegExp("\\s{2,}", "gm"), " "), header);
                                reqPepOffshoreLeaks = mediaTool.reqPepOffshoreLeaks;
                            }
                            if (this.selectedItem?.company_name?.value) {
                                const mediaTool = await this.offshoreLeaksCall(this.selectedItem.company_name.value, header);
                                reqPepOffshoreLeaksComp = mediaTool.reqPepOffshoreLeaks;
                            }
                            break;
                        case "adverse-media-tool":
                            if (this.fNameInput || this.lNameInput || this.mNameInput) {
                                const mediaTool = await this.adverseToolCall(`${this.fNameInput} ${this.mNameInput} ${this.lNameInput}`.replace(new RegExp("\\s{2,}", "gm"), " "), header);
                                reqPepAdvSan = mediaTool.reqPepAdvSan;
                                reqPepWorldCheck = mediaTool.reqPepWorldCheck;
                                reqPepDowJones = mediaTool.reqPepDowJones;
                                reqPepOffshoreLeaks = mediaTool.reqPepOffshoreLeaks;
                                // reqLexis = mediaTool.reqLexis;
                            }
                            if (this.selectedItem?.company_name?.value) {
                                const mediaTool = await this.adverseToolCall(this.selectedItem.company_name.value, header);
                                reqPepAdvSanComp = mediaTool.reqPepAdvSan;
                                reqPepWorldCheckComp = mediaTool.reqPepWorldCheck;
                                reqPepDowJonesComp = mediaTool.reqPepDowJones;
                                reqPepOffshoreLeaksComp = mediaTool.reqPepOffshoreLeaks;
                                // reqLexis = mediaTool.reqLexis;
                            }
                            break;
                        case "lexis-bridger-tool":
                            if (this.fNameInput || this.lNameInput || this.mNameInput) {
                                const mediaTool = await this.lexisBridgerCall(`${this.fNameInput} ${this.mNameInput} ${this.lNameInput}`.replace(new RegExp("\\s{2,}", "gm"), " "), header, "Individual");
                                // reqPepAdvSan = mediaTool.reqPepAdvSan;
                                // reqPepWorldCheck = mediaTool.reqPepWorldCheck;
                                // reqPepDowJones = mediaTool.reqPepDowJones;
                                // reqPepOffshoreLeaks = mediaTool.reqPepOffshoreLeaks;
                                reqLexis = mediaTool.reqLexis;
                            }
                            if (this.selectedItem?.company_name?.value) {
                                const mediaTool = await this.lexisBridgerCall(this.selectedItem.company_name.value, header, "Business");
                                // reqPepAdvSanComp = mediaTool.reqPepAdvSan;
                                // reqPepWorldCheckComp = mediaTool.reqPepWorldCheck;
                                // reqPepDowJonesComp = mediaTool.reqPepDowJones;
                                // reqPepOffshoreLeaksComp = mediaTool.reqPepOffshoreLeaks;
                                reqLexis = mediaTool.reqLexis;
                            }
                            break;
                        case "risk-tool":
                            if (this.fNameInput || this.lNameInput || this.mNameInput) {
                                let sourcesCallBody = {
                                    name: `${this.fNameInput} ${this.mNameInput} ${this.lNameInput}`.replace(new RegExp("\\s{2,}", "gm"), " "),
                                    sources: ["complyadvantage", "dowjones", "worldcheck", "lexis"],
                                    entity_type: "",
                                    year_of_birth: "",
                                    fuzziness: 0.7,
                                    country: [],
                                    checks: ["peps", "sanctions", "adverse"],
                                    page: 1,
                                    limit: 100,
                                };
                                const {data: sourcesCall} = await OsintAxios.post("/adverse-media/search/sources", sourcesCallBody, {headers: header});
                                reqPepAdvSan = OsintAxios.post("/adverse-media/search", {name: `${this.fNameInput} ${this.mNameInput} ${this.lNameInput}`.replace(new RegExp("\\s{2,}", "gm"), " "), fuzziness: 0.5, entity_type: "", checks: ["peps", "sanctions", "adverse"], sources: ["complyadvantage"]}, {headers: header});
                                reqPepWorldCheck = OsintAxios.post(
                                    "/adverse-media/worldcheck",
                                    {name: sourcesCallBody.name, request_id: sourcesCall.request_id, limit: 100, page: 1, entity_type: ""},
                                    {
                                        headers: header,
                                    }
                                );

                                reqPepDowJones = OsintAxios.post(
                                    "/adverse-media/search/dj-search",
                                    {
                                        name: sourcesCallBody.name,
                                        request_id: sourcesCall.request_id,
                                        limit: 100,
                                        page: 1,
                                        sources: sourcesCall.djSources,
                                        entity_type: "",
                                    },
                                    {headers: header}
                                );
                            }
                            break;
                        default:
                            break;
                    }
                }

                Promise.all([
                    reqGitlab,
                    reqEmailRep,
                    reqGoogle,
                    reqWhatsApp,
                    reqDark,
                    reqDark2,
                    reqDark3,
                    reqDark4,
                    reqFlickr,
                    reqGithub,
                    reqLinkedIn,
                    reqLinkedInDiscover,
                    reqPI,
                    reqSkype,
                    reqTwitter,
                    reqCompDomain,
                    reqNewsTool,
                    reqNewsTool2,
                    reqNameSearch,
                    reqEmailSearch,
                    reqCompanyName,
                    reqCompanyName2,
                    reqDomainSt,
                    reqGithub2,
                    reqSkype2,
                    reqLinkedIn2,
                    reqSkype3,
                    reqDomainSt2,
                    reqDomainSt3,
                    reqGoogle2,
                    reqDark5,
                    reqLinkedIn3,
                    reqSkype4,
                    reqInsta,
                    reqTiktok,
                    reqUsernameTool,
                    reqPepAdvSan,
                    reqPepOffshoreLeaks,
                    reqCompanyNameClari,
                    reqCompanyNameWatch,
                    reqCompanyNameBvd,
                    reqCompanyNameOff,
                    reqCompanyWatchOff,
                    reqPepWorldCheck,
                    reqPepDowJones,
                    reqFactivaTool,
                    reqFactivaTool2,
                    reqCompanyNameClariOff,
                    reqPepAdvSanComp,
                    reqPepWorldCheckComp,
                    reqPepDowJonesComp,
                    reqPepOffshoreLeaksComp,
                    reqFacebook,
                    reqLexis,
                    reqdnbcomp,
                ]).then(() => {
                    EventBus.$emit("topprogress", "done");
                    this.toggleAnalyzer = false;
                    this.getToolsStatus();
                });
            } else if (this.selectedItem.phone.value && !this.validatePhone(this.selectedItem.phone.value)) {
                this.$toast.error("Please provide a valid phone number with country code");
            }
        },
        async lexisBridgerCall(sourceData, header, entity_type) {
            // `${this.fNameInput} ${this.mNameInput} ${this.lNameInput}`.replace(new RegExp("\\s{2,}", "gm"), " ")
            let reqLexis;
            let sourcesCallBody = {
                sources: [],
                entity_type,
                page: 1,
                limit: 100,
            };
            if (entity_type === "Individual") {
                const names = sourceData?.trim()?.split(" ");
                if (names.length === 1) return;
                if (names.length === 2)
                    sourcesCallBody.person_entity = {
                        first_name: names?.[0] || "",
                        last_name: names?.[1] || "",
                    };
                else {
                    const list = cloneDeep(names);
                    list.splice(0, 1);
                    list.splice(list.length - 1, 1);
                    sourcesCallBody.person_entity = {
                        first_name: names?.[0] || "",
                        middle_name: list.join(" ") || "",
                        last_name: names?.length > 1 ? names?.[names.length - 1] || "" : "",
                    };
                }
            } else
                sourcesCallBody.company_entity = {
                    name: sourceData,
                };

            if (this.getPermissions.includes("lexis_bridger__adverse_pep/sacntion") || this.getPermissions.includes("lexis_bridger__adverse_pep_sacntion")) sourcesCallBody.sources.push("lexis");
            sourcesCallBody.sources = sourcesCallBody.sources.sort((a, b) => a.localeCompare(b));
            const {data: sourcesCall} = await OsintAxios.post("/lexis_bridger/search_sources", sourcesCallBody, {headers: header});

            if (sourcesCallBody.sources.includes("lexis")) {
                reqLexis = OsintAxios.post(
                    "lexis_bridger/search_entity?cache_bypass=false",
                    {
                        ...sourcesCallBody,
                        ...{
                            additional_details: {
                                query_id: sourcesCall.request_id,
                            },
                        },
                    },
                    {
                        headers: header,
                    }
                );
            }
            return {reqLexis};
        },
        async offshoreLeaksCall(sourceData, header) {
            // `${this.fNameInput} ${this.mNameInput} ${this.lNameInput}`.replace(new RegExp("\\s{2,}", "gm"), " ")
            let reqPepOffshoreLeaks;
            let sourcesCallBody = {
                name: sourceData,
                sources: [],
                search_mode: "exact_match",
                entity_type: "",
                country: [],
                page: 1,
                limit: 100,
            };

            // if (this.getPermissions.includes("offshore_leaks__adverse_pep/sacntion") || this.getPermissions.includes("offshore_leaks__adverse_pep_sacntion")) {
            sourcesCallBody.sources.push("offshoreleaks");
            // }
            sourcesCallBody.sources = sourcesCallBody.sources.sort((a, b) => a.localeCompare(b));
            const {data: sourcesCall} = await OsintAxios.post("/adverse-media/search/sources", sourcesCallBody, {headers: header});

            if (sourcesCallBody.sources.includes("offshoreleaks")) {
                reqPepOffshoreLeaks = OsintAxios.post(
                    "/adverse-media/icij-search",
                    {
                        name: sourcesCallBody.name,
                        request_id: sourcesCall.request_id,
                        limit: 100,
                        offset: 1,
                        entity_type: [],
                        search_mode: sourcesCallBody.search_mode,
                    },
                    {
                        headers: header,
                    }
                );
            }
            return {reqPepOffshoreLeaks};
        },
        async adverseToolCall(sourceData, header) {
            // `${this.fNameInput} ${this.mNameInput} ${this.lNameInput}`.replace(new RegExp("\\s{2,}", "gm"), " ")
            let reqPepAdvSan, reqPepWorldCheck, reqPepDowJones, reqPepOffshoreLeaks;
            let sourcesCallBody = {
                name: sourceData,
                sources: [],
                search_mode: "exact_match",
                // ["complyadvantage", "dowjones", "worldcheck"],
                entity_type: "",
                year_of_birth: "",
                fuzziness: 0.7,
                country: [],
                checks: ["peps", "sanctions", "adverse"].sort((a, b) => a.localeCompare(b)),
                page: 1,
                limit: 100,
            };
            if (this.getPermissions.includes("comply_advantage__adverse_pep/sacntion") || this.getPermissions.includes("comply_advantage__adverse_pep_sacntion")) {
                sourcesCallBody.sources.push("complyadvantage");
            }
            if (this.getPermissions.includes("dow_jones__adverse_pep/sacntion") || this.getPermissions.includes("dow_jones__adverse_pep_sacntion")) {
                // sourcesCallBody.sources.push("soe", "ame", "wl");
                sourcesCallBody.sources.push("soe", "wl");
            }
            if (this.getPermissions.includes("world_check__adverse_pep/sacntion") || this.getPermissions.includes("world_check__adverse_pep_sacntion")) {
                sourcesCallBody.sources.push("worldcheck");
            }
            // if (this.getPermissions.includes("lexis_bridger__adverse_pep_sacntion") || this.getPermissions.includes("lexis_bridger__adverse_pep_sacntion")) {
            //     sourcesCallBody.sources.push("lexis");
            // }
            // if (this.getPermissions.includes("offshore_leaks__adverse_pep/sacntion") || this.getPermissions.includes("offshore_leaks__adverse_pep_sacntion")) {
            // sourcesCallBody.sources.push("offshoreleaks");
            // }
            sourcesCallBody.sources = sourcesCallBody.sources.sort((a, b) => a.localeCompare(b));
            const {data: sourcesCall} = await OsintAxios.post("/adverse-media/search/sources", sourcesCallBody, {headers: header});
            if (sourcesCallBody.sources.includes("complyadvantage")) {
                reqPepAdvSan = OsintAxios.post("/adverse-media/search", {name: sourceData, fuzziness: 0.7, entity_type: "", checks: ["peps", "sanctions", "adverse"], sources: ["complyadvantage"], request_id: sourcesCall.request_id}, {headers: header});
            }
            if (sourcesCallBody.sources.includes("worldcheck")) {
                reqPepWorldCheck = OsintAxios.post(
                    "/adverse-media/worldcheck",
                    {name: sourcesCallBody.name, request_id: sourcesCall.request_id, limit: 100, page: 1, entity_type: "", search_mode: sourcesCallBody.search_mode},
                    {
                        headers: header,
                    }
                );
            }
            if (sourcesCallBody.sources.includes("soe") || sourcesCallBody.sources.includes("ame") || sourcesCallBody.sources.includes("wl")) {
                reqPepDowJones = OsintAxios.post(
                    "/adverse-media/search/dj-search",
                    {
                        name: sourcesCallBody.name,
                        request_id: sourcesCall.request_id,
                        limit: 100,
                        page: 1,
                        entity_type: "",
                        sources: sourcesCallBody.sources.filter((src) => src != "complyadvantage" && src != "worldcheck" && src != "offshoreleaks"),
                    },
                    {headers: header}
                );
            }
            if (sourcesCallBody.sources.includes("lexis")) {
                let payload = {};
                if (source === "name") {
                    payload = {
                        first_name: value,
                        entity_type: "Individual",
                        request_id: sourcesCall.request_id,
                    };
                } else if (source === "company_name") {
                    payload = {
                        name: value,
                        entity_type: "Business",
                        request_id: sourcesCall.request_id,
                    };
                }
            }
            if (sourcesCallBody.sources.includes("offshoreleaks")) {
                reqPepOffshoreLeaks = OsintAxios.post(
                    "/adverse-media/icij-search",
                    {
                        name: sourcesCallBody.name,
                        request_id: sourcesCall.request_id,
                        limit: 100,
                        offset: 1,
                        entity_type: [],
                        search_mode: sourcesCallBody.search_mode,
                    },
                    {
                        headers: header,
                    }
                );
            }
            return {reqPepAdvSan, reqPepWorldCheck, reqPepDowJones, reqPepOffshoreLeaks};
        },
        async newsToolCall(sourceData, header) {
            // `${this.fNameInput} ${this.mNameInput} ${this.lNameInput}`.replace(new RegExp("\\s{2,}", "gm"), " ")
            let reqNewsTool, reqFactivaTool;
            const obj = {query: sourceData, from_date: null, to_date: null, language: "en", page: 1, limit: 10, news_sources: [], sources: []};
            if (this.getPermissions.includes("factiva__news")) {
                obj.sources.push("dowjonesfactiva");
            }
            if (this.getPermissions.includes("news_api__news_")) {
                obj.sources.push("news");
            }
            const {data: sourcesCall} = await OsintAxios.post("/news/search/sources", obj, {headers: header});
            if (obj.sources.includes("news")) {
                reqNewsTool = OsintAxios.post(
                    "/news/search",
                    {
                        query: sourceData,
                        from_date: null,
                        to_date: null,
                        language: "en",
                        page: 1,
                        limit: 10,
                        request_id: sourcesCall.request_id,
                    },
                    {headers: header}
                );
            }
            if (obj.sources.includes("dowjonesfactiva")) {
                reqFactivaTool = OsintAxios.post("/news/dowjones-headlines-search", {query: sourceData, page: 1, limit: 10, from_date: null, to_date: null, language: ["en"], news_sources: [], request_id: sourcesCall.request_id}, {headers: header});
            }
            return {reqNewsTool, reqFactivaTool};
        },
        handleClear() {
            this.locationInput = [];
            this.educationInput = [];
            this.employmentInput = [];
            this.selectedItem = {
                email: "",
                username: "",
                phone: "",
                // domain: "",
                company_name: "",
                // registrant_name: "",
                location: [],
                education: [],
                employment: [],
            };
            this.fNameInput = "";
            this.mNameInput = "";
            this.lNameInput = "";
            this.selectedToolsList = [];
            if (this.$refs["analysis-tool-input"] && this.$refs["analysis-tool-input"][0]) {
                this.$refs["analysis-tool-input"][0].search = "";
                this.$refs["analysis-tool-input"][0].value = "";
                this.$refs["analysis-tool-input"][0].selectedItem = null;
            }
            this.$refs["analysis-tool-input-email"].search = "";
            this.$refs["analysis-tool-input-username"].search = "";
            this.$refs["analysis-tool-input-phone"].search = "";
            this.$refs["analysis-tool-input-company_name"].search = "";
        },
        handleChangeGlobal(value) {
            let index = this.selectedToolsList.findIndex((n) => n == value);
            if (index !== -1) {
                this.selectedToolsList.splice(index, 1);
            } else this.selectedToolsList.push(value);
        },
        limitText(length) {
            // this.$emit('limitText', length);
            return ` +${length}`;
        },

        setLocationInput(option) {
            let tag = this.getTag(option.value);
            this.locationInput.push(tag);
            // this.setSearchedUser(option.value, "location");
        },

        setEmploymentInput(option) {
            let tag = this.getTag(option.value);
            this.employmentInput.push(tag);
            // this.setSearchedUser(option.value, "employment");
        },

        setEducationInput(option) {
            let tag = this.getTag(option.value);
            this.educationInput.push(tag);
            // this.setSearchedUser(option.value, "education");
        },
        async setSearchedUser(searchKeyword, visited, type, val_type) {
            const data = {
                value: searchKeyword,
                platform: "search-engine",
                val_type: val_type || type,
                visited,
            };
            let url = `research-methodology/${this.case_id}/tools/profiles`;
            let isSuccess = await setUsernamesDropdownData(url, data);

            let listName = type !== "company_name" ? `${type}List` : "companyUsers";
            // if (isSuccess) {
            let dataItem = this[listName].find((dataItem) => {
                return dataItem.value === searchKeyword;
            });
            if (dataItem) dataItem["visited"] = true;
            else {
                this[listName].push(data);
                this.addCustomData(data);
                this.updateData(data.platform, [], data, true);
            }
            if (visited) this.handleChecked(data);
            // }
        },
        getTag(tag) {
            return {
                name: tag,
                value: tag,
                code: tag.substring(0, 2) + Math.floor(Math.random() * 10000000),
            };
        },
        addLocationTag(newTag) {
            const tag = this.getTag(newTag);
            let match = -1;
            match = this.locationInput.findIndex((item) => item.value === newTag);
            if (match === -1) {
                this.locationInput.push(tag);
                this.selectedItem["location"].push(tag);
                this.setSearchedUser(newTag, false, "location");
                // this.locationList.push(tag)
            }
        },
        addEducationTag(newTag) {
            const tag = this.getTag(newTag);
            let match = -1;
            match = this.educationInput.findIndex((item) => item.value === newTag);
            if (match === -1) {
                this.educationInput.push(tag);
                this.selectedItem["education"].push(tag);
                this.setSearchedUser(newTag, false, "education");
                // this.educationList.push(tag)
            }
        },
        addEmploymentTag(newTag) {
            const tag = this.getTag(newTag);
            let match = -1;
            match = this.employmentInput.findIndex((item) => item.value === newTag);
            if (match === -1) {
                this.employmentInput.push(tag);
                this.selectedItem["employment"].push(tag);
                this.setSearchedUser(newTag, false, "employment");
                // this.employmentList.push(tag)
            }
        },
        removeLocationTag(removedOption) {
            let match = -1;
            match = this.locationInput.findIndex((item) => {
                return item.value === removedOption.value;
            });
            if (match > -1) {
                this.locationInput.splice(match, 1);
                // this.selectedItem["location"].splice(match, 1);
            }
        },
        removeEmploymentTag(removedOption) {
            let match = -1;
            match = this.employmentInput.findIndex((item) => {
                return item.value === removedOption.value;
            });
            if (match > -1) {
                this.employmentInput.splice(match, 1);
                // this.selectedItem["employment"].splice(match, 1);
            }
        },
        removeEducationTag(removedOption) {
            let match = -1;
            match = this.educationInput.findIndex((item) => {
                return item.value === removedOption.value;
            });
            if (match > -1) {
                this.educationInput.splice(match, 1);
                // this.selectedItem["education"].splice(match, 1);
            }
        },
        onSelect(event, type) {
            this.$set(this.selectVal, type, event.value);
            this.$refs["analysis-tool-input-" + type].search = event.value;
        },

        close(type) {
            this.selectedItem[type] = {
                value: this.selectVal[type],
            };
            this.$refs["analysis-tool-input-" + type].search = this.selectVal[type];
        },
        handleChange(event, type) {
            this.$set(this.selectVal, type, event);
        },

        handleCategoryFilter(tools) {
            this.toolsCategories = tools;
        },
        getMonitoringSources() {
            let sources = [];
            const {toolName} = this.$route.params;
            if (toolName === "adverse-media-tool" || toolName === "company-name" || toolName === "news-tool" || toolName === "offshoreleaks-tool") {
                if (this.selectedToolQuery?.query?.sources) {
                    sources = this.selectedToolQuery.query.sources;
                }
            } else {
                let tool_name = toolName;
                if (tool_name) sources = [tool_name];
            }
            return sources;
        },
        updateMonitoringCascadingTimeline(data) {
            // const data = timeline.concat(timeline).concat(timeline).concat(timeline).concat(timeline)
            // .concat(timeline).concat(timeline).concat(timeline).concat(timeline);
            // Object.assign(this.dateFilters, {
            //     data: data || [],
            // });
            if (data.newData) {
                let timeline = this.dateFilters.data.timeline;
                const match = timeline.findIndex((el) => el.api_all_data.doc_id === data.newData.api_all_data.doc_id);
                timeline[match] = data.newData;
                Object.assign(this.dateFilters, {
                    data: {
                        timeline: timeline,
                        meta_data: this.dateFilters.data.meta_data,
                    },
                });
            } else {
                Object.assign(this.dateFilters, {
                    data: data || {
                        timeline: [],
                        meta_data: null,
                    },
                });
            }
        },
        async setMonitoringDateFilter(dt, changeRange = false) {
            // let sources = this.getMonitoringSources();
            // const query_id = this.getSelectedToolQuery && this.getSelectedToolQuery.query_id;
            const startDt = new Date(dt[0]);
            startDt.setHours(0);
            startDt.setMinutes(0);
            startDt.setSeconds(0);
            startDt.setMilliseconds(0);
            let today = new Date();
            const endDt = new Date(dt[1]);
            endDt.setHours(today.getHours());
            endDt.setMinutes(today.getMinutes());
            endDt.setSeconds(today.getSeconds());
            // let startDate = DateTime.fromMillis(Date.parse(startDt));
            // startDate = startDate.toFormat("yyyy-MM-dd'T'hh:mm:ss.SSS'Z'");
            // let endDate = DateTime.fromMillis(Date.parse(endDt));
            // endDate = endDate.toFormat("yyyy-MM-dd'T'hh:mm:ss.SSS'Z'");
            // if (query_id && sources.length > 0) {
            //     let body = {
            //         query_id: query_id,
            //         sources: sources.map((src) => src?.toLowerCase()),
            //         start_date: startDate,
            //         end_date: endDate,
            //     };

            //     try {
            //         let resp = await axios.post(`osint-tools/query/date-range`, body);
            //         if (resp && resp.status && !changeRange) {
            //             Object.assign(this.dateFilters, {
            //                 data: resp.data.data.length ? resp.data.data : [],
            //             });
            //         }
            //     } catch (error) {
            //         console.info("ERROR!!! ", error);
            //     }
            // }

            this.setMOnitoringDateObj(startDt, endDt);
            if (!changeRange) {
                Object.assign(this.dateFilters, {
                    minDate: startDt,
                    maxDate: endDt,
                });
                this.$forceUpdate();
                // if (this.selectedToolQuery && this.selectedToolQuery?._id) await this.handleSearchQueryClicked(this.selectedToolQuery, true);
            }
        },

        setMOnitoringDateObj(startDt, endDt) {
            let monitData = this.getMonitoringDateFilter;
            monitData.startDate = startDt;
            monitData.endDate = endDt;
            this.SET_MONITORING_DATE_FILTERS({});
            this.SET_MONITORING_DATE_FILTERS(monitData);
        },

        async handleDateRange(dt) {
            let startDt = new Date(dt.from);
            let endDt = new Date(dt.to);
            this.setMOnitoringDateObj(startDt, endDt);
            if (this.currentToolSelected !== "manual-monitoring") {
                let query = null;
                if (this.getSelectedToolQuery?.query?._id) query = this.getSelectedToolQuery.query;
                if (this.selectedToolQuery?._id) query = this.selectedToolQuery;
                if (query?._id) await this.handleSearchQueryClicked(query, true);
                //await this.getNewMonitoringEvents();
                if (this.currentToolSelected) await this.getQueryMonitoringEvents(this.currentToolSelected);
            }
        },
        async setDataType(val) {
            let monitData = this.getMonitoringDateFilter;
            monitData.dataType = val.value;
            this.SET_MONITORING_DATE_FILTERS({});
            this.SET_MONITORING_DATE_FILTERS(monitData);
            let query = null;
            if (this.getSelectedToolQuery?.query?._id) query = this.getSelectedToolQuery.query;
            if (this.selectedToolQuery?._id) query = this.selectedToolQuery;
            if (query?._id) await this.handleSearchQueryClicked(query, true);
        },
        async fetchToolsUsers() {
            const {data} = await axios.get(`/audit-log/query-users/${this.case_id}`);
            if (data?.data?.length > 0) this.toolsUsers = this.getAllUsers.filter((user) => data.data.some((u_id) => user.id === u_id.user_id));
        },

        // updateToolFiltersCount(filtersCount) {
        //     this.toolFiltersCount = filtersCount;
        // },
        handleCollapsed() {
            this.collapsed = !this.collapsed;
            if (this.collapsed) {
                document.getElementById("components").style.paddingBottom = 0;
            } else {
                document.getElementById("components").removeAttribute("style");
            }
        },

        handleNer() {},

        handleNerClear() {
            this.$refs["global-ner-analyser"]?.handleNerClear();
            this.SET_NER_FILTERS();
        },

        async applyNerFilters(filters) {
            this.SET_NER_FILTERS(filters);
            await this.handleRefreshQueries();
            this.$forceUpdate();
            if (this.selectedToolQuery && this.selectedToolQuery?._id) {
                let match = -1;
                match = this.fetchSearchedQueries?.findIndex((el) => el._id === this.selectedToolQuery?._id);
                if (match > -1) await this.handleSearchQueryClicked(this.selectedToolQuery);
            }
        },

        async onRunQueries(params) {
            const {tools} = this.$refs;
            tools.offsetPag = 0;
            tools.limitPag = 1;
            tools.allQueriesParams = null;
            tools.isNextPag = true;
            this.$router.push({path: this.$route.path}).catch((err) => {
                console.log(err, "ERROR");
            });
            switch (this.currentToolSelected) {
                case "news-tool":
                    tools.resetFilter();
                    tools.handleCloseMoreInfo();
                    tools.allNews = [];
                    tools.totalResultSize = 0;
                    tools.currentPage = 1;

                    await tools.onRunAllQueries(params, "allNews");
                    if (tools.totalRecordsInAllQueries > 100 && tools.allNews.length <= tools.totalRecordsInAllQueries) await tools.onRunAllQueries(params, "allNews");
                    break;
                case "adverse-media-tool":
                    tools.resetFilter();
                    tools.monitoringStatus = false;
                    tools.page_number = 1;
                    tools.currentQuery = -1;
                    tools.closeExpandedResult();
                    tools.adverseFilteredResults = [];
                    tools.isAllRunQueries = false;
                    tools.isResultExpanded = false;
                    tools.adverseResults = [];

                    await tools.onRunAllQueries(params, "adverseResults");
                    break;

                case "offshoreleaks-tool":
                    tools.resetFilter();
                    tools.monitoringStatus = false;
                    tools.page_number = 1;
                    tools.currentQuery = -1;
                    tools.closeExpandedResult();
                    tools.adverseFilteredResults = [];
                    tools.isAllRunQueries = false;
                    tools.isResultExpanded = false;
                    tools.adverseResults = [];

                    await tools.onRunAllQueries(params, "adverseResults");
                    break;

                case "lexis-bridger-tool":
                    tools.resetFilter();
                    tools.monitoringStatus = false;
                    tools.page_number = 1;
                    tools.closeExpandedResult();
                    tools.adverseFilteredResults = [];
                    tools.isAllRunQueries = false;
                    tools.isResultExpanded = false;
                    tools.adverseResults = [];

                    await tools.onRunAllQueries(params, "adverseResults");
                    break;
            }
        },

        // for Court Record Tool -> unicourt
        getDisplayDropDownName(value) {
            return value.charAt(0).toUpperCase() + value.slice(1);
        },

        async getNewGraph() {
            this.graph.graphLoading = true;
            const result = await getGraphData({node_type: [], risk_only: false}, this.$store.getters.getCaseId);
            this.graph.graphData = result?.data?.data || null;
            this.graph.graphLoading = false;
        },
    },
};
