import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import ResizeObserver from "@/assets/js/vue-resize";

export default {
    name: "comparify",
    props: {
        value: {default: 100},
        step: {default: ".1"},
        isTool: {default: false},
    },
    template: "#compare-template",
    components: {
        "font-awesome-icon": FontAwesomeIcon,
        "resize-observer": ResizeObserver,
    },
    data() {
        return {
            width: null,
            compareWidth: this.value,
            disabled: true,
        };
    },
    watch: {
        value() {
            let total = 100;
            this.compareWidth = this.value;
            document.getElementsByClassName("compare-overlay")[0].style.left = `calc(${this.compareWidth + "%"})`;
            document.getElementsByClassName("compare-overlay")[0].style.width = `calc(${total - this.compareWidth + "%"})`;
        },
    },
    mounted() {
        let total = 100;
        this.width = this.getContainerWidth();
        document.getElementsByClassName("compare-overlay")[0].style.left = `calc(${this.compareWidth + "%"})`;
        document.getElementsByClassName("compare-overlay")[0].style.width = `calc(${total - this.compareWidth + "%"})`;
    },
    methods: {
        startDrag(e) {
            this.disabled = false;
            this.$refs["input"].disabled = false;
            e.preventDefault();
            e.cancelBubble = true;
            e.stopPropagation();
        },
        endDrag() {
            this.disabled = true;
            this.$refs["input"].disabled = true;
        },
        handleComparatorInput(e) {
            if (!this.disabled) {
                let total = 100;
                let leftPos = document.getElementsByClassName("compare-wrapper")[0].offsetLeft;
                let compareWidth = ((e.pageX - leftPos) / this.$refs["input"].offsetWidth) * 100;
                if (compareWidth >= 50) {
                    if (compareWidth <= 100) this.compareWidth = compareWidth;
                    else this.compareWidth = 100;
                } else {
                    this.compareWidth = 50;
                }
                this.$emit("input", this.compareWidth);
                document.getElementsByClassName("compare-overlay")[0].style.left = `calc(${this.compareWidth + "%"})`;
                document.getElementsByClassName("compare-overlay")[0].style.width = `calc(${total - this.compareWidth + "%"})`;
                this.endDrag();
            }
        },
        handleInput(e) {
            let total = 100;
            this.compareWidth = e.target.value;
            this.$emit("input", e.target.value);
            document.getElementsByClassName("compare-overlay")[0].style.left = `calc(${this.compareWidth + "%"})`;
            document.getElementsByClassName("compare-overlay")[0].style.width = `calc(${total - this.compareWidth + "%"})`;

            this.endDrag();
        },
        preventAllActions(e) {
            e.preventDefault();
            e.cancelBubble = true;
            e.stopPropagation();
        },
        handleResize() {
            const w = this.getContainerWidth();
            if (w === this.width) return;
            this.width = w;
        },
        getContainerWidth() {
            const element = document.getElementsByClassName("case-list")[0];
            return window.getComputedStyle(element, null).getPropertyValue("width");
        },
    },
};
