(function(global, factory) {
    typeof exports === "object" && typeof module !== "undefined" ? factory(exports) : typeof define === "function" && define.amd ? define(["exports"], factory) : factory((global["vue-resize"] = {}));
})(this, function(exports) {
    "use strict";

    function getInternetExplorerVersion() {
        var ua = window.navigator.userAgent;

        var msie = ua.indexOf("MSIE ");
        if (msie > 0) {
            // IE 10 or older => return version number
            return parseInt(ua.substring(msie + 5, ua.indexOf(".", msie)), 10);
        }

        var trident = ua.indexOf("Trident/");
        if (trident > 0) {
            // IE 11 => return version number
            var rv = ua.indexOf("rv:");
            return parseInt(ua.substring(rv + 3, ua.indexOf(".", rv)), 10);
        }

        var edge = ua.indexOf("Edge/");
        if (edge > 0) {
            // Edge (IE 12+) => return version number
            return parseInt(ua.substring(edge + 5, ua.indexOf(".", edge)), 10);
        }

        // other browser
        return -1;
    }

    var isIE = void 0;

    function initCompat() {
        if (!initCompat.init) {
            initCompat.init = true;
            isIE = getInternetExplorerVersion() !== -1;
        }
    }

    var ResizeObserver = {
        render: function render() {
            var _vm = this;
            var _h = _vm.$createElement;
            var _c = _vm._self._c || _h;
            return _c("div", {staticClass: "resize-observer", attrs: {tabindex: "-1"}});
        },
        staticRenderFns: [],
        _scopeId: "data-v-b329ee4c",
        name: "resize-observer",

        methods: {
            notify: function notify() {
                this.$emit("notify");
            },
            addResizeHandlers: function addResizeHandlers() {
                this._resizeObject.contentDocument.defaultView.addEventListener("resize", this.notify);
                if (this._w !== this.$el.offsetWidth || this._h !== this.$el.offsetHeight) {
                    this.notify();
                }
            },
            removeResizeHandlers: function removeResizeHandlers() {
                if (this._resizeObject && this._resizeObject.onload) {
                    if (!isIE && this._resizeObject.contentDocument) {
                        this._resizeObject.contentDocument.defaultView.removeEventListener("resize", this.notify);
                    }
                    delete this._resizeObject.onload;
                }
            },
        },

        mounted: function mounted() {
            var _this = this;

            initCompat();
            this.$nextTick(function() {
                _this._w = _this.$el.offsetWidth;
                _this._h = _this.$el.offsetHeight;
            });
            var object = document.createElement("object");
            this._resizeObject = object;
            object.setAttribute("style", "display: block; position: absolute; top: 0; left: 0; height: 100%; width: 100%; overflow: hidden; pointer-events: none; z-index: -1;");
            object.setAttribute("aria-hidden", "true");
            object.setAttribute("tabindex", -1);
            object.onload = this.addResizeHandlers;
            object.type = "text/html";
            if (isIE) {
                this.$el.appendChild(object);
            }
            object.data = "about:blank";
            if (!isIE) {
                this.$el.appendChild(object);
            }
        },
        beforeDestroy: function beforeDestroy() {
            this.removeResizeHandlers();
        },
    };

    // Install the components
    function install(Vue) {
        Vue.component("resize-observer", ResizeObserver);
        /* -- Add more components here -- */
    }

    /* -- Plugin definition & Auto-install -- */
    /* You shouldn't have to modify the code below */

    // Plugin
    var plugin = {
        // eslint-disable-next-line no-undef
        version: "0.4.4",
        install: install,
    };

    // Auto-install
    var GlobalVue = null;
    if (typeof window !== "undefined") {
        GlobalVue = window.Vue;
    } else if (typeof global !== "undefined") {
        GlobalVue = global.Vue;
    }
    if (GlobalVue) {
        GlobalVue.use(plugin);
    }

    exports.install = install;
    exports.ResizeObserver = ResizeObserver;
    exports["default"] = plugin;

    Object.defineProperty(exports, "__esModule", {value: true});
});
